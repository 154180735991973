.material {
    .info{
        padding-top: 0px;
        padding-bottom: 50px;
        // @include large-desktop {
        //     padding-top: 0px;
        //     padding-bottom: 50px;
        // }

        @include desktop {
            padding-top: 0px;
            padding-bottom: 30px;
        }
        .img{
            width: 100%;
            height: 100%;
            padding-top: 40px;

            @include desktop {
                height: 350px;
            }
            .img-box{
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .right{
            padding-left: 20px;

            @include desktop {
                padding-left: 0px;
            }
            .title{
                height: 60px;
                line-height: 60px;
                font-size: 26px;
                color: #000;
                border-bottom: 1px solid $color-primary;
                // @include xl-desktop {
                //     height: 80px;
                //     line-height: 80px;
                //     font-size: 32px;
                // }

                @include large-desktop {
                    height: 60px;
                    line-height: 60px;
                    font-size: 24px;
                }

                @include desktop {
                    
                }
            }
            .goods-title{
                font-size: 16px;
                color: #666;
                padding: 10px 0;
                // @include xl-desktop {
                //     font-size: 20px;
                // }

                @include large-desktop {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
            
            .el-form-item__label {
                font-size: 16px;
                color: #828282;

                // @include xl-desktop {
                //     font-size: 18px;
                // }

                @include large-desktop {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }

            .el-input {
                height: 100%;
                // border: 1px solid #eee;

                .el-input__inner {
                    height: 100%;
                    font-size: 16px;

                    // @include xl-desktop {
                    //     font-size: 18px;
                    // }

                    @include large-desktop {
                        font-size: 14px;
                    }

                    @include desktop {
                        font-size: 14px;
                    }
                }
            }



            .el-select {
                height: 100%;

                .el-input__icon {
                    color: $color-primary;
                    font-size: 16px;
                    line-height: 40px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     line-height: 50px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        line-height: 40px;
                    }

                    @include desktop {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }


            .sl-form-item-box {
                height: 40px;
                width: 250px;
                border-radius: 6px;
                border: 1px solid #ccc;

                .icon {
                    width: 26px;
                    margin-right: 10px;
                }

                // @include xl-desktop {
                //     width: 280px;
                //     height: 50px;

                //     .icon {
                //         width: 28px;
                //     }
                // }

                @include large-desktop {
                    height: 40px;
                    width: 200px;
                    .icon {
                        width: 24px;
                    }
                }

                @include desktop {
                    height: 40px;
                    width: 200px;
                    .icon {
                        width: 20px;
                    }
                }
            }
            .btns{
                width: 180px;
                height: 50px;
                background-color: $color-primary;
                color: #fff;
                text-align: center;
                line-height: 50px;
                border-radius: 8px;
                font-size: 18px;
                color: #fff;
                text-align: center;
                cursor: pointer;

                // @include large-desktop {
                //     width: 200px;
                //     height: 50px;
                //     line-height: 50px;
                //     font-size: 24px;
                // }

                @include desktop {
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                }
            }
            .span{
                font-size: 18px;
                color: $color-primary;
                padding-top: 30px;

                // @include large-desktop {
                //     font-size: 20px;
                //     padding-top: 40px;
                // }

                @include desktop {
                    font-size: 16px;
                    padding-top: 30px;
                }

            }
            .text{
                font-size: 16px;
                color: #666;
                padding-top: 10px;
                opacity: 0.8;
                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }
    }

    .tabs{
        .el-tabs--card>.el-tabs__header{
            border-bottom: 1px solid $color-primary;
        }
        .el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        .el-tabs--card>.el-tabs__header .el-tabs__item{
            margin-right: 20px;
            border-left: none;
            background-color: #eee;
            border-radius: 10px 10px 0 0;
            height: 36px;
            line-height: 36px;
            padding: 0 20px;
            color: #999;
            font-size: 16px;
            position: relative;
            top: -1px;

            // @include large-desktop {
            //     height: 40px;
            //     line-height: 40px;
            //     padding: 0 25px;
            //     font-size: 18px;
            // }

            @include desktop {
                height: 30px;
                line-height: 30px;
                padding: 0 15px;
                font-size: 14px;
            }
        }
        .el-tabs__item.is-active{
            background-color: $color-primary !important;
            color: #fff !important;
        }

        .tabs2{
            margin:15px 0 15px 0;
            padding:10px 0;
            background-color: #eee;
            @include large-desktop {
                margin:0px 0 10px 0;
            }

            .li{
                font-size: 16px;
                color: #999;
                padding: 0 10px;
                border-right: 1px solid #ccc;
                cursor: pointer;
                &.active{
                    color: $color-primary;
                }
                // @include large-desktop {
                //     font-size: 18px;
                // }
    
                @include desktop {
                    font-size: 16px;
                }
            }
            .li:last-child{
                border-right: none;
            }
            
        }
        .has-gutter{
            font-size: 16px;
            color: #333;
        }
        .el-table__row{
            height: 40px;
            font-size: 14px;
            color: #828282;
            // background-color: #ccc;
            // margin-bottom: 20px;
            @include large-desktop {
                font-size: 16px;
            }

            @include desktop {
                font-size: 14px;
            }
        }


        .area-item {
            margin-top: 20px;

            // @include xl-desktop {
            //     margin-top: 20px;
            // }

            @include large-desktop {
                margin-top: 10px;
            }

            .img-box {
                width: 100%;
                height: 150px;
                border-radius: 10px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

                // @include xl-desktop {
                //     height: 220px;
                // }

                @include large-desktop {
                    height: 120px;
                }

                @include desktop {
                    height: 100px;
                }
            }

            .title {
                height: 40px;
                font-size: 16px;
                color: #333;
                line-height: 40px;
                text-align: center;

                // @include xl-desktop {
                //     height: 50px;
                //     font-size: 16px;
                //     line-height: 50px;
                // }

                @include large-desktop {
                    height: 40px;
                    font-size: 14px;
                    line-height: 40px;
                }

                @include desktop {
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                }
            }
        }
    }


    
}