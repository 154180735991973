@charset "UTF-8";
/*主题色*/
/*副题色*/
/*成功提示色*/
/*警告提示色*/
/*错误警告色*/
/*=== 媒体查询 ===*/
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary,
input,
select,
button,
input::-webkit-input-placeholder {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  outline: none;
}

article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%;
}

img {
  display: inline-block;
}

/* force a vertical scrollbar to prevent a jumpy page */
/*html {overflow-y: scroll;}*/
/*gavin edit*/
html {
  overflow-y: auto;
}

/* we use a lot of ULs then aren't bulleted.
  don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
  text-decoration: none !important;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 0px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
  margin: 0;
}

input {
  outline: none !important;
  /* 去掉默认的聚焦边框 */
  border: none !important;
  /* 去掉边框 */
  background: none !important;
  /* 去掉默认背景 */
  box-shadow: none !important;
  /* 去掉阴影 */
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* 禁用select默认下拉箭头 */
select::-ms-expand {
  display: none;
}

/* 禁用PC端表单输入框默认清除按钮 */
/*input[type=text]::-ms-clear,input[type=tel]::-ms-clear,input[type=number]::-ms-clear{display:none;}*/
/* 禁用radio和checkbox默认样式 */
/*input[type=radio]::-ms-check,input[type=checkbox]::-ms-check{display: none;}*/
/* let's clear some floats */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* start--文本行对齐--start */
.s-text-left {
  text-align: left;
}

.s-text-center {
  text-align: center;
}

.s-text-right {
  text-align: right;
}

/* end--文本行对齐--end */
/* start--文本行数限制--start */
.s-line-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.s-line-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-3 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-4 {
  -webkit-line-clamp: 4;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-5 {
  -webkit-line-clamp: 5;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-6 {
  -webkit-line-clamp: 6;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-7 {
  -webkit-line-clamp: 7;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-8 {
  -webkit-line-clamp: 8;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.s-line-9 {
  -webkit-line-clamp: 9;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* end--文本行数限制--end */
/* start--通用颜色配置--start */
.s-color-f {
  color: #ffffff !important;
}

.s-bg-f {
  background-color: #ffffff !important;
}

.s-color-0 {
  color: #000000 !important;
}

.s-bg-0 {
  background-color: #000000 !important;
}

.s-color-1 {
  color: #111111 !important;
}

.s-bg-1 {
  background-color: #111111 !important;
}

.s-color-2 {
  color: #222222 !important;
}

.s-bg-2 {
  background-color: #222222 !important;
}

.s-color-3 {
  color: #333333 !important;
}

.s-bg-3 {
  background-color: #333333 !important;
}

.s-color-4 {
  color: #444444 !important;
}

.s-bg-4 {
  background-color: #444444 !important;
}

.s-color-5 {
  color: #555555 !important;
}

.s-bg-5 {
  background-color: #555555 !important;
}

.s-color-6 {
  color: #666666 !important;
}

.s-bg-6 {
  background-color: #666666 !important;
}

.s-color-7 {
  color: #777777 !important;
}

.s-bg-7 {
  background-color: #777777 !important;
}

.s-color-8 {
  color: #888888 !important;
}

.s-bg-8 {
  background-color: #888888 !important;
}

.s-color-82 {
  color: #828282 !important;
}

.s-bg-82 {
  background-color: #828282 !important;
}

.s-color-9 {
  color: #999999 !important;
}

.s-bg-9 {
  background-color: #999999 !important;
}

.s-color-BD {
  color: #BDBDBD !important;
}

.s-bg-BD {
  background-color: #BDBDBD !important;
}

.s-color-C {
  color: #CCCCCC !important;
}

.s-bg-C {
  background-color: #CCCCCC !important;
}

.s-color-E {
  color: #EEEEEE !important;
}

.s-bg-E {
  background-color: #EEEEEE !important;
}

.s-color-E5 {
  color: #E5E5E5 !important;
}

.s-bg-E5 {
  background-color: #E5E5E5 !important;
}

.s-color-primary {
  color: #018876 !important;
}

.s-bg-primary {
  background-color: #018876 !important;
}

.s-color-assistant {
  color: #1774ff !important;
}

.s-bg-assistant {
  background-color: #1774ff !important;
}

.s-color-success {
  color: #4cd964 !important;
}

.s-bg-success {
  background-color: #4cd964 !important;
}

.s-color-warning {
  color: #f0ad4e !important;
}

.s-bg-warning {
  background-color: #f0ad4e !important;
}

.s-color-error {
  color: #dd524d !important;
}

.s-bg-error {
  background-color: #dd524d !important;
}

/* end--通用颜色配置--end */
[class*=flex-] {
  display: -webkit-box;
  /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
  display: -moz-box;
  /* Firefox 17- */
  display: -webkit-flex;
  /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
  display: -moz-flex;
  /* Firefox 18+ */
  display: -ms-flexbox;
  /* IE 10 */
  display: flex;
  /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}

.flex-y-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex-y-bottom {
  flex-direction: column-reverse;
}

.flex-x-right {
  flex-direction: row-reverse;
}

/* // 交叉轴上对齐 居中flex-level */
.flex-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* // 交叉轴上对齐 元素第一行文字的基线对齐flex-bottom */
.flex-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

/* // 主轴对齐 左右 左中右 基于子元素的数量flex-lr */
.flex-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/* //换行flex-br */
.flex-wrap {
  -webkit-box-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* // 主轴对齐 居右flex-right */
.flex-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* // 主轴对齐 居左flex-left */
.flex-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

/* // 主轴对齐 居中 */
.flex-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/* // 主轴对齐 居下 */
.flex-align-end {
  align-items: flex-end;
}

.overflow-y {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-y::-webkit-scrollbar {
  width: 0 !important;
}

.overflow-x {
  overflow-x: scroll;
  overflow-y: hidden;
}

.overflow-x::-webkit-scrollbar {
  width: 0 !important;
}

.images {
  width: 100%;
  height: 100%;
  display: inline-block;
}

[v-cloak] {
  display: none;
}

.sl-Regular {
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sl-Medium {
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sl-Bold {
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#translate {
  display: none;
}

.pagination-box {
  position: relative;
}
.pagination-box .text1 {
  position: absolute;
  left: -17%;
  font-size: 16px;
}
.pagination-box .text2 {
  position: absolute;
  right: -17%;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .pagination-box .text1 {
    position: absolute;
    left: -8%;
  }
  .pagination-box .text2 {
    position: absolute;
    right: -8%;
  }
}
@media (max-width: 992px) {
  .pagination-box .text1 {
    position: absolute;
    left: -3%;
  }
  .pagination-box .text2 {
    position: absolute;
    right: -3%;
  }
}

.el-pagination .btn-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #019176;
  position: relative;
  top: -7px;
  right: 10px;
}
.el-pagination .btn-prev .el-icon {
  font-size: 18px !important;
  position: relative;
  left: 2px;
  color: #019176;
}
.el-pagination .btn-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #019176;
  position: relative;
  top: -7px;
  left: 10px;
}
.el-pagination .btn-next .el-icon {
  font-size: 18px !important;
  position: relative;
  left: -2px;
  color: #019176;
}
.el-pagination .el-pager .number {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e5e5;
  color: #727171;
  min-width: auto !important;
  line-height: 24px;
  margin: 0 2px;
}
.el-pagination .el-pager .active {
  background-color: #019176;
  color: #fff;
  border: 1px solid #019176;
}
.el-pagination .el-pager .el-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e5e5;
  color: #727171;
  min-width: auto !important;
  line-height: 24px;
  margin: 0 2px;
}
.el-pagination .el-pager .el-icon::before {
  line-height: 24px;
}

.sl-comp-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 30px 100px;
  z-index: 10;
  background-color: #fff;
}
@media (max-width: 1920px) {
  .sl-comp-header {
    padding: 10px 0;
  }
}
.sl-comp-header .sl-header-logo {
  height: 50px;
}
.sl-comp-header .sl-header-menu .el-dropdown-link .el-icon-arrow-down {
  color: #018876;
}
.sl-comp-header .sl-header-menu .sl-header-menu-li {
  margin-left: 15px;
}
@media (max-width: 1120px) {
  .sl-comp-header .sl-header-menu .sl-header-menu-li {
    margin-left: 5px;
  }
}
.sl-comp-header .sl-header-menu .sl-header-menu-li img {
  display: block;
}
.sl-comp-header .sl-header-menu .navs {
  padding-top: 15px;
}
.sl-comp-header .sl-header-menu .navs .nav {
  margin-right: 32px;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item {
  font-size: 14px;
  color: #000;
  height: 40px;
  line-height: 40px;
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  font-family: SourceHanSansCN-Medium;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item:hover {
  color: #018876;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item.active {
  color: #018876;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item span {
  height: 2px;
  width: 100%;
  background-color: #018876;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  display: none;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item.active span {
  display: block;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item:hover span {
  display: block;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub {
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: none;
  transition: all 0.3s ease-in-out;
  padding-top: 30px;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .nav-sub2 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .type {
  padding: 30px 12px;
  border-bottom: 2px solid #d3d4d4;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .type .name {
  flex: 1;
  margin: 0 12px;
  height: 40px;
  border-radius: 20px;
  background-color: #f7f7f7;
  font-size: 16px;
  color: #727171;
  line-height: 40px;
  text-align: center;
  width: 240px;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .type .name.active {
  background-color: #018876;
  color: #fff;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .type .name.hover {
  background-color: #018876;
  color: #fff;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list {
  padding: 20px;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .left, .sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .right {
  flex: 1;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .left .goods-li, .sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .right .goods-li {
  height: 34px;
  font-size: 14px;
  color: #727171;
  line-height: 30px;
  padding: 0 15px;
  margin: 5px 0;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .left .goods-li:hover, .sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .right .goods-li:hover {
  background-color: #f7f7f7;
  color: #018876;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .left .goods-li.active, .sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .right .goods-li.active {
  background-color: #f7f7f7;
  color: #018876;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .goods-nav-sub-item .goods-list .right {
  flex: 1;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .nav-sub-item {
  height: 30px;
  font-size: 14px;
  color: #727171;
  line-height: 30px;
  padding: 0 15px;
  margin: 5px 0;
  width: 130px;
  text-align: center;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item .nav-sub .nav-sub-item:hover {
  background-color: #f7f7f7;
  color: #018876;
}
.sl-comp-header .sl-header-menu .navs .nav .nav-item:hover .nav-sub {
  display: block;
}
.sl-comp-header .sl-header-menu .navs .sl-header-search {
  width: 250px;
  height: 30px;
  border-radius: 15px;
  position: relative;
  border: 1px solid #018876;
}
@media (max-width: 1920px) {
  .sl-comp-header .sl-header-menu .navs .sl-header-search {
    width: 180px;
  }
}
.sl-comp-header .sl-header-menu .navs .sl-header-search .icon {
  width: 18px;
  margin: 0 10px;
}
.sl-comp-header .sl-header-menu .navs .sl-header-search input {
  flex: 1;
  width: calc(100% - 30px);
  height: 22px;
  border-right: 1px solid #018876 !important;
  padding-left: 24px;
  font-size: 14px;
}
.sl-comp-header .sl-header-menu .navs .all {
  margin-left: 20px;
  width: 30px;
  cursor: pointer;
}

.sl-comp-header-mini {
  height: 40px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
}
.sl-comp-header-mini .left {
  height: 100%;
}
.sl-comp-header-mini .left .sl-header-logo {
  height: 24px;
}
.sl-comp-header-mini .right {
  height: 40px;
}
.sl-comp-header-mini .right .all {
  width: 28px;
}

.sl-comp-header-popup .popup-moake {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: none;
  transition: all 0.3s ease-in-out;
}
.sl-comp-header-popup .popup-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  transform: translate(-100%, 0);
  background-color: #fff;
  padding: 0 20px 20px 20px;
  width: 80%;
  z-index: 101;
}
.sl-comp-header-popup .popup-content .popup-title {
  padding: 15px 0;
}
.sl-comp-header-popup .popup-content .popup-title .close {
  width: 18px;
  height: 18px;
}
.sl-comp-header-popup .popup-content .popup-menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sl-comp-header-popup .popup-content .popup-menu .el-collapse-item .el-collapse-item__arrow {
  display: none;
}
.sl-comp-header-popup .popup-content .popup-menu .sub_menu .el-collapse-item__arrow {
  display: block;
}
.sl-comp-header-popup .popup-content .popup-menu .el-collapse-item__content {
  padding-bottom: 0;
}
.sl-comp-header-popup .popup-content .popup-menu .popup-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-left: 15px;
  border-bottom: 1px solid #eee;
}
.sl-comp-header-popup .popup-content .popup-menu::-webkit-scrollbar {
  width: 0px;
}
.sl-comp-header-popup.showPopup .popup-moake {
  display: block;
}
.sl-comp-header-popup.showPopup .popup-content {
  transform: translate(0, 0);
}

.float {
  position: fixed;
  right: 2%;
  bottom: 5%;
  z-index: 100;
}
.float .float-item {
  width: 50px;
  height: 50px;
  margin-left: 15px;
  cursor: pointer;
}

.sl-comp-foot {
  background-color: #f3f3f3;
  padding: 30px 50px;
}
@media (max-width: 1120px) {
  .sl-comp-foot {
    padding: 20px 30px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot {
    padding: 15px;
  }
}
.sl-comp-foot .model1 {
  padding-bottom: 30px;
  border-bottom: 2px solid #018876;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model1 {
    padding-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model1 {
    padding-bottom: 10px;
  }
}
.sl-comp-foot .model1 .logo {
  height: 54px;
}
.sl-comp-foot .model1 .logo img {
  display: block;
  height: 100%;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model1 .logo {
    height: 40px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model1 .logo {
    height: 30px;
  }
}
.sl-comp-foot .model1 .title {
  font-size: 36px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model1 .title {
    font-size: 24px;
    text-align: left;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model1 .title {
    font-size: 20px;
    text-align: left;
    padding-top: 10px;
  }
}
.sl-comp-foot .model1 .title span {
  color: #018876;
}
.sl-comp-foot .model2 .left {
  height: 100%;
}
.sl-comp-foot .model2 .left .title {
  font-size: 16px;
  color: #000;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .left .title {
    font-size: 14px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .left .title {
    font-size: 12px;
    padding-top: 10px;
  }
}
.sl-comp-foot .model2 .left .desc {
  font-size: 14px;
  color: #666;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .left .desc {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .left .desc {
    font-size: 12px;
  }
}
.sl-comp-foot .model2 .left .input-group {
  width: 450px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #018876;
  margin-top: 20px;
  background-color: #fff;
  overflow: hidden;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .left .input-group {
    width: 100%;
    height: 34px;
    border-radius: 17px;
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .left .input-group {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    margin-top: 10px;
  }
}
.sl-comp-foot .model2 .left .input-group .form-control {
  flex: 1;
  font-size: 16px;
  color: #333;
  padding: 20px;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .left .input-group .form-control {
    font-size: 14px;
    padding: 10px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .left .input-group .form-control {
    font-size: 12px;
    padding: 10px;
  }
}
.sl-comp-foot .model2 .left .input-group .input-btn {
  width: 90px;
  height: 100%;
  background-color: #018876;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  position: relative;
  top: -1px;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .left .input-group .input-btn {
    width: 90px;
    line-height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .left .input-group .input-btn {
    width: 80px;
    line-height: 40px;
    font-size: 14px;
  }
}
.sl-comp-foot .model2 .right {
  padding-top: 20px;
}
@media (max-width: 1920px) {
  .sl-comp-foot .model2 .right {
    padding-top: 15px;
  }
}
.sl-comp-foot .model2 .right .ul {
  padding-bottom: 30px;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .right .ul {
    padding-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .right .ul {
    padding-bottom: 10px;
  }
}
.sl-comp-foot .model2 .right .ul .li {
  font-size: 18px;
  color: #000;
  margin-right: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .right .ul .li {
    font-size: 14px;
    margin-right: 20px;
  }
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .right .ul .li {
    font-size: 14px;
    margin-right: 10px;
  }
}
.sl-comp-foot .model2 .right .ul .li:hover {
  color: #018876;
}
.sl-comp-foot .model2 .right .social {
  padding-bottom: 10px;
}
.sl-comp-foot .model2 .right .social .li {
  font-size: 14px;
  color: #000;
  padding: 4px 0;
}
.sl-comp-foot .model2 .right .social .li img {
  width: 20px;
  margin-right: 5px;
}
@media (max-width: 992px) {
  .sl-comp-foot .model2 .right .social .li {
    font-size: 14px;
  }
  .sl-comp-foot .model2 .right .social .li img {
    width: 18px;
  }
}
.sl-comp-foot .model2 .right .with {
  font-size: 14px;
  color: #000;
  margin-bottom: 15px;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .right .with {
    font-size: 14px;
  }
}
.sl-comp-foot .model2 .right .ul2 .li {
  width: 24px;
  margin-right: 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .sl-comp-foot .model2 .right .ul2 .li {
    width: 20px;
    margin-right: 20px;
  }
}
.sl-comp-foot .model2 .right .ul2 .li:hover {
  opacity: 0.7;
}

.sl-comp-foot-bottom {
  background-color: #000;
}
.sl-comp-foot-bottom .text {
  height: 70px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1120px) {
  .sl-comp-foot-bottom .text {
    height: 40px;
    font-size: 12px;
  }
}

.sl-popup {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  width: 750px;
  height: 500px;
}
@media (max-width: 992px) {
  .sl-popup {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }
}
.sl-popup .popup-top {
  height: 30px;
}
.sl-popup .popup-top .close-popup {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.sl-popup .popup-top .close-popup:hover {
  opacity: 0.7;
}
.sl-popup .popup-content {
  font-size: 18px;
  color: #333;
  font-family: SourceHanSansCN-Medium;
}

.uni-popup {
  position: fixed;
  top: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  overflow: hidden;
}

.uni-popup__mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}

.uni-popup__mask.ani {
  transition: all 0.3s;
}

.uni-popup__mask.uni-bottom,
.uni-popup__mask.uni-center,
.uni-popup__mask.uni-top {
  opacity: 1;
}

.uni-popup__wrapper {
  position: absolute;
  z-index: 999;
  box-sizing: border-box;
}

.uni-popup__wrapper.ani {
  transition: all 0.3s;
}

.uni-popup__wrapper.top {
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
}

.uni-popup__wrapper.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
}

.uni-popup__wrapper.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.2);
  opacity: 0;
}

.uni-popup__wrapper-box {
  position: relative;
  box-sizing: border-box;
}

.uni-popup__wrapper.uni-custom .uni-popup__wrapper-box {
  /* padding: 30upx;
  background: #fff */
}

.uni-popup__wrapper.uni-custom.center .uni-popup__wrapper-box {
  position: relative;
  /* max-width: 80%;
  max-height: 80%;
  overflow-y: scroll */
}

.uni-popup__wrapper.uni-custom.bottom .uni-popup__wrapper-box,
.uni-popup__wrapper.uni-custom.top .uni-popup__wrapper-box {
  width: 100%;
  /* max-height: 500upx; */
  overflow-y: scroll;
}

.uni-popup__wrapper.uni-bottom,
.uni-popup__wrapper.uni-top {
  transform: translateY(0);
}

.uni-popup__wrapper.uni-center {
  transform: scale(1);
  opacity: 1;
}

.index {
  overflow: hidden;
  /*[lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
    background-image: url(../../images/top/p_keyvisual_design_3_en.jpg);
  }
  @media print, screen and (min-width: 768px) {
    [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
      background-image: url(../../images/top/p_keyvisual_design_3_pc_en.jpg);
    }
  }
  [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
    background-image: url(../../images/top/p_keyvisual_design_3_en.jpg);
  }
  @media print, screen and (min-width: 768px) {
    [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
      background-image: url(../../images/top/p_keyvisual_design_3_pc_en.jpg);
    }
  }*/
}
.index .sl-banner {
  background-color: #c1c1c1;
  position: relative;
}
.index .sl-banner .cont {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.index .sl-banner .cont .img-box {
  background-position: center;
  background-position: no-repeat;
  background-size: cover;
  height: 100%;
}
.index .sl-banner .cont .learn-more-hover {
  font-size: 14px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px solid #fff;
  cursor: pointer;
  margin-top: 20px;
}
.index .sl-banner .cont .learn-more-hover:hover {
  background-color: #fff;
  color: #018675;
}
.index .sl-banner .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  font-size: 50px;
}
@media (max-width: 1120px) {
  .index .sl-banner .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
    font-size: 36px;
  }
}
@media (max-width: 992px) {
  .index .sl-banner .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
    font-size: 26px;
  }
}
.index .hidden {
  opacity: 0;
  display: none;
  transition: 500ms;
}
.index .show {
  opacity: 1 !important;
  transition: 500ms;
  z-index: 2;
}
.index .ad .sl-carousel {
  position: relative;
}
.index .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
  transition: all 0.3s;
}
.index .ad .sl-carousel .making-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.index .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.index .ad .sl-carousel .making .title {
  font-size: 110px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1920px) {
  .index .ad .sl-carousel .making .title {
    font-size: 80px;
  }
}
@media (max-width: 1120px) {
  .index .ad .sl-carousel .making .title {
    font-size: 40px;
  }
}
@media (max-width: 992px) {
  .index .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.index .ad .sl-carousel .making .subtitle {
  font-size: 36px;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  transition: all 0.3s;
}
@media (max-width: 1920px) {
  .index .ad .sl-carousel .making .subtitle {
    font-size: 28px;
    padding-top: 15px;
  }
}
@media (max-width: 1120px) {
  .index .ad .sl-carousel .making .subtitle {
    font-size: 18px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .index .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 5px;
  }
}
.index .ad .sl-carousel .making .btn-all {
  height: 50px;
  border-radius: 25px;
  border: 1px solid #fff;
  margin: 60px auto 0 auto;
  font-size: 24px;
  color: #fff;
  line-height: 50px;
  width: 160px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1920px) {
  .index .ad .sl-carousel .making .btn-all {
    height: 36px;
    margin: 40px auto 0 auto;
    line-height: 36px;
    width: 120px;
    font-size: 18px;
  }
}
@media (max-width: 1120px) {
  .index .ad .sl-carousel .making .btn-all {
    height: 30px;
    margin: 30px auto 0 auto;
    line-height: 30px;
    width: 90px;
    font-size: 14px;
  }
}
.index .ad .sl-carousel .making .btn-all:hover {
  background-color: #fff;
  color: #000;
}
.index .ad2 .sl-carousel {
  height: 520px;
  position: relative;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}
.index .ad2 .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel {
    height: 350px;
  }
}
.index .ad2 .sl-carousel .making {
  position: absolute;
  right: 0;
  top: 8%;
}
.index .ad2 .sl-carousel .making .cont {
  background-color: #fff;
  width: 60vw;
  border-radius: 0 0 0 40px;
  padding-top: 20px;
  padding-bottom: 60px;
  padding-left: 60px;
  height: 350px;
  position: relative;
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont {
    width: 90vw;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 0px;
    border-radius: 0 0 0 20px;
    height: 250px;
  }
}
.index .ad2 .sl-carousel .making .cont .tit {
  padding-bottom: 30px;
  display: inline-block;
}
@media (max-width: 1920px) {
  .index .ad2 .sl-carousel .making .cont .tit {
    padding-bottom: 20px;
  }
}
@media (max-width: 1120px) {
  .index .ad2 .sl-carousel .making .cont .tit {
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont .tit {
    padding-bottom: 5px;
  }
}
.index .ad2 .sl-carousel .making .cont .tit .name-en {
  height: 40px;
  font-size: 20px;
  color: #018876;
  line-height: 40px;
  width: 14vw;
  border-bottom: 1px solid #018876;
  font-family: SourceHanSansCN-Medium;
}
@media (max-width: 1120px) {
  .index .ad2 .sl-carousel .making .cont .tit .name-en {
    height: 36px;
    font-size: 18px;
    line-height: 36px;
  }
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont .tit .name-en {
    width: 24vw;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}
.index .ad2 .sl-carousel .making .cont .tit .name {
  font-size: 26px;
  color: #000;
}
.index .ad2 .sl-carousel .making .cont .tit .name span {
  font-size: 28px;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .index .ad2 .sl-carousel .making .cont .tit .name {
    font-size: 18px;
  }
  .index .ad2 .sl-carousel .making .cont .tit .name span {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont .tit .name {
    font-size: 16px;
  }
  .index .ad2 .sl-carousel .making .cont .tit .name span {
    font-size: 22px;
  }
}
.index .ad2 .sl-carousel .making .cont .text p {
  font-size: 18px;
  color: #727171;
  font-family: SourceHanSansCN-Regular;
  line-height: 1.6em;
}
@media (max-width: 1120px) {
  .index .ad2 .sl-carousel .making .cont .text p {
    font-size: 16px;
    width: auto;
  }
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont .text p {
    font-size: 14px;
    -webkit-line-clamp: 4;
  }
}
.index .ad2 .sl-carousel .making .cont .btn-all {
  position: absolute;
  bottom: 30px;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  background-color: #018876;
  font-size: 14px;
  color: #fff;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .index .ad2 .sl-carousel .making .cont .btn-all {
    bottom: 30px;
  }
}
@media (max-width: 992px) {
  .index .ad2 .sl-carousel .making .cont .btn-all {
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    width: 90px;
    bottom: 10px;
  }
}
.index .ad2 .sl-carousel .making .cont .btn-all:hover {
  opacity: 0.8;
}
.index .box-title {
  padding: 70px 90px 40px 90px;
}
@media (max-width: 1920px) {
  .index .box-title {
    padding: 30px 0;
  }
}
@media (max-width: 1120px) {
  .index .box-title {
    padding: 10px 0;
  }
}
@media (max-width: 992px) {
  .index .box-title {
    padding: 10px 0;
  }
}
.index .box-title .tit {
  padding-bottom: 20px;
  display: inline-block;
}
@media (max-width: 1920px) {
  .index .box-title .tit {
    padding-bottom: 10px;
  }
}
@media (max-width: 1120px) {
  .index .box-title .tit {
    padding-bottom: 5px;
  }
}
@media (max-width: 992px) {
  .index .box-title .tit {
    padding-bottom: 5px;
  }
}
.index .box-title .tit .name-en {
  color: #018876;
  border-bottom: 1px solid #018876;
  font-family: SourceHanSansCN-Medium;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
}
@media (max-width: 1120px) {
  .index .box-title .tit .name-en {
    height: 40px;
    font-size: 20px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .index .box-title .tit .name-en {
    height: 30px;
    font-size: 18px;
    line-height: 30px;
  }
}
.index .box-title .tit .name {
  font-size: 24px;
  color: #000;
}
.index .box-title .tit .name span {
  font-size: 26px;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .index .box-title .tit .name {
    font-size: 20px;
  }
  .index .box-title .tit .name span {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  .index .box-title .tit .name {
    font-size: 16px;
  }
  .index .box-title .tit .name span {
    font-size: 18px;
  }
}
.index .box-title .text {
  height: 100%;
}
.index .box-title .text p {
  font-size: 16px;
  color: #727171;
  font-family: SourceHanSansCN-Regular;
}
@media (max-width: 992px) {
  .index .box-title .text p {
    font-size: 14px;
  }
}
.index .box-title .ul {
  margin: 0 -12px;
}
.index .box-title .ul .li {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #727171;
  padding: 0 10px;
  border-radius: 15px;
  background-color: #f3f3f3;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
  .index .box-title .ul .li {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 12px;
    margin: 5px;
  }
}
.index .box-title .ul .li span {
  font-weight: bold;
}
.index .box-title .ul .li:hover {
  background-color: #018876;
  color: #fff;
}
.index .box-title .ul .li.active {
  background-color: #018876;
  color: #fff;
}
.index .box-title .btn-all {
  font-size: 18px;
  color: #018876;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1920px) {
  .index .box-title .btn-all {
    font-size: 16px;
  }
}
@media (max-width: 1120px) {
  .index .box-title .btn-all {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .index .box-title .btn-all {
    font-size: 12px;
  }
}
.index .box-title .btn-all:hover {
  opacity: 0.6;
}
.index .sl-swiper {
  padding: 0 100px 80px 100px;
}
@media (max-width: 1920px) {
  .index .sl-swiper {
    padding: 0 50px 40px 50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper {
    padding: 0 40px 20px 40px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper {
    padding: 0 30px 10px 30px;
  }
}
.index .sl-swiper .swiper-box {
  width: 100%;
  height: 350px;
  position: relative;
}
@media (max-width: 1120px) {
  .index .sl-swiper .swiper-box {
    height: 300px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper .swiper-box {
    height: 300px;
  }
}
.index .sl-swiper .swiper-box .homeSwiper1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eee;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide:hover .img-box {
  transform: scale(1.1);
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info .name {
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media (max-width: 1120px) {
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info {
    padding: 8px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info .name {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info {
    padding: 8px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info .name {
    font-size: 14px;
  }
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide:hover .info {
  bottom: -100%;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 {
  position: absolute;
  left: 0;
  bottom: -100%;
  right: 0;
  padding: 20px 5px 5px 5px;
  background-color: #018876;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .name {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-bottom: 15px;
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .text {
  font-size: 16px;
  color: #fff;
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .right .icon {
  width: 26px;
}
@media (max-width: 1120px) {
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 {
    padding: 20px 5px 5px 5px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .name {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .text {
    font-size: 14px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .right .icon {
    width: 20px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 {
    padding: 15px 5px 5px 5px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .name {
    font-size: 14px;
    padding-bottom: 2px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .text {
    font-size: 12px;
  }
  .index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide .info2 .right .icon {
    width: 16px;
  }
}
.index .sl-swiper .swiper-box .homeSwiper1 .swiper-slide:hover .info2 {
  bottom: 0;
}
.index .sl-swiper .homeSwiper1-button-prev {
  width: 50px;
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-swiper .homeSwiper1-button-prev {
    width: 40px;
    left: -50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper .homeSwiper1-button-prev {
    width: 30px;
    left: -40px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper .homeSwiper1-button-prev {
    width: 20px;
    left: -30px;
  }
}
.index .sl-swiper .homeSwiper1-button-next {
  width: 50px;
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-swiper .homeSwiper1-button-next {
    width: 40px;
    right: -50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper .homeSwiper1-button-next {
    width: 30px;
    right: -40px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper .homeSwiper1-button-next {
    width: 20px;
    right: -30px;
  }
}
.index .sl-zoom {
  padding: 0 100px 50px 100px;
}
@media (max-width: 1920px) {
  .index .sl-zoom {
    padding: 0 0 40px 0;
  }
}
@media (max-width: 1120px) {
  .index .sl-zoom {
    padding: 0 0 30px 0;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom {
    padding: 0 0 20px 0;
  }
}
.index .sl-zoom .zoom-box {
  width: 100%;
  height: 600px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1120px) {
  .index .sl-zoom .zoom-box {
    height: 450px;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom .zoom-box {
    height: 350px;
  }
}
.index .sl-zoom .zoom-box .item {
  width: 88px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
  position: relative;
}
@media (max-width: 1920px) {
  .index .sl-zoom .zoom-box .item {
    width: 60px;
  }
}
@media (max-width: 1120px) {
  .index .sl-zoom .zoom-box .item {
    width: 40px;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom .zoom-box .item {
    width: 30px;
  }
}
.index .sl-zoom .zoom-box .item .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.index .sl-zoom .zoom-box .item .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 136, 118, 0.7);
  font-size: 24px;
  color: #fff;
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 15px;
  transition: all 0.8s ease-in-out;
}
@media (max-width: 1120px) {
  .index .sl-zoom .zoom-box .item .making {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom .zoom-box .item .making {
    font-size: 18px;
  }
}
.index .sl-zoom .zoom-box .item .making2 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.index .sl-zoom .zoom-box .item .making2 .making2-box {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 40px 60px;
  max-width: 650px;
  border-radius: 10px;
}
.index .sl-zoom .zoom-box .item .making2 .making2-box .title {
  font-size: 30px;
  color: #fff;
  padding-bottom: 20px;
}
.index .sl-zoom .zoom-box .item .making2 .making2-box .subtitle {
  font-size: 20px;
  color: #fff;
  line-height: 36px;
}
@media (max-width: 1120px) {
  .index .sl-zoom .zoom-box .item .making2 .making2-box {
    padding: 30px 40px;
    border-radius: 10px;
  }
  .index .sl-zoom .zoom-box .item .making2 .making2-box .title {
    font-size: 24px;
    padding-bottom: 10px;
  }
  .index .sl-zoom .zoom-box .item .making2 .making2-box .subtitle {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom .zoom-box .item .making2 .making2-box {
    padding: 20px 20px;
    border-radius: 10px;
  }
  .index .sl-zoom .zoom-box .item .making2 .making2-box .title {
    font-size: 22px;
    padding-bottom: 5px;
  }
  .index .sl-zoom .zoom-box .item .making2 .making2-box .subtitle {
    font-size: 14px;
    line-height: 24px;
  }
}
.index .sl-zoom .zoom-box .item.active {
  flex: 1;
}
.index .sl-zoom .zoom-box .item.active .making {
  opacity: 0;
}
.index .sl-zoom .zoom-box .item.active .making2 {
  opacity: 1;
}
.index .sl-zoom .zoom-box-btns {
  padding-top: 30px;
}
.index .sl-zoom .zoom-box-btns .btn-prev,
.index .sl-zoom .zoom-box-btns .btn-next {
  width: 46px;
  height: 46px;
  margin: 0 26px;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-zoom .zoom-box-btns {
    padding-top: 24px;
  }
  .index .sl-zoom .zoom-box-btns .btn-prev,
  .index .sl-zoom .zoom-box-btns .btn-next {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
@media (max-width: 1120px) {
  .index .sl-zoom .zoom-box-btns {
    padding-top: 20px;
  }
  .index .sl-zoom .zoom-box-btns .btn-prev,
  .index .sl-zoom .zoom-box-btns .btn-next {
    width: 36px;
    height: 36px;
    margin: 0 16px;
  }
}
@media (max-width: 992px) {
  .index .sl-zoom .zoom-box-btns {
    padding-top: 10px;
  }
  .index .sl-zoom .zoom-box-btns .btn-prev,
  .index .sl-zoom .zoom-box-btns .btn-next {
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
}
.index .sl-zoom .zoom-box-btns.disabled {
  opacity: 0.2;
}
.index .sl-swiper2 {
  background-color: #f3f3f3;
  padding: 0 100px 130px 100px;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 {
    padding: 0 0 80px 0;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 {
    padding: 0 0 60px 0;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 {
    padding: 0 0 40px 0;
  }
}
.index .sl-swiper2 .swiper-box {
  width: 100%;
  height: 680px;
  position: relative;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box {
    height: 600px;
  }
}
@media only screen and (min-width: 1121px) and (max-width: 1600px) {
  .index .sl-swiper2 .swiper-box {
    height: 550px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box {
    height: 450px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box {
    height: 300px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide {
  width: 80%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making {
    padding: 30px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making {
    padding: 20px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making {
    padding: 10px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box {
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 50px;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box {
    border-radius: 15px;
    padding: 50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box {
    border-radius: 10px;
    padding: 25px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box {
    border-radius: 5px;
    padding: 10px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .title {
  font-size: 40px;
  color: #fff;
  padding-bottom: 20px;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .title {
    font-size: 30px;
    padding-bottom: 15px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .title {
    font-size: 26px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .title {
    font-size: 20px;
    padding-bottom: 5px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .subtitle {
  font-size: 22px;
  color: #fff;
  line-height: 36px;
  opacity: 0.8;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .subtitle {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .subtitle {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .btn-all {
  margin-top: 48px;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #018876;
  font-size: 18px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .btn-all {
    margin-top: 40px;
    width: 100px;
    height: 36px;
    border-radius: 18px;
    line-height: 36px;
    font-size: 16px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .btn-all {
    margin-top: 30px;
    width: 90px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .btn-all {
    margin-top: 30px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    font-size: 12px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .making .making-box .btn-all:hover {
  background-color: #fff;
  color: #018876;
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
  bottom: -60px;
  text-align: left;
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
  width: 110px;
  height: 5px;
  background-color: #d1d1d1;
  border-radius: 0;
}
@media (max-width: 1920px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -55px;
  }
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 80px;
    height: 4px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -50px;
  }
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 50px;
    height: 3px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -50px;
  }
  .index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 30px;
    height: 3px;
  }
}
.index .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet-active {
  background-color: #018876;
}
.index .sl-example {
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}
.index .sl-example .ul {
  padding-right: 50px;
}
@media only screen and (min-width: 1121px) and (max-width: 1600px) {
  .index .sl-example .ul {
    padding-right: 0;
  }
}
@media (max-width: 1120px) {
  .index .sl-example .ul {
    padding-right: 0;
  }
}
.index .sl-example .ul .li {
  background-color: #c1c1c1;
  color: #fff;
  text-align: center;
  margin: 2px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 100px;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 40px;
}
@media (max-width: 1120px) {
  .index .sl-example .ul .li {
    min-width: 90px;
    height: 32px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .ul .li {
    min-width: 70px;
    height: 26px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 26px;
  }
}
.index .sl-example .ul .li:hover {
  background-color: #018876;
}
.index .sl-example .ul .li.active {
  background-color: #018876;
}
.index .sl-example .box-title {
  padding: 0 100px 50px 100px;
}
@media (max-width: 1920px) {
  .index .sl-example .box-title {
    padding: 0 0px 40px 0;
  }
}
@media (max-width: 1120px) {
  .index .sl-example .box-title {
    padding: 0 0px 30px 0;
  }
}
@media (max-width: 992px) {
  .index .sl-example .box-title {
    padding: 0 0px 20px 0;
  }
}
.index .sl-example .sl-row {
  padding-bottom: 90px;
}
@media (max-width: 1920px) {
  .index .sl-example .sl-row {
    padding-bottom: 70px;
  }
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row {
    padding-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row {
    padding-bottom: 30px;
  }
}
.index .sl-example .sl-row .sl-item {
  height: 500px;
  position: relative;
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row .sl-item {
    height: 400px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row .sl-item {
    height: 250px;
  }
}
.index .sl-example .sl-row .sl-item .img-box {
  height: 100%;
  width: 100%;
  clip-path: url(#myShape);
  position: relative;
  top: -24px;
}
.index .sl-example .sl-row .sl-item .making {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 420px;
  padding: 0 50px;
  background-color: #018876;
}
@media (max-width: 1920px) {
  .index .sl-example .sl-row .sl-item .making {
    width: 300px;
    padding: 0 30px;
  }
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row .sl-item .making {
    width: 250px;
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row .sl-item .making {
    width: 150px;
    padding: 0 10px;
  }
}
.index .sl-example .sl-row .sl-item .making .making-box .title {
  font-size: 24px;
  color: #fff;
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row .sl-item .making .making-box .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row .sl-item .making .making-box .title {
    font-size: 16px;
  }
}
.index .sl-example .sl-row .sl-item .making .making-box .subtitle {
  padding-top: 20px;
  padding-bottom: 50px;
}
.index .sl-example .sl-row .sl-item .making .making-box .subtitle p {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  text-align: justify;
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row .sl-item .making .making-box .subtitle {
    padding-top: 24px;
    padding-bottom: 30px;
  }
  .index .sl-example .sl-row .sl-item .making .making-box .subtitle p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row .sl-item .making .making-box .subtitle {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .index .sl-example .sl-row .sl-item .making .making-box .subtitle p {
    font-size: 12px;
    line-height: 20px;
  }
}
.index .sl-example .sl-row .sl-item .making .making-box .btn-all {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
}
@media (max-width: 1120px) {
  .index .sl-example .sl-row .sl-item .making .making-box .btn-all {
    width: 86px;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .index .sl-example .sl-row .sl-item .making .making-box .btn-all {
    width: 70px;
    height: 26px;
    border-radius: 15px;
    font-size: 12px;
    line-height: 26px;
  }
}
.index .sl-example .sl-row .sl-item .making .making-box .btn-all:hover {
  background-color: #fff;
  color: #018876;
}
.index .sl-swiper3 {
  padding-bottom: 120px;
}
@media (max-width: 1920px) {
  .index .sl-swiper3 {
    padding-bottom: 100px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper3 {
    padding-bottom: 80px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper3 {
    padding-bottom: 60px;
  }
}
.index .sl-swiper3 .swiper-box {
  width: 100%;
  height: 200px;
  position: relative;
}
@media (max-width: 1120px) {
  .index .sl-swiper3 .swiper-box {
    height: 180px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper3 .swiper-box {
    height: 180px;
  }
}
.index .sl-swiper3 .swiper-box .homeSwiper3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide:hover .img-box {
  transform: scale(1.1);
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 {
  position: absolute;
  left: 0;
  bottom: -100%;
  right: 0;
  padding: 20px 20px 10px 20px;
  background: linear-gradient(to bottom, rgba(1, 136, 118, 0), rgba(1, 136, 118, 0.3), rgba(1, 136, 118, 0.6));
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .name {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 10px;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .text {
  font-size: 14px;
  color: #fff;
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .right .icon {
  width: 30px;
}
@media (max-width: 1120px) {
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 {
    padding: 10px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .name {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .text {
    font-size: 12px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .right .icon {
    width: 24px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 {
    padding: 10px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .name {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .text {
    font-size: 12px;
  }
  .index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide .info2 .right .icon {
    width: 20px;
  }
}
.index .sl-swiper3 .swiper-box .homeSwiper3 .swiper-slide:hover .info2 {
  bottom: 0;
}
.index .sl-swiper3 .homeSwiper3-button-prev {
  margin: 30px 35px;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-swiper3 .homeSwiper3-button-prev {
    margin: 26px 30px;
    width: 50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper3 .homeSwiper3-button-prev {
    margin: 20px 25px;
    width: 40px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper3 .homeSwiper3-button-prev {
    margin: 12px 10px;
    width: 30px;
  }
}
.index .sl-swiper3 .homeSwiper3-button-next {
  margin: 30px 35px;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .index .sl-swiper3 .homeSwiper3-button-next {
    margin: 26px 30px;
    width: 50px;
  }
}
@media (max-width: 1120px) {
  .index .sl-swiper3 .homeSwiper3-button-next {
    margin: 20px 25px;
    width: 40px;
  }
}
@media (max-width: 992px) {
  .index .sl-swiper3 .homeSwiper3-button-next {
    margin: 12px 10px;
    width: 30px;
  }
}
@media print, screen and (min-width: 768px) {
  .index #u-news .tab-news .m-txt-ttl7 {
    margin-bottom: 0;
  }
}
.index #u-news .tab-news .m-box-tabhead .tabhead-list {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: 0;
}
.index #u-news .tab-news .m-box-tabhead .tabhead-list .list-item:before {
  display: none;
}
@media print, screen and (min-width: 768px) {
  .index #u-news .tab-news .m-box-tabhead .tabhead-list .list-item {
    font-size: 1.4rem;
  }
}
.index .u-list-link {
  margin-top: 20px;
  overflow: hidden;
}
@media print, screen and (min-width: 768px) {
  .index .u-list-link {
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 768px) {
  .index .u-list-link .link-list {
    margin: 0 -22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media print {
  .index .u-list-link .link-list {
    display: block;
    letter-spacing: -0.4em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index .u-list-link .link-list .list-item {
    width: 50%;
    width: calc(50% - 0.1px) \0 ;
    padding: 0 22px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-list-link .link-list .list-item {
    width: 25%;
    width: calc(25% - 0.1px) \0 ;
    padding: 0 22px;
  }
}
@media print {
  .index .u-list-link .link-list .list-item {
    width: 25%;
    display: inline-block;
    padding: 0 22px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    letter-spacing: normal;
  }
}
.index .u-list-link .link-list .list-item + .list-item {
  margin-top: 40px;
}
@media print, screen and (min-width: 768px) {
  .index .u-list-link .link-list .list-item + .list-item {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index .u-list-link .link-list .list-item:nth-child(n+3) {
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-list-link .link-list .list-item:nth-child(n+5) {
    margin-top: 40px;
  }
}
.index .u-list-link .link-list .list-item > a {
  height: 100%;
}
.index .u-btn-common {
  position: relative;
  display: block;
}
.index .u-btn-common::before {
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #e6e6e6;
}
.index .u-btn-common::after {
  content: "";
  display: block;
  width: 20px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #222222;
}
.index .u-btn-common .btn-inner {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0px 31px 15px 22px;
  display: inline-block;
  position: relative;
}
.index [lang=cn] .u-btn-common .btn-inner {
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .index .u-btn-common .btn-inner {
    font-size: 2rem;
    padding: 0px 0 30px 40px;
  }
}
.index .u-btn-common .btn-inner::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: url(../../svg/i_arrow-222222.svg) center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 2px;
  bottom: 15px;
  left: 0;
  margin: auto;
}
@media print, screen and (min-width: 768px) {
  .index .u-btn-common .btn-inner::after {
    bottom: 30px;
    left: 4px;
  }
}
.index [data-whatinput=keyboard] .u-btn-common .btn-inner:focus::after {
  -webkit-animation-name: arrowLinkRight;
  animation-name: arrowLinkRight;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
@media print, screen and (min-width: 1025px) {
  .index .u-btn-common .btn-inner:hover::after {
    -webkit-animation-name: arrowLinkRight;
    animation-name: arrowLinkRight;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
}
.index #u-products {
  padding-top: 30px;
}
@media print, screen and (min-width: 768px) {
  .index #u-products {
    padding-top: 60px;
  }
}
.index #u-company {
  padding-top: 30px;
}
@media print, screen and (min-width: 768px) {
  .index #u-company {
    padding-top: 60px;
  }
}
.index .products-inner,
.index .company-inner {
  width: 100%;
  overflow: hidden;
}
.index .products-inner .company-keyvisual,
.index .products-inner .products-keyvisual,
.index .company-inner .company-keyvisual,
.index .company-inner .products-keyvisual {
  position: relative;
  z-index: 1;
}
.index .products-inner .company-keyvisual:before,
.index .products-inner .products-keyvisual:before,
.index .company-inner .company-keyvisual:before,
.index .company-inner .products-keyvisual:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 50%;
  background: #f6f6f6;
}
.index .products-inner .company-keyvisual .keyvisual-img,
.index .products-inner .products-keyvisual .keyvisual-img,
.index .company-inner .company-keyvisual .keyvisual-img,
.index .company-inner .products-keyvisual .keyvisual-img {
  position: relative;
  z-index: 10;
}
.index .products-inner .company-keyvisual .keyvisual-img img,
.index .products-inner .products-keyvisual .keyvisual-img img,
.index .company-inner .company-keyvisual .keyvisual-img img,
.index .company-inner .products-keyvisual .keyvisual-img img {
  width: 100%;
}
.index .products-inner .company-keyvisual .keyvisual-ttl,
.index .products-inner .products-keyvisual .keyvisual-ttl,
.index .company-inner .company-keyvisual .keyvisual-ttl,
.index .company-inner .products-keyvisual .keyvisual-ttl {
  font-size: 3.6rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  right: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  line-height: 1.25;
  z-index: 20;
}
.index [lang=cn] .products-inner .company-keyvisual .keyvisual-ttl,
.index [lang=cn] .products-inner .products-keyvisual .keyvisual-ttl,
.index [lang=cn] .company-inner .company-keyvisual .keyvisual-ttl,
.index [lang=cn] .company-inner .products-keyvisual .keyvisual-ttl {
  font-weight: 700;
}
.index [lang=ja] .products-inner .company-keyvisual .keyvisual-ttl,
.index [lang=ja] .products-inner .products-keyvisual .keyvisual-ttl,
.index [lang=ja] .company-inner .company-keyvisual .keyvisual-ttl,
.index [lang=ja] .company-inner .products-keyvisual .keyvisual-ttl {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=en] .products-inner .company-keyvisual .keyvisual-ttl,
.index [lang=en] .products-inner .products-keyvisual .keyvisual-ttl,
.index [lang=en] .company-inner .company-keyvisual .keyvisual-ttl,
.index [lang=en] .company-inner .products-keyvisual .keyvisual-ttl {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=cn] .products-inner .company-keyvisual .keyvisual-ttl,
.index [lang=cn] .products-inner .products-keyvisual .keyvisual-ttl,
.index [lang=cn] .company-inner .company-keyvisual .keyvisual-ttl,
.index [lang=cn] .company-inner .products-keyvisual .keyvisual-ttl {
  letter-spacing: 0.05em;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .company-keyvisual .keyvisual-ttl,
  .index .products-inner .products-keyvisual .keyvisual-ttl,
  .index .company-inner .company-keyvisual .keyvisual-ttl,
  .index .company-inner .products-keyvisual .keyvisual-ttl {
    font-size: 6.4rem;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .company-keyvisual .keyvisual-ttl,
  .index .products-inner .products-keyvisual .keyvisual-ttl,
  .index .company-inner .company-keyvisual .keyvisual-ttl,
  .index .company-inner .products-keyvisual .keyvisual-ttl {
    font-size: 8rem;
  }
}
.index .products-inner .company-keyvisual .keyvisual-start_stop,
.index .products-inner .products-keyvisual .keyvisual-start_stop,
.index .company-inner .company-keyvisual .keyvisual-start_stop,
.index .company-inner .products-keyvisual .keyvisual-start_stop {
  position: absolute;
  z-index: 999;
  bottom: 20px;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .company-keyvisual .keyvisual-start_stop,
  .index .products-inner .products-keyvisual .keyvisual-start_stop,
  .index .company-inner .company-keyvisual .keyvisual-start_stop,
  .index .company-inner .products-keyvisual .keyvisual-start_stop {
    bottom: 50px;
  }
}
.index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
.index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn,
.index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
.index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn {
  position: relative;
  background: center no-repeat;
  background-size: 100% auto;
  border: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  background-image: url(../../svg/i_stop_white.svg);
}
.index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:before,
.index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:before,
.index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:before,
.index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:before {
  content: "";
  position: absolute;
  top: -22px;
  bottom: -22px;
  left: -22px;
  right: -22px;
  width: 44px;
  height: 44px;
  display: block;
  margin: auto;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
  .index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn,
  .index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
  .index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn {
    width: 36px;
    height: 36px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
}
.index [data-whatinput=keyboard] .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
.index [data-whatinput=keyboard] .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
.index [data-whatinput=keyboard] .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
.index [data-whatinput=keyboard] .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus {
  background-image: url(../../svg/i_stop_white_on.svg);
}
.index [data-whatinput=keyboard] .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
.index [data-whatinput=keyboard] .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
.index [data-whatinput=keyboard] .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
.index [data-whatinput=keyboard] .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active {
  background-image: url(../../svg/i_start_white.svg);
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
  .index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
  .index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
  .index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover {
    background-image: url(../../svg/i_stop_white_on.svg);
  }
  .index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
  .index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
  .index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
  .index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active {
    background-image: url(../../svg/i_start_white.svg);
  }
}
.index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
.index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
.index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
.index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
}
.index .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
.index .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
.index .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
.index .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn.is-active {
  background-image: url(../../svg/i_start_white_on.svg);
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-keyvisual,
  .index .company-inner .products-keyvisual {
    max-width: 1520px;
    margin: 0 auto;
    position: relative;
    margin-right: auto;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .products-keyvisual:before,
  .index .company-inner .products-keyvisual:before {
    margin-right: calc(730px - 50vw);
  }
}
.index .products-inner .products-keyvisual .keyvisual-img,
.index .company-inner .products-keyvisual .keyvisual-img {
  margin-right: 20px;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .products-keyvisual .keyvisual-img,
  .index .company-inner .products-keyvisual .keyvisual-img {
    margin-right: 30px;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .products-keyvisual .keyvisual-img,
  .index .company-inner .products-keyvisual .keyvisual-img {
    margin-left: calc(730px - 50vw);
    margin-right: 0;
    padding-right: 30px;
  }
}
.index .products-inner .products-keyvisual .keyvisual-ttl,
.index .company-inner .products-keyvisual .keyvisual-ttl {
  padding-right: 20px;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-keyvisual .keyvisual-ttl,
  .index .company-inner .products-keyvisual .keyvisual-ttl {
    padding-left: 0;
    padding-right: 0;
    margin-left: 480px;
    margin-right: 30px;
  }
}
.index .products-inner .products-keyvisual .keyvisual-start_stop,
.index .company-inner .products-keyvisual .keyvisual-start_stop {
  right: 40px;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .products-keyvisual .keyvisual-start_stop,
  .index .company-inner .products-keyvisual .keyvisual-start_stop {
    right: 80px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .company-keyvisual,
  .index .company-inner .company-keyvisual {
    max-width: 1520px;
    margin: 0 auto;
    position: relative;
    margin-right: auto;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .company-keyvisual:before,
  .index .company-inner .company-keyvisual:before {
    margin-left: calc(730px - 50vw);
  }
}
.index .products-inner .company-keyvisual .keyvisual-img,
.index .company-inner .company-keyvisual .keyvisual-img {
  margin-left: 20px;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .company-keyvisual .keyvisual-img,
  .index .company-inner .company-keyvisual .keyvisual-img {
    margin-left: 30px;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .company-keyvisual .keyvisual-img,
  .index .company-inner .company-keyvisual .keyvisual-img {
    margin-right: calc(730px - 50vw);
    margin-left: 0;
    padding-left: 30px;
  }
}
.index .products-inner .company-keyvisual .keyvisual-ttl,
.index .company-inner .company-keyvisual .keyvisual-ttl {
  padding-left: 20px;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .company-keyvisual .keyvisual-ttl,
  .index .company-inner .company-keyvisual .keyvisual-ttl {
    padding-right: 0;
    padding-left: 0;
    margin-right: 480px;
    margin-left: 30px;
  }
}
.index .products-inner .company-keyvisual .keyvisual-start_stop,
.index .company-inner .company-keyvisual .keyvisual-start_stop {
  left: 40px;
}
@media print, screen and (min-width: 768px) {
  .index .products-inner .company-keyvisual .keyvisual-start_stop,
  .index .company-inner .company-keyvisual .keyvisual-start_stop {
    left: 80px;
  }
}
.index .products-inner .products-content,
.index .products-inner .company-content,
.index .company-inner .products-content,
.index .company-inner .company-content {
  background-color: #f6f6f6;
  padding-bottom: 55px;
  position: relative;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content,
  .index .products-inner .company-content,
  .index .company-inner .products-content,
  .index .company-inner .company-content {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}
.index .products-inner .products-content .content-inner,
.index .products-inner .company-content .content-inner,
.index .company-inner .products-content .content-inner,
.index .company-inner .company-content .content-inner {
  margin: 0 auto;
  position: relative;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content .content-inner,
  .index .products-inner .company-content .content-inner,
  .index .company-inner .products-content .content-inner,
  .index .company-inner .company-content .content-inner {
    bottom: calc(100% - 60px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 30px;
  }
}
@media print, screen and (min-width: 1520px) {
  .index .products-inner .products-content .content-inner,
  .index .products-inner .company-content .content-inner,
  .index .company-inner .products-content .content-inner,
  .index .company-inner .company-content .content-inner {
    max-width: 1520px;
  }
}
.index .products-inner .products-content .content-inner .content-summary,
.index .products-inner .company-content .content-inner .content-summary,
.index .company-inner .products-content .content-inner .content-summary,
.index .company-inner .company-content .content-inner .content-summary {
  z-index: 10;
  background-color: #fff;
  padding: 30px 20px 40px;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content .content-inner .content-summary,
  .index .products-inner .company-content .content-inner .content-summary,
  .index .company-inner .products-content .content-inner .content-summary,
  .index .company-inner .company-content .content-inner .content-summary {
    position: absolute;
    margin-left: 0;
    width: 450px;
    padding: 60px 70px 60px;
    min-height: 365px;
    bottom: calc(100% + 30px);
  }
}
.index .products-inner .products-content .content-inner .content-summary .summary-ttl,
.index .products-inner .company-content .content-inner .content-summary .summary-ttl,
.index .company-inner .products-content .content-inner .content-summary .summary-ttl,
.index .company-inner .company-content .content-inner .content-summary .summary-ttl {
  color: #222222;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5;
}
.index [lang=cn] .products-inner .products-content .content-inner .content-summary .summary-ttl,
.index [lang=cn] .products-inner .company-content .content-inner .content-summary .summary-ttl,
.index [lang=cn] .company-inner .products-content .content-inner .content-summary .summary-ttl,
.index [lang=cn] .company-inner .company-content .content-inner .content-summary .summary-ttl {
  font-weight: 700;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content .content-inner .content-summary .summary-ttl,
  .index .products-inner .company-content .content-inner .content-summary .summary-ttl,
  .index .company-inner .products-content .content-inner .content-summary .summary-ttl,
  .index .company-inner .company-content .content-inner .content-summary .summary-ttl {
    font-size: 3rem;
  }
}
.index .products-inner .products-content .content-inner .content-summary .summary-desc,
.index .products-inner .company-content .content-inner .content-summary .summary-desc,
.index .company-inner .products-content .content-inner .content-summary .summary-desc,
.index .company-inner .company-content .content-inner .content-summary .summary-desc {
  color: #222222;
  margin-top: 15px;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content .content-inner .content-summary .summary-desc,
  .index .products-inner .company-content .content-inner .content-summary .summary-desc,
  .index .company-inner .products-content .content-inner .content-summary .summary-desc,
  .index .company-inner .company-content .content-inner .content-summary .summary-desc {
    margin-top: 20px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-content .u-list-link,
  .index .products-inner .company-content .u-list-link,
  .index .company-inner .products-content .u-list-link,
  .index .company-inner .company-content .u-list-link {
    margin-top: 0;
  }
}
.index .company-content .content-inner {
  padding-left: 20px;
  padding-right: 0;
}
@media print, screen and (min-width: 768px) {
  .index .company-content .content-inner {
    padding-left: 30px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .company-content .content-inner {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 30px;
  }
}
.index .products-content .content-inner {
  padding-right: 20px;
  padding-left: 0;
}
@media print, screen and (min-width: 768px) {
  .index .products-content .content-inner {
    padding-right: 30px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-content .content-inner {
    padding: 0 30px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -30px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-link .link-item {
    width: 50%;
    width: calc(50% - 0.1px) \0 ;
    padding: 0 30px;
  }
}
@media print {
  .index .products-inner .products-link .link-item {
    width: 50%;
    display: inline-block;
    padding: 0 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    letter-spacing: normal;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-link .link-item .u-list-link .link-list {
    margin: 0 -30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media print {
  .index .products-inner .products-link .link-item .u-list-link .link-list {
    display: block;
    letter-spacing: -0.4em;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-link .link-item .u-list-link .link-list .list-item {
    width: 50%;
    width: calc(50% - 0.1px) \0 ;
    padding: 0 30px;
  }
}
@media print {
  .index .products-inner .products-link .link-item .u-list-link .link-list .list-item {
    width: 50%;
    display: inline-block;
    padding: 0 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    letter-spacing: normal;
  }
}
.index .products-inner .products-link .link-item:nth-child(n+3) {
  margin-top: 40px;
}
@media print, screen and (min-width: 1025px) {
  .index .products-inner .products-link .m-list-icon {
    margin-top: 0;
  }
}
.index #u-instagram {
  background-color: #303030;
  padding: 40px 30px 35px;
  overflow: hidden;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram {
    padding: 60px 30px 45px;
  }
}
.index #u-instagram .instagram-wrap {
  margin: 0 auto;
  position: relative;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap {
    max-width: 1460px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl {
    position: absolute;
    left: 0;
    width: 355px;
    height: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 2;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner {
  background-color: #303030;
  width: 100%;
  padding: 0 16px 36px;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon {
  width: 100%;
  text-align: center;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon img {
  width: 30px;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon img {
    width: 44px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
  margin-top: 10px;
  width: 100%;
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.07em;
}
.index [lang=ja] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=en] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
  letter-spacing: 0.05em;
}
.index [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
  font-weight: 700;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
    font-size: 3rem;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link {
  margin-top: -5px;
  width: 100%;
  text-align: center;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link {
    margin-top: 0px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
  letter-spacing: 0.03em;
}
.index [lang=ja] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=en] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
  letter-spacing: 0.05em;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[target=_blank]:not(.noticon):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 22px;
  margin-left: 5px;
  background: url("../../svg/i_blank-fff.svg") no-repeat right top;
  background-size: 100% auto;
  position: relative;
  top: 0.1em;
  width: 12px;
  top: 0.2em;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".pdf"]:not(.noticon):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 22px;
  margin-left: 5px;
  background: url("../../svg/i_pdf-fff.svg") no-repeat right top;
  background-size: 100% auto;
  position: relative;
  top: 0.1em;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".doc"]:not(.noticon):after, .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".docx"]:not(.noticon):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 22px;
  margin-left: 5px;
  background: url("../../svg/i_word-fff.svg") no-repeat right top;
  background-size: 100% auto;
  position: relative;
  top: 0.1em;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".xls"]:not(.noticon):after, .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".xlsx"]:not(.noticon):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 22px;
  margin-left: 5px;
  background: url("../../svg/i_excel-fff.svg") no-repeat right top;
  background-size: 100% auto;
  position: relative;
  top: 0.1em;
}
.index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".zip"]:not(.noticon):after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 22px;
  margin-left: 5px;
  background: url("../../svg/i_zip-fff.svg") no-repeat right top;
  background-size: 100% auto;
  position: relative;
  top: 0.1em;
  top: 0.2em;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
    font-size: 1.4rem;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item {
  position: relative;
  padding: 0 16px;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item {
    margin: 0 auto;
    padding: 0;
    padding-left: 355px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner {
  position: relative;
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container {
  overflow: visible;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container {
    margin: 0;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-wrapper .swiper-slide {
  width: 200px;
  height: 200px;
}
@media print, screen and (min-width: 768px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-wrapper .swiper-slide {
    width: 250px;
    height: 250px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
  position: relative;
  background-color: rgba(255, 255, 255, 0.08);
  margin: 30px 155px 15px 0;
  width: calc(100% - 155px);
  height: 3px;
  display: none;
}
@media print, screen and (min-width: 768px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
    margin: 40px 155px 15px 0;
  }
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
    margin-left: -355px;
    width: calc(100% + 355px - 155px);
  }
}
@media print, screen and (min-width: 1460px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
    width: calc(100% + 355px - 155px);
    margin-left: -355px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination .swiper-pagination-progressbar-fill {
  background-color: white;
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button {
  position: absolute;
  bottom: 15px;
  background-color: transparent;
  width: 50px;
  height: 30px;
  border: none;
  z-index: 1;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  outline: none;
  padding: 0;
}
@media print, screen and (min-width: 768px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button {
    width: 72px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button .button-txt {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-prev {
  right: 38px;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
@media print, screen and (min-width: 768px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-prev {
    right: 72px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next {
  right: -12px;
}
@media print, screen and (min-width: 768px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next {
    right: 0px;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next::before {
  content: "";
  display: block;
  width: 1px;
  height: 18px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.index [data-whatinput=keyboard] #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button:focus::after {
  -webkit-animation-name: arrowLinkRight;
  animation-name: arrowLinkRight;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
@media print, screen and (min-width: 1025px) {
  .index #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button:hover::after {
    -webkit-animation-name: arrowLinkRight;
    animation-name: arrowLinkRight;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
}
.index #u-instagram .instagram-wrap .instagram-inner .u-list-item.is-active .item-inner .item-swiper.swiper-container .swiper-pagination {
  display: block;
}
.index .u-box-picture {
  display: block;
  height: 100%;
  background-color: #f6f6f6;
}
.index .u-box-picture .picture-img {
  display: block;
  height: 100%;
}
.index .u-box-picture .picture-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.index .u-box-keyvisual .keyvisual-inner {
  position: relative;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image {
  height: calc(100vh - 80px);
  height: calc(var(--vh, 1vh) * 100 - 96px);
  min-height: 350px;
  position: relative;
  overflow: hidden;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-image {
    height: calc(100vh - 80px);
    height: calc(var(--vh, 1vh) * 100 - 80px);
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg {
  background: center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1000px;
  right: 50%;
  overflow: hidden;
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj {
  -webkit-transform: skew(45deg);
  transform: skew(45deg);
  -webkit-transform-origin: 25% center;
  transform-origin: 25% center;
  background: center no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50vw;
  width: 100vw;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter {
  position: absolute;
  top: -25vw;
  bottom: -25vw;
  left: -250vw;
  right: 50%;
  overflow: hidden;
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg);
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter .filter-obj {
  background-color: #222222;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter .filter-obj.rotate {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .img-bg {
  right: -1000px;
  left: 50%;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .img-bg .bg-obj {
  left: -50vw;
  -webkit-transform-origin: 75% center;
  transform-origin: 75% center;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .image-filter {
  top: -25vw;
  bottom: -25vw;
  right: -250vw;
  left: calc(50% - 1px);
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .image-filter .filter-obj {
  background-color: #018675;
  right: -100%;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-chemistry-visible=true] .image-filter .filter-obj, .index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-design-visible=true] .image-filter .filter-obj {
  -webkit-animation-name: keyvisual_open;
  animation-name: keyvisual_open;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-chemistry-visible=false] .image-filter .filter-obj, .index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-design-visible=false] .image-filter .filter-obj {
  -webkit-animation-name: keyvisual_close;
  animation-name: keyvisual_close;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 0.9);
  animation-timing-function: cubic-bezier(0, 0, 0, 0.9);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj[data-keyvisual-chemistry-zoom=true], .index .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj[data-keyvisual-design-zoom=true] {
  -webkit-animation-name: keyvisual_zoom;
  animation-name: keyvisual_zoom;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt {
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt {
    top: 0;
    bottom: 20px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  color: #fff;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.index [lang=ja] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  letter-spacing: 0.05em;
}
.index [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
    letter-spacing: 0.1em;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.25;
  margin-top: 9px;
  letter-spacing: 0.1em;
  padding-right: 25px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub {
    padding-right: 43px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
  font-size: 1.7rem;
  color: #fff;
  font-weight: 500;
}
.index [lang=ja] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
  font-family: Noto Sans SC;
  letter-spacing: 0.05em;
}
.index [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
  letter-spacing: 0.05em;
}
.index [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
    font-size: 4rem;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt.txt-right {
  margin-left: 13px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt.txt-right {
    margin-left: 40px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross {
  display: inline-block;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src {
  margin-left: 16px;
  margin-right: 16px;
  display: inline-block;
  width: 20px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src {
    width: 36px;
    /* margin-left: 30px; */
    margin-right: 30px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src[data-keyvisual-cross=is-anim] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 1s cubic-bezier(0.69, 0.01, 0.48, 0.99);
  transition: all 1s cubic-bezier(0.69, 0.01, 0.48, 0.99);
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  margin-top: 15px;
  letter-spacing: 0.1em;
  font-weight: 500;
  padding: 0 16px;
  font-family: "Noto Sans SC", "microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.index [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
    font-size: 18px;
    margin-top: 23px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
  position: absolute;
  background: center no-repeat;
  background-size: 100% auto;
  bottom: 50px;
  right: 160px;
  border: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
    width: 36px;
    height: 36px;
    bottom: 96px;
    right: 229px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn img {
  width: 100%;
  height: 100%;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=start] .img1 {
  display: block;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=start] .img2 {
  display: none;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=stop] .img1 {
  display: none;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=stop] .img2 {
  display: block;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
  position: absolute;
  overflow: hidden;
  display: block;
  bottom: 20px;
  /* left: 20px; */
  width: 90px;
  right: 40px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
    bottom: 50px;
    right: 50px;
    left: auto;
    width: 130px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link::before {
  content: "";
  display: block;
  width: 17px;
  height: 16px;
  background-size: 100% auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(0) rotateZ(90deg);
  transform: translateY(0) rotateZ(90deg);
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link::before {
    width: 24px;
    height: 22px;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link .link-circle {
  -webkit-animation-name: scrolldown_circle;
  animation-name: scrolldown_circle;
  -webkit-animation-duration: 14s;
  animation-duration: 14s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link .link-circle2 {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -28px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
}
.index [data-whatinput=keyboard] .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link:focus::before {
  -webkit-animation-name: arrowLinkBottom;
  animation-name: arrowLinkBottom;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link:hover::before {
    -webkit-animation-name: arrowLinkBottom;
    animation-name: arrowLinkBottom;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link[data-keyvisual-scroll-anim=true] .link-circle {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link[data-keyvisual-scroll-anim=false] .link-circle {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.index .u-box-keyvisual .keyvisual-important .important-inner {
  padding: 30px 16px;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner {
    max-width: 1460px;
    margin: 0 auto 0;
    padding: 0 30px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}
.index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap {
  position: relative;
  background-color: #fff;
}
.index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child {
  margin-top: 0 !important;
}
.index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child.m-box-section {
  padding-top: 0;
}
.index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child.m-list-desc.desc-inlineblock {
  margin-top: -10px !important;
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > .position-left:first-child + *,
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > .position-right:first-child + * {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child [data-gs-col]:first-child > *:first-child {
    margin-top: 0 !important;
  }
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
    right: 20px;
  }
  .index .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
    right: 130px;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child > [data-gs-col] > *:first-child {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child[data-gs-tab="1"] > [data-gs-col]:first-child > *:first-child {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child[data-gs-tab="2"] > [data-gs-col]:nth-child(-n+2) > *:first-child {
    margin-top: 0 !important;
  }
}
@media print, screen and (min-width: 768px) {
  .index .u-box-keyvisual .keyvisual-important .important-inner .important-wrap {
    margin-top: -110px;
    background-color: #8F001F;
    color: white;
    width: 220px;
    height: 220px;
    padding: 10px 50px;
  }
}
@-webkit-keyframes keyvisual_zoom {
  0% {
    -webkit-transform: skew(45deg);
    transform: skew(45deg);
  }
  100% {
    -webkit-transform: scale(1.2) skew(45deg);
    transform: scale(1.2) skew(45deg);
  }
}
@keyframes keyvisual_zoom {
  0% {
    -webkit-transform: skew(45deg);
    transform: skew(45deg);
  }
  100% {
    -webkit-transform: scale(1.2) skew(45deg);
    transform: scale(1.2) skew(45deg);
  }
}
@-webkit-keyframes keyvisual_open {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes keyvisual_open {
  0% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@-webkit-keyframes keyvisual_close {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes keyvisual_close {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes scrolldown_circle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes scrolldown_circle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.index .instaPost .postList {
  margin: 0 -1.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media print {
  .index .instaPost .postList {
    display: block;
    letter-spacing: -0.4em;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .instaPost .postList {
    margin: 0 -2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media print {
  .index .instaPost .postList {
    display: block;
    letter-spacing: -0.4em;
  }
}
.index .instaPost .postList .listItem {
  width: 33.3333333333%;
  width: calc(33.3333333333% - 0.1px) \0 ;
  padding: 0 1.5px;
}
@media print {
  .index .instaPost .postList .listItem {
    width: 33.3333333333%;
    display: inline-block;
    padding: 0 1.5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    letter-spacing: normal;
  }
}
@media print, screen and (min-width: 1025px) {
  .index .instaPost .postList .listItem {
    width: 25%;
    width: calc(25% - 0.1px) \0 ;
    padding: 0 2px;
  }
}
@media print {
  .index .instaPost .postList .listItem {
    width: 25%;
    display: inline-block;
    padding: 0 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: top;
    letter-spacing: normal;
  }
}
.index .instaPost .postList .listItem .itemInner {
  display: block;
}
.index .num_Science_bg {
  width: 100%;
  height: 750px;
  position: relative;
  background-color: #f5f5f5;
}
.index .num_text_content {
  width: 100%;
  margin: auto;
  position: relative;
}
.index .text_width {
  width: 95%;
  max-width: 1320px;
  margin: auto;
}
.index .num_Science_img_list {
  width: 100%;
  height: 650px;
  position: absolute;
}
.index .lbt-box {
  position: relative;
  max-width: 1090px;
  width: 80%;
  height: 100%;
  margin: auto;
}
.index .imgs {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  margin-bottom: 30px;
}
.index .imgitem {
  position: absolute;
  height: 650px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  opacity: 0;
}
.index .imgitem .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
}
@media (max-width: 1920px) {
  .index .imgitem .making {
    padding: 30px;
  }
}
@media (max-width: 1120px) {
  .index .imgitem .making {
    padding: 20px;
  }
}
@media (max-width: 992px) {
  .index .imgitem .making {
    padding: 10px;
  }
}
.index .imgitem .making .making-box {
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 50px;
}
@media (max-width: 1920px) {
  .index .imgitem .making .making-box {
    border-radius: 15px;
    padding: 50px;
  }
}
@media (max-width: 1120px) {
  .index .imgitem .making .making-box {
    border-radius: 10px;
    padding: 25px;
  }
}
@media (max-width: 992px) {
  .index .imgitem .making .making-box {
    border-radius: 5px;
    padding: 10px;
  }
}
.index .imgitem .making .making-box .title {
  font-size: 24px;
  color: #fff;
  padding-bottom: 20px;
}
@media (max-width: 1120px) {
  .index .imgitem .making .making-box .title {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .index .imgitem .making .making-box .title {
    font-size: 18px;
    padding-bottom: 5px;
  }
}
.index .imgitem .making .making-box .subtitle {
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  opacity: 0.8;
}
@media (max-width: 1120px) {
  .index .imgitem .making .making-box .subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .index .imgitem .making .making-box .subtitle {
    font-size: 14px;
    line-height: 20px;
  }
}
.index .imgitem .making .making-box .btn-all {
  background-color: #018876;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  line-height: 36px;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .index .imgitem .making .making-box .btn-all {
    margin-top: 30px;
    width: 90px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .index .imgitem .making .making-box .btn-all {
    margin-top: 30px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    font-size: 12px;
  }
}
.index .imgitem .making .making-box .btn-all:hover {
  background-color: #fff;
  color: #018876;
}
.index .imgitem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index .cur-img {
  z-index: 100;
  opacity: 1;
  transition: 1.2s;
}
.index .pre-img {
  transform: translateX(-294px) scale(1);
  z-index: 9;
  opacity: 0.9;
  transition: 0.8s;
}
.index .next-img {
  transform: translateX(293px) scale(1);
  z-index: 9;
  opacity: 0.9;
  transition: 0.8s;
}
.index #pre {
  display: inline-block;
  cursor: pointer;
}
.index #next {
  display: inline-block;
  cursor: pointer;
}
.index .number_sort {
  display: flex;
  justify-content: space-between;
  max-width: 840px;
  width: 60%;
  position: absolute;
  bottom: -40px;
}
.index .prduce_right_list_bg {
  max-width: 431px;
  width: 40% !important;
  height: 384px;
  position: absolute;
  right: -38px;
  background-color: white;
  z-index: 4;
  top: 248px;
  padding: 49px 56px 0;
  line-height: 1;
}
.index .prduce_right_list_bg .learn-more {
  text-align: center;
  width: 100%;
  height: 90px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: center;
}
.index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h2 {
  margin: 30px 0 20px 0;
}
.index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8 {
  font-size: 16px;
  letter-spacing: 4px;
  line-height: 25px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.index .page_list {
  display: flex;
  text-align: center;
}
.index .page_list div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 2px;
  background-color: #ccc;
  margin-right: 4px;
  display: inline-block;
  cursor: pointer;
}
.index .page_list .active {
  border: 1px solid #018876;
  color: #a31326;
}
.index .btn_play {
  max-width: 33px;
  width: 4vw;
  max-height: 33px;
  height: 4vw;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  .index .produce_list_text_p {
    display: flex;
  }
}
@media (max-width: 1300px) {
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8 {
    letter-spacing: 2px !important;
  }
}
@media (max-width: 768px) {
  .index .num_Science_bg {
    height: 500px;
    height: auto !important;
  }
  .index .num_Science_bg .num_text_content {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .index .num_Science_bg .num_text_content .text_width {
    width: 100%;
    padding: 0 5%;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list {
    height: 226px;
    position: static;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box {
    width: 100%;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .imgs {
    overflow: hidden;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .imgs .imgitem {
    width: 100%;
    height: 210px;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg {
    width: 46% !important;
    right: 5% !important;
    height: 230px !important;
    top: 70px !important;
    padding: 16px 5% 0 !important;
    overflow: hidden;
    line-height: normal;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg p {
    font-size: 12px !important;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h2 {
    font-size: 16px !important;
    letter-spacing: 2px !important;
    margin: 10px 0 !important;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h3 {
    font-size: 12px !important;
    letter-spacing: 2px !important;
    line-height: 16px !important;
  }
  .index .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8 {
    font-size: 12px !important;
    line-height: 18px !important;
    -webkit-line-clamp: 4;
  }
  .index .learn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    height: 80px !important;
  }
  .index .learn-more div {
    width: 90px !important;
    height: 28px !important;
    line-height: 26px !important;
    border-width: 1px !important;
    font-size: 12px !important;
  }
  .index .number_sort {
    z-index: 2;
    bottom: -20px;
    width: 100%;
  }
  .index .number_sort .page_list {
    display: inline-block;
    text-align: left;
    width: 90%;
    margin: 0 5%;
  }
  .index .number_sort .page_list div {
    width: 20px;
    height: 2px;
    background-color: #ccc;
    margin-right: 4px;
    display: inline-block;
  }
  .index .number_sort .arrow {
    display: none !important;
  }
}

.goods {
  overflow: hidden;
}
.goods .ad .sl-carousel {
  position: relative;
}
.goods .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.goods .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.goods .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .goods .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .goods .ad .sl-carousel .making .title {
    font-size: 24px;
  }
}
.goods .ad .sl-carousel .making .subtitle {
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}
@media (max-width: 1120px) {
  .goods .ad .sl-carousel .making .subtitle {
    font-size: 20px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .goods .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.goods .nav1 {
  padding: 0 0px 70px 0;
  position: relative;
  top: -30px;
  z-index: 1;
}
@media (max-width: 1120px) {
  .goods .nav1 {
    padding: 0 0px 40px 0;
    top: -20px;
  }
}
@media (max-width: 992px) {
  .goods .nav1 {
    padding: 0 0px 20px 0;
    top: -10px;
  }
}
.goods .nav1 .ul {
  margin: 0 -4px;
}
.goods .nav1 .ul .li {
  height: 60px;
  flex: 1;
  margin: 0 4px;
  border-radius: 10px;
  background-color: #9e9e9e;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .goods .nav1 .ul .li {
    height: 50px;
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .goods .nav1 .ul .li {
    height: 40px;
    font-size: 16px;
  }
}
.goods .nav1 .ul .li .img {
  width: 24px;
  margin-left: 15px;
}
@media (max-width: 1120px) {
  .goods .nav1 .ul .li .img {
    width: 18px;
    margin-left: 10px;
  }
}
@media (max-width: 992px) {
  .goods .nav1 .ul .li .img {
    width: 15px;
    margin-left: 5px;
  }
}
.goods .nav1 .ul .li.active {
  background-color: #018876;
  color: #fff;
}
.goods .sl-swiper4 {
  background-color: #f2f2f2;
  padding: 50px 0 70px 0;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 {
    padding: 40px 0 50px 0;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 {
    padding: 20px 0;
  }
}
.goods .sl-swiper4 .left {
  padding: 0 40px;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .left {
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .left {
    padding: 0;
  }
}
.goods .sl-swiper4 .left .title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .left .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .left .title {
    font-size: 16px;
  }
}
.goods .sl-swiper4 .left .text {
  padding-top: 15px;
}
.goods .sl-swiper4 .left .text p {
  font-size: 16px;
  color: #666;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .left .text {
    padding-top: 10px;
  }
  .goods .sl-swiper4 .left .text p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .left .text {
    padding-top: 5px;
  }
  .goods .sl-swiper4 .left .text p {
    font-size: 12px;
  }
}
.goods .sl-swiper4 .right {
  position: relative;
  top: -120px;
  margin-top: 15px;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .right {
    top: 0;
    padding-bottom: 50px;
  }
}
.goods .sl-swiper4 .right .swiper-box {
  width: 100%;
  height: 350px;
  position: relative;
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .right .swiper-box {
    height: 300px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .right .swiper-box {
    height: 250px;
  }
}
.goods .sl-swiper4 .right .swiper-box .homeSwiper4 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.goods .sl-swiper4 .right .swiper-box .homeSwiper4 .swiper-slide {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.goods .sl-swiper4 .right .swiper-box .homeSwiper4 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.goods .sl-swiper4 .right .swiper-left {
  padding-top: 60px;
}
@media (max-width: 1920px) {
  .goods .sl-swiper4 .right .swiper-left {
    padding-top: 40px;
  }
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .right .swiper-left {
    padding-top: 30px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .right .swiper-left {
    padding-top: 15px;
  }
}
.goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-prev {
  margin-right: 40px;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-prev {
    width: 40px;
    margin-right: 30px;
  }
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-prev {
    width: 35px;
    margin-right: 20px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-prev {
    width: 30px;
    margin-right: 20px;
  }
}
.goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-next {
  cursor: pointer;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-next {
    width: 40px;
  }
}
@media (max-width: 1120px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-next {
    width: 35px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper4 .right .swiper-left .homeSwiper4-button-next {
    width: 30px;
  }
}
.goods .sl-swiper4 .right .swiper-right {
  padding-top: 60px;
}
.goods .sl-swiper4 .right .swiper-right .icon {
  height: 40px;
  margin-left: 20px;
}
.goods .advantage {
  padding: 70px 0px 0 0px;
}
@media (max-width: 1120px) {
  .goods .advantage {
    padding: 50px 0px 0 0px;
  }
}
@media (max-width: 992px) {
  .goods .advantage {
    padding: 30px 0px 0 0px;
  }
}
.goods .advantage .advantage-title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .advantage .advantage-title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .advantage-title {
    font-size: 16px;
  }
}
.goods .advantage .advantage-title i {
  width: 1px;
  height: 40px;
  background-color: #018876;
  transform: rotate(30deg);
  margin: 0 15px;
}
@media (max-width: 1120px) {
  .goods .advantage .advantage-title i {
    margin: 0 10px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .advantage-title i {
    margin: 0 5px;
    height: 20px;
  }
}
.goods .advantage .advantage-title span {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .advantage .advantage-title span {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .advantage-title span {
    font-size: 14px;
  }
}
.goods .advantage .tab {
  border-bottom: 1px solid #018876;
  padding-top: 30px;
}
@media (max-width: 1120px) {
  .goods .advantage .tab {
    padding-top: 20px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .tab {
    padding-top: 10px;
  }
}
.goods .advantage .tab .name {
  width: 200px;
  height: 40px;
  border-radius: 10px 10px 0 0;
  background-color: #018876;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}
@media (max-width: 1120px) {
  .goods .advantage .tab .name {
    width: 150px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .tab .name {
    width: 120px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
  }
}
.goods .advantage .img-li {
  padding-top: 20px;
}
.goods .advantage .img-li .image {
  width: 100%;
}
.goods .advantage .img-desc {
  padding: 30px 0 20px 0;
}
.goods .advantage .img-desc p {
  font-size: 18px;
  color: #666;
  opacity: 0.7;
}
@media (max-width: 1920px) {
  .goods .advantage .img-desc {
    padding: 20px 0 10px 0;
  }
  .goods .advantage .img-desc p {
    font-size: 16px;
  }
}
@media (max-width: 1120px) {
  .goods .advantage .img-desc {
    padding: 10px 0 10px 0;
  }
  .goods .advantage .img-desc p {
    font-size: 14px;
  }
}
.goods .advantage .sl-row {
  padding-top: 15px;
}
.goods .advantage .sl-row .sl-item {
  margin: 15px 0;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1920px) {
  .goods .advantage .sl-row .sl-item {
    margin: 10px 0;
  }
}
@media (max-width: 1120px) {
  .goods .advantage .sl-row .sl-item {
    margin: 5px 0;
  }
}
.goods .advantage .sl-row .sl-item .right {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.goods .advantage .sl-row .sl-item .left {
  padding: 60px 75px;
}
@media (max-width: 1920px) {
  .goods .advantage .sl-row .sl-item .left {
    padding: 40px 50px;
  }
}
@media (max-width: 1120px) {
  .goods .advantage .sl-row .sl-item .left {
    padding: 20px 30px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .sl-row .sl-item .left {
    padding: 15px;
  }
}
.goods .advantage .sl-row .sl-item .left .title {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .advantage .sl-row .sl-item .left .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .sl-row .sl-item .left .title {
    font-size: 16px;
  }
}
.goods .advantage .sl-row .sl-item .left .text {
  height: 150px;
  padding-top: 20px;
}
.goods .advantage .sl-row .sl-item .left .text p {
  font-size: 16px;
  color: #666;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .goods .advantage .sl-row .sl-item .left .text {
    padding-top: 15px;
  }
  .goods .advantage .sl-row .sl-item .left .text p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .sl-row .sl-item .left .text {
    padding-top: 10px;
  }
  .goods .advantage .sl-row .sl-item .left .text p {
    font-size: 12px;
  }
}
.goods .advantage .sl-row .sl-item .left .btn-all {
  font-size: 18px;
  color: #018876;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .goods .advantage .sl-row .sl-item .left .btn-all {
    font-size: 16px;
  }
}
@media (max-width: 1120px) {
  .goods .advantage .sl-row .sl-item .left .btn-all {
    font-size: 14px;
    margin-top: 5px;
  }
}
@media (max-width: 992px) {
  .goods .advantage .sl-row .sl-item .left .btn-all {
    font-size: 14px;
  }
}
.goods .advantage .sl-row .sl-item .left .btn-all:hover {
  opacity: 0.7;
}
.goods .area {
  padding: 50px 0 0 0;
}
@media (max-width: 1120px) {
  .goods .area {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 992px) {
  .goods .area {
    padding: 30px 0 0 0;
  }
}
.goods .area .advantage-title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .area .advantage-title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .goods .area .advantage-title {
    font-size: 16px;
  }
}
.goods .area .advantage-title i {
  width: 1px;
  height: 40px;
  background-color: #018876;
  transform: rotate(30deg);
  margin: 0 15px;
}
@media (max-width: 1120px) {
  .goods .area .advantage-title i {
    margin: 0 10px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .goods .area .advantage-title i {
    margin: 0 5px;
    height: 20px;
  }
}
.goods .area .advantage-title span {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .area .advantage-title span {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .goods .area .advantage-title span {
    font-size: 14px;
  }
}
.goods .area .area-item {
  margin-top: 30px;
}
@media (max-width: 1920px) {
  .goods .area .area-item {
    margin-top: 20px;
  }
}
@media (max-width: 1120px) {
  .goods .area .area-item {
    margin-top: 10px;
  }
}
.goods .area .area-item .img-box {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1920px) {
  .goods .area .area-item .img-box {
    height: 250px;
  }
}
@media (max-width: 1120px) {
  .goods .area .area-item .img-box {
    height: 180px;
  }
}
@media (max-width: 992px) {
  .goods .area .area-item .img-box {
    height: 100px;
  }
}
.goods .area .area-item .title {
  height: 60px;
  font-size: 18px;
  color: #333;
  line-height: 60px;
  text-align: center;
}
@media (max-width: 1920px) {
  .goods .area .area-item .title {
    height: 50px;
    font-size: 16px;
    line-height: 50px;
  }
}
@media (max-width: 1120px) {
  .goods .area .area-item .title {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .goods .area .area-item .title {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}
.goods .sl-swiper5 {
  margin-top: 30px;
  background-color: #018876;
}
.goods .sl-swiper5 .left {
  height: 100%;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .left {
    padding: 15px 0;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper5 .left {
    padding: 15px 0px;
  }
}
.goods .sl-swiper5 .left .title {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .left .title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper5 .left .title {
    font-size: 18px;
  }
}
.goods .sl-swiper5 .left .text {
  padding-top: 10px;
  max-width: 460px;
}
.goods .sl-swiper5 .left .text p {
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .left .text {
    padding-top: 10px;
  }
  .goods .sl-swiper5 .left .text p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper5 .left .text {
    padding-top: 10px;
  }
  .goods .sl-swiper5 .left .text p {
    font-size: 14px;
  }
}
.goods .sl-swiper5 .left .btn-all {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #fff;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  margin-top: 50px;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .left .btn-all {
    width: 80px;
    height: 32px;
    border-radius: 16px;
    font-size: 12px;
    line-height: 32px;
    margin-top: 20px;
  }
}
.goods .sl-swiper5 .left .btn-all:hover {
  background-color: #fff;
  color: #018876;
}
.goods .sl-swiper5 .right {
  position: relative;
  top: -50px;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .right {
    top: 0;
    margin: 15px 0;
  }
}
.goods .sl-swiper5 .right .img-box {
  width: 100%;
  height: 25vw;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media (max-width: 1120px) {
  .goods .sl-swiper5 .right .img-box {
    height: 300px;
  }
}
@media (max-width: 992px) {
  .goods .sl-swiper5 .right .img-box {
    height: 200px;
  }
}
.goods .design {
  padding: 0;
}
.goods .design .left .el-form-item__label {
  font-size: 16px;
  color: #828282;
}
@media (max-width: 1120px) {
  .goods .design .left .el-form-item__label {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .el-form-item__label {
    font-size: 12px;
  }
}
.goods .design .left .el-input {
  height: 100%;
}
.goods .design .left .el-input .el-input__inner {
  height: 100%;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .goods .design .left .el-input .el-input__inner {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .el-input .el-input__inner {
    font-size: 12px;
  }
}
.goods .design .left .el-select {
  height: 100%;
}
.goods .design .left .el-select .el-input__icon {
  color: #018876;
  font-size: 18px;
  line-height: 40px;
}
@media (max-width: 1120px) {
  .goods .design .left .el-select .el-input__icon {
    font-size: 24px;
    line-height: 46px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .el-select .el-input__icon {
    font-size: 20px;
    line-height: 40px;
  }
}
.goods .design .left .sl-form-item-box {
  height: 40px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.goods .design .left .sl-form-item-box .icon {
  width: 20px;
  margin-right: 10px;
}
@media (max-width: 1120px) {
  .goods .design .left .sl-form-item-box {
    height: 30px;
  }
  .goods .design .left .sl-form-item-box .icon {
    width: 20px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .sl-form-item-box {
    height: 30px;
  }
  .goods .design .left .sl-form-item-box .icon {
    width: 15px;
  }
}
.goods .design .left .sl-btn {
  margin-top: 40px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  background-color: #018876;
  color: #fff;
  cursor: pointer;
}
.goods .design .left .sl-btn:hover {
  opacity: 0.6;
}
.goods .design .left .history {
  padding-top: 50px;
}
@media (max-width: 1120px) {
  .goods .design .left .history {
    padding-top: 15px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .history {
    padding-top: 0px;
  }
}
.goods .design .left .history .title {
  height: 40px;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  border-bottom: 1px solid #999;
  margin-bottom: 8px;
}
@media (max-width: 1120px) {
  .goods .design .left .history .title {
    height: 30px;
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .history .title {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}
.goods .design .left .history .img {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  margin: 8px 0;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .goods .design .left .history .img {
    height: 60px;
  }
}
@media (max-width: 992px) {
  .goods .design .left .history .img {
    height: 40px;
  }
}
.goods .design .right .sl-row .sl-item {
  margin: 15px 0;
  cursor: pointer;
}
.goods .design .right .sl-row .sl-item .info {
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  border: 1px solid #ccc;
}
.goods .design .right .sl-row .sl-item .info .img-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.goods .design .right .sl-row .sl-item .info .name {
  font-size: 16px;
  color: #828282;
  text-align: right;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}
@media (max-width: 1120px) {
  .goods .design .right .sl-row .sl-item .info .name {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}
@media (max-width: 992px) {
  .goods .design .right .sl-row .sl-item .info .name {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
}
.goods .design .right .sl-row .sl-item .info .img-box {
  margin: 0 20px 20px 20px;
  height: 100px;
}
@media (max-width: 1120px) {
  .goods .design .right .sl-row .sl-item .info .img-box {
    margin: 0 20px 20px 20px;
    height: 80px;
  }
}
@media (max-width: 992px) {
  .goods .design .right .sl-row .sl-item .info .img-box {
    margin: 0 10px 10px 10px;
    height: 60px;
  }
}
.goods .design .right .sl-row .sl-item .title {
  height: 48px;
  color: #666;
  background-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
  font-size: 16px;
  line-height: 48px;
  padding: 0 10px;
  text-align: center;
}
@media (max-width: 1120px) {
  .goods .design .right .sl-row .sl-item .title {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
  }
}
@media (max-width: 992px) {
  .goods .design .right .sl-row .sl-item .title {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
  }
}
.goods .design .right .sl-pagination {
  padding: 20px 0;
}
.goods .design .right .sl-pagination .el-pagination {
  transform: scale(1.2);
}
@media (max-width: 1120px) {
  .goods .design .right .sl-pagination {
    padding: 20px 0;
  }
  .goods .design .right .sl-pagination .el-pagination {
    transform: scale(1.1);
  }
}
@media (max-width: 992px) {
  .goods .design .right .sl-pagination {
    padding: 10px 0;
  }
  .goods .design .right .sl-pagination .el-pagination {
    transform: scale(1);
  }
}
.goods .handle .handle-item {
  width: 100%;
  cursor: pointer;
  position: relative;
}
.goods .handle .handle-item .img-box {
  height: 220px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media (max-width: 1120px) {
  .goods .handle .handle-item .img-box {
    height: 180px;
  }
}
@media (max-width: 992px) {
  .goods .handle .handle-item .img-box {
    height: 120px;
  }
}
.goods .handle .handle-item .img-box .zoom-frame {
  position: absolute;
  cursor: move;
}
@media (max-width: 992px) {
  .goods .handle .handle-item .img-box .zoom-frame {
    display: none;
  }
}
.goods .handle .handle-item .title {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #666;
  text-align: center;
  padding: 0 10px;
}
@media (max-width: 1120px) {
  .goods .handle .handle-item .title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
}
@media (max-width: 992px) {
  .goods .handle .handle-item .title {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding: 0 10px;
  }
}
.goods .handle .handle-item .magnifier {
  position: absolute;
  border: 2px solid #000;
  overflow: hidden;
  pointer-events: none;
  cursor: none;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.el-select-dropdown .el-select-dropdown__item {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}

.zoom-preview {
  width: 875px;
  height: 580px;
  border-radius: 16px;
  padding: 40px;
  position: fixed;
  background: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
  z-index: 1000;
}
@media (max-width: 992px) {
  .zoom-preview {
    display: none;
  }
}
.zoom-preview img {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.profession {
  /* .point-container {
      position: relative;
      width: 100%;
      height: 100%;
  } */
  /* 其他样式保持不变 */
}
.profession .ad .sl-carousel {
  position: relative;
}
.profession .ad .sl-carousel .mySwiper2 {
  overflow: hidden;
}
.profession .ad .sl-carousel .mySwiper2 .swiper-wrapper {
  height: auto !important; /* 确保高度是自适应的 */
}
.profession .ad .sl-carousel .mySwiper2 .swiper-slide {
  height: 100%;
  position: relative;
}
.profession .ad .sl-carousel .mySwiper2 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profession .ad .sl-carousel .mySwiper {
  overflow: hidden;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  z-index: 1;
  height: 60px;
  padding: 0 10px;
}
@media (max-width: 1120px) {
  .profession .ad .sl-carousel .mySwiper {
    height: 50px;
    padding: 0 10px;
    bottom: 10px;
  }
}
@media (max-width: 992px) {
  .profession .ad .sl-carousel .mySwiper {
    height: 40px;
    padding: 0 5px;
    bottom: 10px;
  }
}
.profession .ad .sl-carousel .mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.profession .ad .sl-carousel .mySwiper .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media (max-width: 1920px) {
  .profession .ad .sl-carousel .mySwiper .swiper-slide .img-box {
    border-radius: 8px;
  }
}
@media (max-width: 1120px) {
  .profession .ad .sl-carousel .mySwiper .swiper-slide .img-box {
    border-radius: 6px;
  }
}
@media (max-width: 992px) {
  .profession .ad .sl-carousel .mySwiper .swiper-slide .img-box {
    border-radius: 4px;
  }
}
.profession .ad .sl-carousel .mySwiper .swiper-slide .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
}
@media (max-width: 1120px) {
  .profession .ad .sl-carousel .mySwiper .swiper-slide .making {
    font-size: 14px;
    border-radius: 6px;
  }
}
@media (max-width: 992px) {
  .profession .ad .sl-carousel .mySwiper .swiper-slide .making {
    font-size: 12px;
    border-radius: 5px;
  }
}
.profession .ad .sl-carousel .mySwiper .swiper-slide-thumb-active .making {
  background-color: rgba(1, 136, 118, 0.6);
}
.profession .tit {
  padding: 50px 0 0 0;
}
@media (max-width: 1120px) {
  .profession .tit {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 992px) {
  .profession .tit {
    padding: 20px 0 0 0;
  }
}
.profession .tit .tit-title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .profession .tit .tit-title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .profession .tit .tit-title {
    font-size: 16px;
  }
}
.profession .tit .tit-title i {
  width: 1px;
  height: 40px;
  background-color: #018876;
  transform: rotate(30deg);
  margin: 0 10px;
}
@media (max-width: 1120px) {
  .profession .tit .tit-title i {
    margin: 0 10px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .profession .tit .tit-title i {
    margin: 0 5px;
    height: 20px;
  }
}
.profession .tit .tit-title span {
  font-size: 18px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .profession .tit .tit-title span {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .profession .tit .tit-title span {
    font-size: 14px;
  }
}
.profession .tit .tit-all {
  font-size: 16px;
  color: #018876;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .profession .tit .tit-all {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .tit .tit-all {
    font-size: 12px;
  }
}
.profession .tit .tit-all:hover {
  opacity: 0.7;
}
.profession .tit .right .sl-row .sl-item {
  margin: 15px 0;
  cursor: pointer;
}
.profession .tit .right .sl-row .sl-item .info {
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-bottom: 20px;
}
@media (max-width: 1120px) {
  .profession .tit .right .sl-row .sl-item .info {
    padding-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .profession .tit .right .sl-row .sl-item .info {
    padding-bottom: 10px;
  }
}
.profession .tit .right .sl-row .sl-item .info .name {
  font-size: 14px;
  color: #828282;
  text-align: right;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
@media (max-width: 1120px) {
  .profession .tit .right .sl-row .sl-item .info .name {
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .tit .right .sl-row .sl-item .info .name {
    height: 15px;
    line-height: 15px;
    padding: 0 5px;
    font-size: 12px;
  }
}
.profession .tit .right .sl-row .sl-item .info .img-box {
  margin: 0 20px;
  height: 100px;
}
@media (max-width: 1120px) {
  .profession .tit .right .sl-row .sl-item .info .img-box {
    margin: 0 15px;
    height: 80px;
  }
}
@media (max-width: 992px) {
  .profession .tit .right .sl-row .sl-item .info .img-box {
    margin: 0 15px;
    height: 60px;
  }
}
.profession .tit .right .sl-row .sl-item .title {
  height: 40px;
  color: #666;
  background-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
  font-size: 16px;
  line-height: 40px;
  padding: 0 10px;
  text-align: center;
}
@media (max-width: 1120px) {
  .profession .tit .right .sl-row .sl-item .title {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .tit .right .sl-row .sl-item .title {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
  }
}
.profession .tit .tit-desc {
  font-size: 16px;
  color: #828282;
  padding: 20px 0;
}
@media (max-width: 1120px) {
  .profession .tit .tit-desc {
    font-size: 14px;
    padding: 10px 0;
  }
}
@media (max-width: 992px) {
  .profession .tit .tit-desc {
    font-size: 12px;
    padding: 5px 0;
  }
}
.profession .handle {
  padding: 30px 0;
}
@media (max-width: 1120px) {
  .profession .handle {
    padding: 20px 0 0 0;
  }
}
@media (max-width: 992px) {
  .profession .handle {
    padding: 10px 0 0 0;
  }
}
.profession .handle .handle-item {
  width: 100%;
  cursor: pointer;
}
.profession .handle .handle-item .img-box {
  height: 200px;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1120px) {
  .profession .handle .handle-item .img-box {
    height: 150px;
  }
}
@media (max-width: 992px) {
  .profession .handle .handle-item .img-box {
    height: 100px;
  }
}
.profession .handle .handle-item .title {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #666;
  text-align: center;
  padding: 0 10px;
}
@media (max-width: 1120px) {
  .profession .handle .handle-item .title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
}
@media (max-width: 992px) {
  .profession .handle .handle-item .title {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 5px;
  }
}
.profession .handle .handle-item .magnifier {
  position: absolute;
  border: 2px solid #000;
  overflow: hidden;
  pointer-events: none;
  cursor: none;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.profession .sl-swiper5 {
  margin-top: 20px;
  background-color: #018876;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 {
    margin-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 {
    margin-top: 10px;
  }
}
.profession .sl-swiper5 .left {
  height: 100%;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 .left {
    padding: 15px 0;
    display: block;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 .left {
    padding: 15px 0px;
  }
}
.profession .sl-swiper5 .left .title {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 .left .title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 .left .title {
    font-size: 18px;
  }
}
.profession .sl-swiper5 .left .text {
  padding-top: 10px;
}
.profession .sl-swiper5 .left .text p {
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 .left .text {
    padding-top: 10px;
  }
  .profession .sl-swiper5 .left .text p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 .left .text {
    padding-top: 5px;
  }
  .profession .sl-swiper5 .left .text p {
    font-size: 12px;
  }
}
.profession .sl-swiper5 .left .btn-all {
  width: 80px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #fff;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  margin-top: 40px;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 .left .btn-all {
    width: 70px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 24px;
    margin-top: 15px;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 .left .btn-all {
    width: 60px;
    height: 20px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 10px;
  }
}
.profession .sl-swiper5 .left .btn-all:hover {
  background-color: #fff;
  color: #018876;
}
.profession .sl-swiper5 .right {
  position: relative;
  top: -40px;
}
@media (max-width: 1120px) {
  .profession .sl-swiper5 .right {
    top: -30px;
  }
}
@media (max-width: 992px) {
  .profession .sl-swiper5 .right {
    top: 0;
  }
}
.profession .sl-swiper5 .right .img-box {
  width: 100%;
  height: 25vw;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media (max-width: 1120px) {
  .profession .advantage {
    padding: 0px;
  }
}
@media (max-width: 992px) {
  .profession .advantage {
    padding: 0px;
  }
}
.profession .advantage .sl-row {
  padding-top: 5px;
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row {
    padding-top: 0px;
  }
}
.profession .advantage .sl-row .sl-item {
  margin: 15px 0;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 1920px) {
  .profession .advantage .sl-row .sl-item {
    margin: 10px 0;
    border-radius: 8px;
  }
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row .sl-item {
    margin: 5px 0;
    border-radius: 5px;
  }
}
.profession .advantage .sl-row .sl-item .right {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profession .advantage .sl-row .sl-item .left {
  padding: 30px;
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row .sl-item .left {
    padding: 20px;
  }
}
@media (max-width: 992px) {
  .profession .advantage .sl-row .sl-item .left {
    padding: 15px;
  }
}
.profession .advantage .sl-row .sl-item .left .title {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row .sl-item .left .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .profession .advantage .sl-row .sl-item .left .title {
    font-size: 16px;
  }
}
.profession .advantage .sl-row .sl-item .left .text {
  height: 140px;
  padding-top: 20px;
}
.profession .advantage .sl-row .sl-item .left .text p {
  font-size: 16px;
  color: #666;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row .sl-item .left .text {
    height: 100px;
    padding-top: 15px;
  }
  .profession .advantage .sl-row .sl-item .left .text p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .advantage .sl-row .sl-item .left .text {
    height: 80px;
    padding-top: 10px;
  }
  .profession .advantage .sl-row .sl-item .left .text p {
    font-size: 12px;
  }
}
.profession .advantage .sl-row .sl-item .left .btn-all {
  font-size: 16px;
  color: #018876;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .profession .advantage .sl-row .sl-item .left .btn-all {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .profession .advantage .sl-row .sl-item .left .btn-all {
    font-size: 12px;
  }
}
.profession .advantage .sl-row .sl-item .left .btn-all:hover {
  opacity: 0.7;
}
.profession .recommended {
  padding: 0 0 40px 0;
}
@media (max-width: 1120px) {
  .profession .recommended {
    padding: 0 0 30px 0;
  }
}
@media (max-width: 992px) {
  .profession .recommended {
    padding: 0 0 20px 0;
  }
}
.profession .recommended .recommended-item {
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item {
    border-radius: 5px;
    padding: 10px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item {
    border-radius: 5px;
    padding: 5px;
  }
}
.profession .recommended .recommended-item .icon {
  width: 230px;
  height: 230px;
  border-radius: 10px;
}
@media (max-width: 1920px) {
  .profession .recommended .recommended-item .icon {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item .icon {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item .icon {
    width: 120px;
    height: 120px;
  }
}
.profession .recommended .recommended-item .cont {
  width: calc(100% - 180px);
  padding-left: 20px;
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item .cont {
    width: calc(100% - 150px);
    padding-left: 15px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item .cont {
    width: calc(100% - 120px);
    padding-left: 10px;
  }
}
.profession .recommended .recommended-item .cont .title {
  font-size: 18px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item .cont .title {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item .cont .title {
    font-size: 14px;
  }
}
.profession .recommended .recommended-item .cont .text {
  height: 90px;
  padding-top: 15px;
}
.profession .recommended .recommended-item .cont .text p {
  font-size: 14px;
  color: #828282;
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item .cont .text {
    height: 60px;
    padding-top: 10px;
  }
  .profession .recommended .recommended-item .cont .text p {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item .cont .text {
    height: 50px;
    padding-top: 5px;
  }
  .profession .recommended .recommended-item .cont .text p {
    font-size: 12px;
  }
}
.profession .recommended .recommended-item .cont .btn-all {
  width: 90px;
  height: 30px;
  border-radius: 15px;
  background-color: #018876;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
}
@media (max-width: 1120px) {
  .profession .recommended .recommended-item .cont .btn-all {
    width: 80px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 24px;
    margin-top: 15px;
  }
}
@media (max-width: 992px) {
  .profession .recommended .recommended-item .cont .btn-all {
    width: 70px;
    height: 20px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 10px;
  }
}
.profession .recommended .recommended-item .cont .btn-all:hover {
  opacity: 0.7;
}
.profession .point {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.profession .point-dot {
  width: 24px;
  height: 24px;
  background: #018876;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
}
.profession .point-pulse {
  position: absolute;
  width: 26px;
  height: 26px;
  background: rgba(33, 150, 243, 0.3);
  border-radius: 50%;
  top: -2px;
  left: -2px;
  animation: pulse 1.5s infinite;
}
.profession .point-label {
  position: absolute;
  color: #fff;
  font-size: 14px;
  z-index: 10;
}
.profession .label-popup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 8px;
  width: 200px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  /* 确保弹窗不会被其他元素遮挡 */
  z-index: 100;
}
.profession .popup-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
}
.profession .popup-desc {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 12px;
}
.profession .popup-more {
  margin: 50px auto 0 auto;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #018876;
  font-size: 12px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.profession .popup-more:hover {
  background-color: #fff;
  color: #018876;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.news .ad .sl-carousel {
  position: relative;
}
.news .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.news .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.news .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .news .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .news .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.news .ad .sl-carousel .making .subtitle {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 15px;
}
@media (max-width: 1120px) {
  .news .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .news .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.news .tit {
  padding: 60px 0 0 0;
}
@media (max-width: 1120px) {
  .news .tit {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 992px) {
  .news .tit {
    padding: 20px 0 0 0;
  }
}
.news .tit .tit-title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .news .tit .tit-title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .news .tit .tit-title {
    font-size: 16px;
  }
}
.news .tit .tit-title i {
  width: 1px;
  height: 40px;
  background-color: #018876;
  transform: rotate(30deg);
  margin: 0 10px;
}
@media (max-width: 1120px) {
  .news .tit .tit-title i {
    margin: 0 10px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .news .tit .tit-title i {
    margin: 0 5px;
    height: 20px;
  }
}
.news .tit .tit-title span {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .news .tit .tit-title span {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .news .tit .tit-title span {
    font-size: 14px;
  }
}
.news .tit .tit-all {
  font-size: 14px;
  color: #018876;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .tit .tit-all {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .news .tit .tit-all {
    font-size: 12px;
  }
}
.news .tit .tit-all:hover {
  opacity: 0.7;
}
.news .dynamic {
  padding: 40px 70px;
}
@media (max-width: 1120px) {
  .news .dynamic {
    padding: 20px;
  }
}
@media (max-width: 992px) {
  .news .dynamic {
    padding: 0px;
  }
}
.news .dynamic .left .img-box {
  height: 100%;
  width: 100%;
  padding: 8px 0;
}
@media (max-width: 1120px) {
  .news .dynamic .left .img-box {
    height: 250px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .left .img-box {
    height: 220px;
  }
}
.news .dynamic .left .img-box .img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.news .dynamic .left .text-box {
  margin: 8px 0;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0 30px;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box {
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .left .text-box {
    padding: 0 15px;
  }
}
.news .dynamic .left .text-box .u {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #018876;
}
.news .dynamic .left .text-box .u .time {
  width: 80px;
}
.news .dynamic .left .text-box .u .time .day {
  font-size: 24px;
  color: #000;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box .u .time .day {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .left .text-box .u .time .day {
    font-size: 18px;
  }
}
.news .dynamic .left .text-box .u .time .date {
  font-size: 18px;
  color: #999;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box .u .time .date {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .left .text-box .u .time .date {
    font-size: 14px;
  }
}
.news .dynamic .left .text-box .u .txt {
  width: calc(100% - 90px);
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box .u .txt {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .left .text-box .u .txt {
    font-size: 14px;
  }
}
.news .dynamic .left .text-box .n {
  padding: 10px 0 20px 0;
}
.news .dynamic .left .text-box .n .txt {
  font-size: 14px;
  color: #999;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box .n .txt {
    font-size: 14px;
  }
}
.news .dynamic .left .text-box .n .btn-all {
  font-size: 14px;
  color: #018876;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .dynamic .left .text-box .n .btn-all {
    font-size: 14px;
  }
}
.news .dynamic .left .text-box:hover {
  background-color: #018876;
}
.news .dynamic .left .text-box:hover .u {
  border-bottom: 1px solid #fff;
}
.news .dynamic .left .text-box:hover .txt,
.news .dynamic .left .text-box:hover .btn-all {
  color: #fff;
}
.news .dynamic .left .text-box:hover .time .day {
  color: #fff;
}
.news .dynamic .left .text-box:hover .time .date {
  color: #fff;
}
.news .dynamic .right {
  margin-top: 8px;
  height: 496px;
}
.news .dynamic .right .sl-row .sl-item {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px 30px;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item {
    padding: 15px 20px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .right .sl-row .sl-item {
    padding: 15px;
  }
}
.news .dynamic .right .sl-row .sl-item .time {
  width: 110px;
}
@media (max-width: 1920px) {
  .news .dynamic .right .sl-row .sl-item .time {
    width: 90px;
  }
}
.news .dynamic .right .sl-row .sl-item .time .day {
  font-size: 24px;
  color: #000;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item .time .day {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .right .sl-row .sl-item .time .day {
    font-size: 18px;
  }
}
.news .dynamic .right .sl-row .sl-item .time .date {
  font-size: 18px;
  color: #999;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item .time .date {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .right .sl-row .sl-item .time .date {
    font-size: 14px;
  }
}
.news .dynamic .right .sl-row .sl-item .cont {
  width: calc(100% - 90px);
}
.news .dynamic .right .sl-row .sl-item .cont .title {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item .cont .title {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news .dynamic .right .sl-row .sl-item .cont .title {
    font-size: 14px;
  }
}
.news .dynamic .right .sl-row .sl-item .cont .txt {
  height: 80px;
  padding-top: 10px;
}
.news .dynamic .right .sl-row .sl-item .cont .txt p {
  font-size: 14px;
  color: #999;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item .cont .txt p {
    font-size: 14px;
  }
}
.news .dynamic .right .sl-row .sl-item .cont .btn-all {
  font-size: 14px;
  color: #018876;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .dynamic .right .sl-row .sl-item .cont .btn-all {
    font-size: 14px;
  }
}
.news .dynamic .right .sl-row .sl-item:hover {
  background-color: #018876;
}
.news .dynamic .right .sl-row .sl-item:hover .title,
.news .dynamic .right .sl-row .sl-item:hover .btn-all {
  color: #fff;
}
.news .dynamic .right .sl-row .sl-item:hover .txt p {
  color: #fff;
}
.news .dynamic .right .sl-row .sl-item:hover .time .day {
  color: #fff;
}
.news .dynamic .right .sl-row .sl-item:hover .time .date {
  color: #fff;
}
.news .area {
  padding: 0;
}
.news .area .area-item {
  margin-top: 20px;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .news .area .area-item {
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  .news .area .area-item {
    margin-top: 0px;
  }
}
.news .area .area-item .img-box {
  width: 100%;
  height: 180px;
  border-radius: 10px;
}
@media (max-width: 1120px) {
  .news .area .area-item .img-box {
    height: 160px;
  }
}
@media (max-width: 992px) {
  .news .area .area-item .img-box {
    height: 120px;
  }
}
.news .area .area-item .title {
  height: 48px;
  font-size: 14px;
  color: #333;
  line-height: 48px;
  text-align: center;
}
@media (max-width: 1120px) {
  .news .area .area-item .title {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
  }
}
@media (max-width: 992px) {
  .news .area .area-item .title {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}
.news .area .area-item:hover .title {
  color: #018876;
}
.news .exhibition {
  padding: 30px 0;
}
@media (max-width: 1120px) {
  .news .exhibition {
    padding: 20px 0;
  }
}
@media (max-width: 992px) {
  .news .exhibition {
    padding: 10px 0;
  }
}
.news .exhibition .left {
  height: 100%;
}
.news .exhibition .left .img-box {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 1120px) {
  .news .exhibition .left .img-box {
    height: 300px;
  }
}
@media (max-width: 992px) {
  .news .exhibition .left .img-box {
    height: 200px;
  }
}
.news .exhibition .right {
  height: 500px;
  border-radius: 10px;
  background-color: #f2f2f2;
}
@media (max-width: 1120px) {
  .news .exhibition .right {
    margin-top: 10px;
    height: auto;
  }
}
@media (max-width: 992px) {
  .news .exhibition .right {
    margin-top: 10px;
    height: auto;
  }
}
.news .exhibition .right .sl-row .sl-item {
  border-bottom: 1px solid #d3d3d3;
  height: 60px;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
@media (max-width: 1120px) {
  .news .exhibition .right .sl-row .sl-item {
    height: 50px;
    padding: 0 15px;
  }
}
@media (max-width: 992px) {
  .news .exhibition .right .sl-row .sl-item {
    height: 40px;
    padding: 0 10px;
  }
}
.news .exhibition .right .sl-row .sl-item .label {
  font-size: 16px;
  color: #828282;
  padding-left: 15px;
  position: relative;
}
@media (max-width: 1120px) {
  .news .exhibition .right .sl-row .sl-item .label {
    font-size: 14px;
    padding-left: 10px;
  }
}
@media (max-width: 992px) {
  .news .exhibition .right .sl-row .sl-item .label {
    font-size: 12px;
    padding-left: 10px;
  }
}
.news .exhibition .right .sl-row .sl-item .label span {
  width: 2px;
  height: 16px;
  background-color: #018876;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.news .exhibition .right .sl-row .sl-item .time {
  font-size: 12px;
  color: #828282;
}
.news .exhibition .right .sl-row .sl-item:hover {
  background-color: #018876;
}
.news .exhibition .right .sl-row .sl-item:hover .label {
  color: #fff;
}
.news .exhibition .right .sl-row .sl-item:hover .label span {
  background-color: #fff;
}
.news .exhibition .right .sl-row .sl-item:hover .time {
  color: #fff;
}

.case .ad .sl-carousel {
  position: relative;
}
.case .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.case .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.case .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .case .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .case .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.case .ad .sl-carousel .making .subtitle {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 15px;
}
@media (max-width: 1120px) {
  .case .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .case .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.case .case-swiper {
  padding: 40px 70px;
}
@media (max-width: 1120px) {
  .case .case-swiper {
    padding: 30px 50px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper {
    padding: 15px 30px;
  }
}
.case .case-swiper .title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 1120px) {
  .case .case-swiper .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.case .case-swiper .swiper-box {
  width: 100%;
  height: 430px;
  padding: 0 50px;
  position: relative;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box {
    height: 370px;
    padding: 0 70px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box {
    height: 300px;
    padding: 0 50px;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide {
  height: 100%;
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item {
  height: 100%;
  padding: 10px;
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont {
  height: calc(100% - 20px);
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span {
  padding: 15px;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span {
    padding: 10px 10px;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .logo {
  height: 170px;
  display: block;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .logo {
    height: 150px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .logo {
    height: 120px;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul {
  padding: 10px 0;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul {
    padding: 15px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul {
    padding: 10px;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li {
  font-size: 16px;
  color: #828282;
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #018876;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li {
    font-size: 16px;
  }
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li span {
    width: 8px;
    height: 8px;
    margin-right: 10px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li {
    font-size: 14px;
  }
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .ul .li span {
    width: 8px;
    height: 8px;
    margin-right: 10px;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .desc {
  font-size: 14px;
  color: #828282;
  -webkit-line-clamp: 3;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .desc {
    font-size: 14px;
    -webkit-line-clamp: 2;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .desc {
    font-size: 14px;
    -webkit-line-clamp: 1;
  }
}
.case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .btn-all {
  margin-top: 40px;
  width: 80px;
  height: 30px;
  border-radius: 15px;
  background-color: #018876;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .btn-all {
    margin-top: 30px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .swiper-box .homeSwiper1 .swiper-slide .item .cont .span .btn-all {
    margin-top: 20px;
    width: 80px;
    height: 26px;
    border-radius: 13px;
    font-size: 12px;
    line-height: 26px;
  }
}
.case .case-swiper .homeSwiper1-button-prev {
  width: 50px;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .case .case-swiper .homeSwiper1-button-prev {
    width: 40px;
    left: 0px;
  }
}
@media (max-width: 1120px) {
  .case .case-swiper .homeSwiper1-button-prev {
    width: 30px;
    left: 0px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .homeSwiper1-button-prev {
    width: 20px;
    left: 0px;
  }
}
.case .case-swiper .homeSwiper1-button-next {
  width: 50px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 1920px) {
  .case .case-swiper .homeSwiper1-button-next {
    width: 40px;
    right: 0px;
  }
}
@media (max-width: 1120px) {
  .case .case-swiper .homeSwiper1-button-next {
    width: 30px;
    right: 0px;
  }
}
@media (max-width: 992px) {
  .case .case-swiper .homeSwiper1-button-next {
    width: 20px;
    right: 0px;
  }
}
.case .case-type {
  padding: 40px 0;
}
@media (max-width: 1120px) {
  .case .case-type {
    padding: 20px 0;
  }
}
@media (max-width: 992px) {
  .case .case-type {
    padding: 0;
  }
}
.case .case-type .title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 1120px) {
  .case .case-type .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 992px) {
  .case .case-type .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.case .case-type .sl-row {
  padding-bottom: 40px;
  border-bottom: 2px solid #018876;
}
@media (max-width: 1120px) {
  .case .case-type .sl-row {
    padding-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .case .case-type .sl-row {
    padding-bottom: 20px;
  }
}
.case .case-type .sl-row .sl-item {
  min-width: 200px;
  padding: 0 15px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #018876;
  font-size: 16px;
  color: #018876;
  line-height: 40px;
  text-align: center;
  margin: 20px 8px;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .case .case-type .sl-row .sl-item {
    min-width: 150px;
    padding: 0 15px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    line-height: 40px;
    margin: 10px 8px;
  }
}
@media (max-width: 992px) {
  .case .case-type .sl-row .sl-item {
    min-width: 100px;
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 30px;
    margin: 5px;
  }
}
.case .example .left .sl-form-item-box {
  height: 36px;
  border-radius: 6px;
  border: 1px solid #999;
  margin-right: 10px;
}
.case .example .left .sl-form-item-box .icon {
  width: 26px;
  margin-right: 10px;
}
@media (max-width: 1120px) {
  .case .example .left .sl-form-item-box {
    height: 30px;
  }
  .case .example .left .sl-form-item-box .icon {
    width: 24px;
  }
}
@media (max-width: 992px) {
  .case .example .left .sl-form-item-box {
    height: 30px;
  }
  .case .example .left .sl-form-item-box .icon {
    width: 20px;
  }
}
.case .example .left .el-input {
  height: 100%;
}
.case .example .left .el-input .el-input__inner {
  height: 100%;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .case .example .left .el-input .el-input__inner {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .case .example .left .el-input .el-input__inner {
    font-size: 12px;
  }
}
.case .example .left .el-select {
  height: 100%;
}
.case .example .left .el-select .el-input__icon {
  color: #018876;
  font-size: 20px;
  line-height: 30px;
}
@media (max-width: 1120px) {
  .case .example .left .el-select .el-input__icon {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .case .example .left .el-select .el-input__icon {
    font-size: 14px;
    line-height: 20px;
  }
}
.case .example .right {
  width: 100%;
}
.case .example .right .txt {
  font-size: 18px;
  color: #000;
}
@media (max-width: 1120px) {
  .case .example .right .txt {
    padding-top: 15px;
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .case .example .right .txt {
    font-size: 14px;
  }
}
.case .example .right .txt span {
  color: #018876;
}
.case .example .right .el-input {
  height: 100%;
}
.case .example .right .el-input .el-input__inner {
  height: 100%;
  font-size: 18px;
}
@media (max-width: 1120px) {
  .case .example .right .el-input .el-input__inner {
    margin-top: 15px;
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .case .example .right .el-input .el-input__inner {
    font-size: 14px;
  }
}
.case .example .right .el-select {
  height: 100%;
}
.case .example .right .el-select .el-input__icon {
  color: #018876;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1120px) {
  .case .example .right .el-select .el-input__icon {
    margin-top: 5px;
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .case .example .right .el-select .el-input__icon {
    font-size: 16px;
    line-height: 20px;
  }
}
.case .example .sl-item {
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin: 15px 0;
  cursor: pointer;
}
.case .example .sl-item .img-box {
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1120px) {
  .case .example .sl-item .img-box {
    height: 200px;
  }
}
@media (max-width: 992px) {
  .case .example .sl-item .img-box {
    height: 100px;
  }
}
.case .example .sl-item .info {
  padding: 30px 15px;
}
@media (max-width: 1120px) {
  .case .example .sl-item .info {
    padding: 20px 10px;
  }
}
@media (max-width: 992px) {
  .case .example .sl-item .info {
    padding: 10px;
  }
}
.case .example .sl-item .info .title {
  padding-bottom: 10px;
  font-size: 18px;
  color: #000;
  border-bottom: 1px solid #018876;
}
@media (max-width: 1120px) {
  .case .example .sl-item .info .title {
    padding-bottom: 10px;
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .case .example .sl-item .info .title {
    padding-bottom: 5px;
    font-size: 14px;
  }
}
.case .example .sl-item .info .desc {
  padding-top: 10px;
}
.case .example .sl-item .info .desc p {
  font-size: 16px;
  color: #828282;
  text-align: justify;
}
@media (max-width: 1120px) {
  .case .example .sl-item .info .desc p {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .case .example .sl-item .info .desc p {
    font-size: 12px;
    -webkit-line-clamp: 2;
  }
}
.case .example .sl-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.case .example .sl-pagination {
  padding: 50px 0;
}
.case .example .sl-pagination .el-pagination {
  transform: scale(1.3);
}
@media (max-width: 1920px) {
  .case .example .sl-pagination {
    padding: 30px 0;
  }
  .case .example .sl-pagination .el-pagination {
    transform: scale(1.2);
  }
}
@media (max-width: 1120px) {
  .case .example .sl-pagination {
    padding: 20px 0;
  }
  .case .example .sl-pagination .el-pagination {
    transform: scale(1.1);
  }
}
@media (max-width: 992px) {
  .case .example .sl-pagination {
    padding: 10px 0;
  }
  .case .example .sl-pagination .el-pagination {
    transform: scale(1);
  }
}

.technology .ad .sl-carousel {
  position: relative;
}
.technology .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.technology .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.technology .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .technology .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .technology .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.technology .ad .sl-carousel .making .subtitle {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding-top: 15px;
}
@media (max-width: 1120px) {
  .technology .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .technology .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.technology .advantage {
  padding: 30px 0 70px 0;
}
@media (max-width: 1120px) {
  .technology .advantage {
    padding: 20px 0 50px 0;
  }
}
@media (max-width: 992px) {
  .technology .advantage {
    padding: 10px 0 30px 0;
  }
}
.technology .advantage .tit {
  max-width: 1200px;
  margin: 40px auto;
}
@media (max-width: 1120px) {
  .technology .advantage .tit {
    margin: 30px auto;
  }
}
@media (max-width: 992px) {
  .technology .advantage .tit {
    margin: 0 auto;
  }
}
.technology .advantage .tit .tit-title {
  font-size: 26px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .technology .advantage .tit .tit-title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .technology .advantage .tit .tit-title {
    font-size: 18px;
  }
}
.technology .advantage .tit .tit-desc {
  padding-top: 15px;
}
.technology .advantage .tit .tit-desc p {
  font-size: 18px;
  color: #828282;
  text-align: center;
  max-width: 1150px;
}
@media (max-width: 1120px) {
  .technology .advantage .tit .tit-desc {
    padding-top: 10px;
  }
  .technology .advantage .tit .tit-desc p {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .technology .advantage .tit .tit-desc {
    padding-top: 10px;
  }
  .technology .advantage .tit .tit-desc p {
    font-size: 14px;
  }
}
.technology .advantage .sl-row {
  padding-top: 15px;
}
@media (max-width: 1920px) {
  .technology .advantage .sl-row {
    padding-top: 0;
  }
}
.technology .advantage .sl-row .sl-item {
  margin: 15px 0;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
}
.technology .advantage .sl-row .sl-item .right {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.technology .advantage .sl-row .sl-item .left {
  padding: 40px 50px;
}
@media (max-width: 1120px) {
  .technology .advantage .sl-row .sl-item .left {
    padding: 30px 40px;
  }
}
@media (max-width: 992px) {
  .technology .advantage .sl-row .sl-item .left {
    padding: 15px;
  }
}
.technology .advantage .sl-row .sl-item .left .title {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .technology .advantage .sl-row .sl-item .left .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .technology .advantage .sl-row .sl-item .left .title {
    font-size: 16px;
  }
}
.technology .advantage .sl-row .sl-item .left .text {
  height: 110px;
  padding-top: 20px;
}
.technology .advantage .sl-row .sl-item .left .text p {
  font-size: 16px;
  color: #666;
  opacity: 0.8;
  text-align: justify;
}
@media (max-width: 1120px) {
  .technology .advantage .sl-row .sl-item .left .text {
    padding-top: 10px;
    height: 100px;
  }
  .technology .advantage .sl-row .sl-item .left .text p {
    font-size: 14px;
  }
}
.technology .advantage .sl-row .sl-item .left .btn-all {
  font-size: 14px;
  color: #018876;
  cursor: pointer;
}
@media (max-width: 1120px) {
  .technology .advantage .sl-row .sl-item .left .btn-all {
    font-size: 14px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .technology .advantage .sl-row .sl-item .left .btn-all {
    font-size: 12px;
  }
}
.technology .advantage .sl-row .sl-item .left .btn-all:hover {
  opacity: 0.7;
}

.business .ad .sl-carousel {
  position: relative;
}
.business .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.business .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.business .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .business .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .business .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.business .ad .sl-carousel .making .subtitle {
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 1120px) {
  .business .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .business .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.business .adderss {
  padding: 30px 0 70px 0;
}
@media (max-width: 1120px) {
  .business .adderss {
    padding: 20px 0 40px 0;
  }
}
@media (max-width: 992px) {
  .business .adderss {
    padding: 10px 0;
  }
}
.business .adderss .title {
  font-size: 26px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 1120px) {
  .business .adderss .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .business .adderss .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.business .adderss .icon {
  width: 20px;
  display: block;
  margin: 0 auto 50px auto;
}
@media (max-width: 1120px) {
  .business .adderss .icon {
    width: 20px;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 992px) {
  .business .adderss .icon {
    width: 20px;
    margin: 0 auto 20px auto;
  }
}
.business .adderss .item {
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 15% 10%;
}
@media (max-width: 1120px) {
  .business .adderss .item {
    height: 350px;
    padding: 10% 6%;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .business .adderss .item {
    height: 300px;
    padding: 10% 6%;
  }
}
.business .adderss .item .image {
  height: 45px;
  display: block;
  margin: 0 auto;
}
@media (max-width: 1120px) {
  .business .adderss .item .image {
    height: 45px;
  }
}
@media (max-width: 992px) {
  .business .adderss .item .image {
    height: 35px;
  }
}
.business .adderss .item .sl-btn {
  height: 60px;
  border-radius: 35px;
  background-color: rgba(1, 130, 118, 0.5);
  font-size: 26px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  margin: 10% auto 10% auto;
}
@media (max-width: 1120px) {
  .business .adderss .item .sl-btn {
    height: 50px;
    border-radius: 30px;
    font-size: 22px;
    line-height: 50px;
    margin: 8% auto 12% auto;
  }
}
@media (max-width: 992px) {
  .business .adderss .item .sl-btn {
    height: 45px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 45px;
    margin: 5% auto 5% auto;
  }
}
.business .adderss .item .addr {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 1120px) {
  .business .adderss .item .addr {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .business .adderss .item .addr {
    font-size: 16px;
  }
}
.business .adderss .item2 {
  height: 250px;
  border-radius: 10px;
  padding: 10% 10%;
  background-color: #f2f2f2;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .business .adderss .item2 {
    height: 200px;
    padding: 8% 8%;
  }
}
@media (max-width: 992px) {
  .business .adderss .item2 {
    height: 180px;
    padding: 5% 5%;
  }
}
.business .adderss .item2 .sl-btn {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  background-color: rgb(1, 130, 118);
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  margin: 4% auto 5% auto;
}
@media (max-width: 1120px) {
  .business .adderss .item2 .sl-btn {
    width: 120px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 40px;
    margin: 5% auto;
  }
}
@media (max-width: 992px) {
  .business .adderss .item2 .sl-btn {
    width: 100px;
    height: 35px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 35px;
    margin: 5% auto;
  }
}
.business .adderss .item2 .addr {
  font-size: 18px;
  color: #828282;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 1120px) {
  .business .adderss .item2 .addr {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .business .adderss .item2 .addr {
    font-size: 14px;
  }
}
.business .adderss .item2:hover {
  background-color: #e6e6e6;
}

.about .ad .sl-carousel {
  position: relative;
}
.about .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.about .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.about .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .about .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .about .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.about .ad .sl-carousel .making .subtitle {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 1120px) {
  .about .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .about .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.about .tit {
  padding: 60px 0 0 0;
}
@media (max-width: 1120px) {
  .about .tit {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 992px) {
  .about .tit {
    padding: 20px 0 0 0;
  }
}
.about .tit .advantage-title {
  font-size: 26px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .about .tit .advantage-title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .about .tit .advantage-title {
    font-size: 16px;
  }
}
.about .tit .advantage-title i {
  width: 1px;
  height: 40px;
  background-color: #018876;
  transform: rotate(30deg);
  margin: 0 10px;
}
@media (max-width: 1120px) {
  .about .tit .advantage-title i {
    margin: 0 10px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .about .tit .advantage-title i {
    margin: 0 5px;
    height: 20px;
  }
}
.about .tit .advantage-title span {
  font-size: 18px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .about .tit .advantage-title span {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .about .tit .advantage-title span {
    font-size: 14px;
  }
}
.about .info {
  padding: 10px 0px 30px 0px;
}
@media (max-width: 1120px) {
  .about .info {
    padding: 10px 0px 20px 0px;
  }
}
@media (max-width: 992px) {
  .about .info {
    padding: 10px 0px;
  }
}
.about .info .left {
  height: 100%;
}
.about .info .left .text {
  font-size: 18px;
  color: #828282;
  line-height: 30px;
  max-width: 940px;
}
@media (max-width: 1120px) {
  .about .info .left .text {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .about .info .left .text {
    font-size: 14px;
    line-height: 24px;
  }
}
.about .info .right {
  height: 100%;
}
.about .info .right .logo {
  height: 120px;
}
@media (max-width: 1120px) {
  .about .info .right .logo {
    height: 100px;
    margin-top: 15px;
  }
}
@media (max-width: 992px) {
  .about .info .right .logo {
    height: 70px;
  }
}
.about .sl-row {
  padding: 40px 0px;
}
@media (max-width: 1120px) {
  .about .sl-row {
    padding: 30px 0px;
  }
}
@media (max-width: 992px) {
  .about .sl-row {
    padding: 15px 0px;
  }
}
.about .sl-row .sl-item {
  margin: 10px 0;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item {
    margin: 5px 0;
  }
}
.about .sl-row .sl-item .img-box {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ccc;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item .img-box {
    height: 150px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item .img-box {
    height: 100px;
  }
}
.about .sl-row .sl-item .span {
  height: 150px;
  padding-top: 30px;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item .span {
    height: 100px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item .span {
    height: 70px;
  }
}
.about .sl-row .sl-item .span .yar {
  border-left: 8px solid #018876;
  padding-left: 15px;
  padding-right: 10px;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item .span .yar {
    padding-left: 10px;
    border-left: 6px solid #018876;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item .span .yar {
    padding-left: 5px;
    border-left: 4px solid #018876;
  }
}
.about .sl-row .sl-item .span .yar .title {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item .span .yar .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item .span .yar .title {
    font-size: 16px;
  }
}
.about .sl-row .sl-item .span .yar .desc {
  font-size: 14px;
  color: #828282;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item .span .yar .desc {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item .span .yar .desc {
    font-size: 12px;
  }
}
.about .sl-row .sl-item2 {
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  height: 350px;
  padding-top: 20px;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item2 {
    height: 250px;
    padding-top: 10px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item2 {
    height: 170px;
  }
}
.about .sl-row .sl-item2 .title {
  font-size: 20px;
  color: #018876;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .about .sl-row .sl-item2 .title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .about .sl-row .sl-item2 .title {
    font-size: 16px;
  }
}

.advantage .ad .sl-carousel {
  position: relative;
}
.advantage .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.advantage .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.advantage .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .advantage .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .advantage .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.advantage .ad .sl-carousel .making .subtitle {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 1120px) {
  .advantage .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .advantage .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.advantage .about {
  padding: 100px 0 50px 0;
  background-color: #fff;
}
@media (max-width: 1120px) {
  .advantage .about {
    padding: 50px 0 30px 0;
  }
}
@media (max-width: 992px) {
  .advantage .about {
    padding: 10px 0;
  }
}
.advantage .about .title {
  font-size: 26px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}
@media (max-width: 1120px) {
  .advantage .about .title {
    font-size: 22px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .advantage .about .title {
    font-size: 18px;
    padding-bottom: 5px;
  }
}
.advantage .about .desc {
  font-size: 18px;
  color: #828282;
  line-height: 30px;
  text-align: center;
}
@media (max-width: 1120px) {
  .advantage .about .desc {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .advantage .about .desc {
    font-size: 14px;
    line-height: 24px;
  }
}
.advantage .about .sl-btn {
  margin: 60px auto 0 auto;
  width: 500px;
  height: 80px;
  border-radius: 40px;
  border: 2px solid #018876;
  font-size: 24px;
  color: #018876;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1920px) {
  .advantage .about .sl-btn {
    margin: 50px auto 0 auto;
    height: 70px;
    border-radius: 35px;
    font-size: 20px;
  }
}
@media (max-width: 1120px) {
  .advantage .about .sl-btn {
    margin: 40px auto 0 auto;
    height: 60px;
    border-radius: 30px;
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .advantage .about .sl-btn {
    margin: 30px auto 0 auto;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
  }
}
.advantage .about .sl-btn .icon {
  width: 40px;
  margin-right: 10px;
}
.advantage .about .sl-btn:hover {
  opacity: 0.7;
}
.advantage .core {
  padding-bottom: 70px;
}
@media (max-width: 1120px) {
  .advantage .core {
    padding-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .advantage .core {
    padding-bottom: 30px;
  }
}
.advantage .core .desc {
  padding: 30px 0;
}
.advantage .core .desc p {
  font-size: 18px;
  color: #828282;
  line-height: 30px;
  text-align: justify;
}
@media (max-width: 1120px) {
  .advantage .core .desc {
    padding: 20px 0;
  }
  .advantage .core .desc p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .advantage .core .desc {
    padding: 10px 0;
  }
  .advantage .core .desc p {
    font-size: 14px;
    line-height: 24px;
  }
}
.advantage .core .img {
  width: 100%;
  border-radius: 20px;
}

.el-collapse {
  border-top: none !important;
}
.el-collapse .el-collapse-item .el-collapse-item__header {
  border-bottom: 1px solid #018876;
  font-size: 18px;
  color: #018876;
  font-weight: bold;
  height: 70px;
  line-height: 70px;
}
@media (max-width: 1120px) {
  .el-collapse .el-collapse-item .el-collapse-item__header {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }
}
@media (max-width: 992px) {
  .el-collapse .el-collapse-item .el-collapse-item__header {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
  }
}
.el-collapse .el-collapse-item__wrap {
  border-bottom: none !important;
}

.connection .connection {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 15px;
}
@media (max-width: 1120px) {
  .connection .connection {
    padding: 50px 15px;
  }
}
@media (max-width: 992px) {
  .connection .connection {
    padding: 15px;
  }
}
.connection .connection .connection-box {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}
.connection .connection .connection-box .left {
  padding: 40px 60px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left {
    padding: 30px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left {
    padding: 15px 0px;
  }
}
.connection .connection .connection-box .left .title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .title {
    font-size: 18px;
  }
}
.connection .connection .connection-box .left .subtitle {
  font-size: 18px;
  color: #000;
  padding: 15px 0;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .subtitle {
    font-size: 16px;
    padding: 10px 0;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .subtitle {
    font-size: 14px;
    padding: 10px 0;
  }
}
.connection .connection .connection-box .left .ul {
  padding-bottom: 20px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .ul {
    padding-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .ul {
    padding-bottom: 10px;
  }
}
.connection .connection .connection-box .left .ul .li {
  font-size: 16px;
  color: #018876;
  padding: 5px 0;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .ul .li {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .ul .li {
    font-size: 12px;
  }
}
.connection .connection .connection-box .left .ul .li span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #018876;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -4px;
}
.connection .connection .connection-box .left .ul-box {
  padding-top: 0px;
}
.connection .connection .connection-box .left .ul-box .li-title {
  font-size: 16px;
  color: #000;
  padding: 5px 0;
  font-weight: bold;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .ul-box .li-title {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .ul-box .li-title {
    font-size: 12px;
  }
}
.connection .connection .connection-box .left .ul-box .li {
  font-size: 14px;
  color: #828282;
  padding: 5px 0;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .left .ul-box .li {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .left .ul-box .li {
    font-size: 12px;
  }
}
.connection .connection .connection-box .left .ul-box .li span {
  display: inline-block;
  width: 10px;
}
.connection .connection .connection-box .right {
  padding: 40px 60px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right {
    padding: 30px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right {
    padding: 0;
  }
}
.connection .connection .connection-box .right .title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .title {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .title {
    font-size: 18px;
  }
}
.connection .connection .connection-box .right .form {
  margin: 10px -5px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .form {
    margin: 5px 0;
  }
}
.connection .connection .connection-box .right .el-input {
  height: 100%;
}
.connection .connection .connection-box .right .el-input .el-input__inner {
  height: 100%;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .el-input .el-input__inner {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .el-input .el-input__inner {
    font-size: 14px;
  }
}
.connection .connection .connection-box .right .el-select {
  height: 100%;
}
.connection .connection .connection-box .right .el-select .el-input__icon {
  color: #018876;
  font-size: 18px;
  line-height: 40px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .el-select .el-input__icon {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .el-select .el-input__icon {
    font-size: 14px;
    line-height: 24px;
  }
}
.connection .connection .connection-box .right .sl-form-item-box {
  height: 40px;
  margin: 0 10px;
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #999;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .sl-form-item-box {
    height: 32px;
    border-radius: 5px;
    margin: 0 5px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .sl-form-item-box {
    height: 30px;
  }
}
.connection .connection .connection-box .right .sl-form-item-box .icon {
  width: 33px;
  margin-right: 10px;
}
.connection .connection .connection-box .right .sl-form-item-textarea {
  border-radius: 10px;
  border: 1px solid #999;
  overflow: hidden;
  background-color: #fff;
  padding: 10px;
}
.connection .connection .connection-box .right .sl-form-item-textarea .el-textarea__inner {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 16px;
  color: #333;
  height: 500px;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .sl-form-item-textarea .el-textarea__inner {
    font-size: 14px;
    height: 400px;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .sl-form-item-textarea .el-textarea__inner {
    font-size: 14px;
    height: 300px;
  }
}
.connection .connection .connection-box .right .btn-box {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  background-color: #018876;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  margin: 30px auto;
  cursor: pointer;
  transition: all 0.3s ease;
}
@media (max-width: 1120px) {
  .connection .connection .connection-box .right .btn-box {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    font-size: 16px;
    margin: 30px auto;
  }
}
@media (max-width: 992px) {
  .connection .connection .connection-box .right .btn-box {
    width: 120px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    font-size: 14px;
    margin: 20px auto;
  }
}
.connection .connection .connection-box .right .btn-box:hover {
  opacity: 0.7;
}

.news-detail .detail {
  padding: 100px;
}
@media (max-width: 1920px) {
  .news-detail .detail {
    padding: 70px 0;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail {
    padding: 40px 0;
  }
}
@media (max-width: 992px) {
  .news-detail .detail {
    padding: 20px 0;
  }
}
.news-detail .detail .left .years {
  max-width: 210px;
  font-size: 36px;
  color: #018876;
  border-bottom: 1px solid #018876;
}
@media (max-width: 1920px) {
  .news-detail .detail .left .years {
    font-size: 30px;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail .left .years {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .news-detail .detail .left .years {
    font-size: 24px;
  }
}
.news-detail .detail .left .month {
  font-size: 20px;
  color: #018876;
  margin-top: 10px;
}
@media (max-width: 1920px) {
  .news-detail .detail .left .month {
    font-size: 18px;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail .left .month {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news-detail .detail .left .month {
    font-size: 14px;
  }
}
.news-detail .detail .left .desc {
  padding-top: 60px;
}
.news-detail .detail .left .desc p {
  font-size: 20px;
  color: #828282;
  padding: 5px 0;
}
@media (max-width: 1920px) {
  .news-detail .detail .left .desc {
    padding-top: 40px;
  }
  .news-detail .detail .left .desc p {
    font-size: 18px;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail .left .desc {
    padding-top: 30px;
  }
  .news-detail .detail .left .desc p {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .news-detail .detail .left .desc {
    padding-top: 15px;
  }
  .news-detail .detail .left .desc p {
    font-size: 14px;
  }
}
.news-detail .detail .right .title {
  padding-bottom: 20px;
  border-bottom: 1px solid #018876;
}
.news-detail .detail .right .title p {
  font-size: 36px;
  color: #018876;
}
@media (max-width: 1920px) {
  .news-detail .detail .right .title p {
    font-size: 30px;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail .right .title p {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .news-detail .detail .right .title p {
    font-size: 24px;
  }
}
.news-detail .detail .right .content {
  padding: 30px 0;
}
@media (max-width: 1920px) {
  .news-detail .detail .right .content {
    padding: 25px 0;
  }
}
@media (max-width: 1120px) {
  .news-detail .detail .right .content {
    padding: 20px 0;
  }
}
@media (max-width: 992px) {
  .news-detail .detail .right .content {
    padding: 15px 0;
  }
}

.join .ad .sl-carousel {
  position: relative;
}
.join .ad .sl-carousel .img {
  margin: 0 auto;
  display: block;
  width: 100%;
}
.join .ad .sl-carousel .making {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.join .ad .sl-carousel .making .title {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1120px) {
  .join .ad .sl-carousel .making .title {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .join .ad .sl-carousel .making .title {
    font-size: 20px;
  }
}
.join .ad .sl-carousel .making .subtitle {
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 1120px) {
  .join .ad .sl-carousel .making .subtitle {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 992px) {
  .join .ad .sl-carousel .making .subtitle {
    font-size: 14px;
    padding-top: 10px;
  }
}
.join .about {
  padding: 100px 0;
  background-color: #f2f2f2;
}
@media (max-width: 1120px) {
  .join .about {
    padding: 70px 0;
  }
}
@media (max-width: 992px) {
  .join .about {
    padding: 50px 0;
  }
}
.join .about .title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}
@media (max-width: 1120px) {
  .join .about .title {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .join .about .title {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
.join .about .desc {
  font-size: 18px;
  color: #828282;
  line-height: 30px;
  text-align: justify;
}
@media (max-width: 1120px) {
  .join .about .desc {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 992px) {
  .join .about .desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.join .about .sl-btn {
  margin: 40px auto 0 auto;
  width: 400px;
  height: 60px;
  border-radius: 40px;
  border: 2px solid #018876;
  font-size: 18px;
  color: #018876;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.join .about .sl-btn .icon {
  width: 30px;
  margin-right: 10px;
}
@media (max-width: 1120px) {
  .join .about .sl-btn {
    margin: 40px auto 0 auto;
    width: 300px;
    height: 60px;
    border-radius: 30px;
    font-size: 18px;
  }
  .join .about .sl-btn .icon {
    width: 30px;
  }
}
@media (max-width: 992px) {
  .join .about .sl-btn {
    margin: 30px auto 0 auto;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
  }
  .join .about .sl-btn .icon {
    width: 26px;
  }
}
.join .about .sl-btn:hover {
  opacity: 0.7;
}
.join .about2 {
  padding: 50px 0;
  background-color: #fff;
}
@media (max-width: 1120px) {
  .join .about2 {
    padding: 40px 0;
  }
}
@media (max-width: 992px) {
  .join .about2 {
    padding: 30px 0;
  }
}
.join .about2 .title {
  font-size: 26px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}
@media (max-width: 1120px) {
  .join .about2 .title {
    font-size: 22px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .join .about2 .title {
    font-size: 20px;
    padding-bottom: 10px;
  }
}
.join .about2 .desc {
  font-size: 18px;
  color: #828282;
  line-height: 30px;
  text-align: center;
}
@media (max-width: 1120px) {
  .join .about2 .desc {
    font-size: 16px;
    line-height: 28px;
  }
}
@media (max-width: 992px) {
  .join .about2 .desc {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .join .about2 .row {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 1120px) {
  .join .about2 .row .wow {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 992px) {
  .join .about2 .row .wow {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.join .about2 .sl-btn {
  margin: 30px auto 0 auto;
  height: 50px;
  border-radius: 30px;
  border: 2px solid #018876;
  font-size: 18px;
  color: #018876;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1120px) {
  .join .about2 .sl-btn {
    margin: 30px auto 0 auto;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .join .about2 .sl-btn {
    margin: 30px auto 0 auto;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
  }
}
.join .about2 .sl-btn .icon {
  width: 40px;
  margin-right: 10px;
}
@media (max-width: 1920px) {
  .join .about2 .sl-btn .icon {
    width: 32px;
  }
}
@media (max-width: 1120px) {
  .join .about2 .sl-btn .icon {
    width: 26px;
  }
}
@media (max-width: 992px) {
  .join .about2 .sl-btn .icon {
    width: 20px;
  }
}
.join .about2 .sl-btn:hover {
  opacity: 0.7;
}
.join .sl-swiper2 {
  background-color: #fff;
  padding: 0 0 80px 0;
}
@media (max-width: 1120px) {
  .join .sl-swiper2 {
    padding: 0 0 90px 0;
  }
}
@media (max-width: 992px) {
  .join .sl-swiper2 {
    padding: 0 0 60px 0;
  }
}
.join .sl-swiper2 .swiper-box {
  width: 100%;
  height: 200px;
  position: relative;
}
@media (max-width: 1120px) {
  .join .sl-swiper2 .swiper-box {
    height: 180px;
  }
}
@media (max-width: 992px) {
  .join .sl-swiper2 .swiper-box {
    height: 150px;
  }
}
.join .sl-swiper2 .swiper-box .homeSwiper2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.join .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide {
  width: 80%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.join .sl-swiper2 .swiper-box .homeSwiper2 .swiper-slide .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
  bottom: -60px;
  text-align: center;
}
@media (max-width: 1920px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -50px;
  }
}
@media (max-width: 1120px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -40px;
  }
}
@media (max-width: 992px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination {
    bottom: -30px;
  }
}
.join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
  width: 110px;
  height: 5px;
  background-color: #d1d1d1;
  border-radius: 0;
}
@media (max-width: 1920px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 80px;
    height: 4px;
  }
}
@media (max-width: 1120px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 60px;
    height: 3px;
  }
}
@media (max-width: 992px) {
  .join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
  }
}
.join .sl-swiper2 .swiper-box .homeSwiper2 .homeSwiper2-pagination .swiper-pagination-bullet-active {
  background-color: #018876;
}

.material .info {
  padding-top: 0px;
  padding-bottom: 50px;
}
@media (max-width: 992px) {
  .material .info {
    padding-top: 0px;
    padding-bottom: 30px;
  }
}
.material .info .img {
  width: 100%;
  height: 100%;
  padding-top: 40px;
}
@media (max-width: 992px) {
  .material .info .img {
    height: 350px;
  }
}
.material .info .img .img-box {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.material .info .right {
  padding-left: 20px;
}
@media (max-width: 992px) {
  .material .info .right {
    padding-left: 0px;
  }
}
.material .info .right .title {
  height: 60px;
  line-height: 60px;
  font-size: 26px;
  color: #000;
  border-bottom: 1px solid #018876;
}
@media (max-width: 1120px) {
  .material .info .right .title {
    height: 60px;
    line-height: 60px;
    font-size: 24px;
  }
}
.material .info .right .goods-title {
  font-size: 16px;
  color: #666;
  padding: 10px 0;
}
@media (max-width: 1120px) {
  .material .info .right .goods-title {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .material .info .right .goods-title {
    font-size: 14px;
  }
}
.material .info .right .el-form-item__label {
  font-size: 16px;
  color: #828282;
}
@media (max-width: 1120px) {
  .material .info .right .el-form-item__label {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .material .info .right .el-form-item__label {
    font-size: 16px;
  }
}
.material .info .right .el-input {
  height: 100%;
}
.material .info .right .el-input .el-input__inner {
  height: 100%;
  font-size: 16px;
}
@media (max-width: 1120px) {
  .material .info .right .el-input .el-input__inner {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .material .info .right .el-input .el-input__inner {
    font-size: 14px;
  }
}
.material .info .right .el-select {
  height: 100%;
}
.material .info .right .el-select .el-input__icon {
  color: #018876;
  font-size: 16px;
  line-height: 40px;
}
@media (max-width: 1120px) {
  .material .info .right .el-select .el-input__icon {
    font-size: 16px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .material .info .right .el-select .el-input__icon {
    font-size: 16px;
    line-height: 30px;
  }
}
.material .info .right .sl-form-item-box {
  height: 40px;
  width: 250px;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.material .info .right .sl-form-item-box .icon {
  width: 26px;
  margin-right: 10px;
}
@media (max-width: 1120px) {
  .material .info .right .sl-form-item-box {
    height: 40px;
    width: 200px;
  }
  .material .info .right .sl-form-item-box .icon {
    width: 24px;
  }
}
@media (max-width: 992px) {
  .material .info .right .sl-form-item-box {
    height: 40px;
    width: 200px;
  }
  .material .info .right .sl-form-item-box .icon {
    width: 20px;
  }
}
.material .info .right .btns {
  width: 180px;
  height: 50px;
  background-color: #018876;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 992px) {
  .material .info .right .btns {
    width: 150px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
.material .info .right .span {
  font-size: 18px;
  color: #018876;
  padding-top: 30px;
}
@media (max-width: 992px) {
  .material .info .right .span {
    font-size: 16px;
    padding-top: 30px;
  }
}
.material .info .right .text {
  font-size: 16px;
  color: #666;
  padding-top: 10px;
  opacity: 0.8;
}
@media (max-width: 1120px) {
  .material .info .right .text {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .material .info .right .text {
    font-size: 14px;
  }
}
.material .tabs .el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid #018876;
}
.material .tabs .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
.material .tabs .el-tabs--card > .el-tabs__header .el-tabs__item {
  margin-right: 20px;
  border-left: none;
  background-color: #eee;
  border-radius: 10px 10px 0 0;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  color: #999;
  font-size: 16px;
  position: relative;
  top: -1px;
}
@media (max-width: 992px) {
  .material .tabs .el-tabs--card > .el-tabs__header .el-tabs__item {
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    font-size: 14px;
  }
}
.material .tabs .el-tabs__item.is-active {
  background-color: #018876 !important;
  color: #fff !important;
}
.material .tabs .tabs2 {
  margin: 15px 0 15px 0;
  padding: 10px 0;
  background-color: #eee;
}
@media (max-width: 1120px) {
  .material .tabs .tabs2 {
    margin: 0px 0 10px 0;
  }
}
.material .tabs .tabs2 .li {
  font-size: 16px;
  color: #999;
  padding: 0 10px;
  border-right: 1px solid #ccc;
  cursor: pointer;
}
.material .tabs .tabs2 .li.active {
  color: #018876;
}
@media (max-width: 992px) {
  .material .tabs .tabs2 .li {
    font-size: 16px;
  }
}
.material .tabs .tabs2 .li:last-child {
  border-right: none;
}
.material .tabs .has-gutter {
  font-size: 16px;
  color: #333;
}
.material .tabs .el-table__row {
  height: 40px;
  font-size: 14px;
  color: #828282;
}
@media (max-width: 1120px) {
  .material .tabs .el-table__row {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .material .tabs .el-table__row {
    font-size: 14px;
  }
}
.material .tabs .area-item {
  margin-top: 20px;
}
@media (max-width: 1120px) {
  .material .tabs .area-item {
    margin-top: 10px;
  }
}
.material .tabs .area-item .img-box {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1120px) {
  .material .tabs .area-item .img-box {
    height: 120px;
  }
}
@media (max-width: 992px) {
  .material .tabs .area-item .img-box {
    height: 100px;
  }
}
.material .tabs .area-item .title {
  height: 40px;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  text-align: center;
}
@media (max-width: 1120px) {
  .material .tabs .area-item .title {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .material .tabs .area-item .title {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}