.advantage {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 10px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .about {
        padding: 100px 0 50px 0;
        background-color: #fff;

        // @include xl-desktop {
        //     padding: 100px 0 50px 0;
        // }

        @include large-desktop {
            padding: 50px 0 30px 0;
        }

        @include desktop {
            padding: 10px 0;
        }

        .title {
            font-size: 26px;
            color: #000;
            font-weight: bold;
            text-align: center;
            padding-bottom: 10px;

            // @include xl-desktop {
            //     font-size: 30px;
            //     padding-bottom: 15px;
            // }

            @include large-desktop {
                font-size: 22px;
                padding-bottom: 10px;
            }

            @include desktop {
                font-size: 18px;
                padding-bottom: 5px;
            }
        }

        .desc {
            font-size: 18px;
            color: #828282;
            line-height: 30px;
            text-align: center;

            // @include xl-desktop {
            //     font-size: 20px;
            //     line-height: 30px;
            // }

            @include large-desktop {
                font-size: 16px;
                line-height: 26px;
            }

            @include desktop {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .sl-btn {
            margin: 60px auto 0 auto;
            width: 500px;
            height: 80px;
            border-radius: 40px;
            border: 2px solid $color-primary;
            font-size: 24px;
            color: $color-primary;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            @include xl-desktop {
                margin: 50px auto 0 auto;
                height: 70px;
                border-radius: 35px;
                font-size: 20px;
            }

            @include large-desktop {
                margin: 40px auto 0 auto;
                height: 60px;
                border-radius: 30px;
                font-size: 18px;
            }

            @include desktop {
                margin: 30px auto 0 auto;
                height: 50px;
                border-radius: 25px;
                font-size: 16px;
            }

            .icon {
                width: 40px;
                margin-right: 10px;
            }



            &:hover {
                opacity: 0.7;
            }
        }
    }

    .core {
        padding-bottom: 70px;

        // @include xl-desktop {
        //     padding-bottom: 70px;
        // }

        @include large-desktop {
            padding-bottom: 50px;
        }

        @include desktop {
            padding-bottom: 30px;
        }

        .desc {
            padding: 30px 0;

            p {
                font-size: 18px;
                color: #828282;
                line-height: 30px;
                text-align: justify;
            }

            // @include xl-desktop {
            //     padding: 26px 0;

            //     p {
            //         font-size: 18px;
            //         line-height: 30px;
            //     }
            // }

            @include large-desktop {
                padding: 20px 0;

                p {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            @include desktop {
                padding: 10px 0;

                p {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .img {
            width: 100%;
            border-radius: 20px;
        }
    }

}

.el-collapse {
    border-top: none !important;

    .el-collapse-item {
        .el-collapse-item__header {
            border-bottom: 1px solid $color-primary;
            font-size: 18px;
            color: $color-primary;
            font-weight: bold;
            height: 70px;
            line-height: 70px;

            // @include xl-desktop {
            //     font-size: 20px;
            //     height: 80px;
            //     line-height: 80px;
            // }

            @include large-desktop {
                font-size: 16px;
                height: 60px;
                line-height: 60px;
            }

            @include desktop {
                font-size: 14px;
                height: 50px;
                line-height: 50px;
            }
        }
    }

    .el-collapse-item__wrap {
        border-bottom: none !important;
    }
}