.sl-comp-foot {
    background-color: #f3f3f3;
    padding: 30px 50px;

    // @include xl-desktop {
    //     padding: 50px 70px;
    // }

    @include large-desktop {
        padding: 20px 30px;
    }

    @include desktop {
        padding: 15px;
    }

    .model1 {
        padding-bottom: 30px;
        border-bottom: 2px solid $color-primary;

        // @include xl-desktop {
        //     padding-bottom: 40px;
        // }

        @include large-desktop {
            padding-bottom: 20px;
        }

        @include desktop {
            padding-bottom: 10px;
        }

        .logo {
            height: 54px;

            img {
                display: block;
                height: 100%;
            }

            @include large-desktop {
                height: 40px;
            }

            @include desktop {
                height: 30px;
            }
        }

        .title {
            font-size: 36px;
            color: #000;
            font-weight: bold;
            // font-weight: bold;
            // text-align: right;

            // @include xl-desktop {
            //     font-size: 54px;
            // }

            @include large-desktop {
                font-size: 24px;
                text-align: left;
                padding-top: 10px;
            }

            @include desktop {
                font-size: 20px;
                text-align: left;
                padding-top: 10px;
            }

            span {
                color: $color-primary;
            }
        }
    }

    .model2 {
        .left {
            height: 100%;

            .title {
                font-size: 16px;
                color: #000;
                // font-weight: bold;

                // @include xl-desktop {
                //     font-size: 14px;
                // }

                @include large-desktop {
                    font-size: 14px;
                    padding-top: 10px;
                }

                @include desktop {
                    font-size: 12px;
                    padding-top: 10px;
                }
            }

            .desc {
                font-size: 14px;
                color: #666;

                // @include xl-desktop {
                //     font-size: 14px;
                // }

                @include large-desktop {
                    font-size: 12px;
                }

                @include desktop {
                    font-size: 12px;
                }
            }

            .input-group {
                width: 450px;
                height: 40px;
                border-radius: 20px;
                border: 1px solid $color-primary;
                margin-top: 20px;
                background-color: #fff;
                overflow: hidden;

                // @include xl-desktop {
                //     width: 500px;
                //     height: 50px;
                //     border-radius: 25px;
                //     margin-top: 20px;
                // }

                @include large-desktop {
                    width: 100%;
                    height: 34px;
                    border-radius: 17px;
                    margin-top: 10px;
                }

                @include desktop {
                    width: 100%;
                    height: 30px;
                    border-radius: 15px;
                    margin-top: 10px;
                }

                .form-control {
                    flex: 1;
                    font-size: 16px;
                    color: #333;
                    padding: 20px;

                    // @include xl-desktop {
                    //     font-size: 16px;
                    //     padding: 15px;
                    // }

                    @include large-desktop {
                        font-size: 14px;
                        padding: 10px;
                    }

                    @include desktop {
                        font-size: 12px;
                        padding: 10px;
                    }
                }

                .input-btn {
                    width: 90px;
                    height: 100%;
                    background-color: $color-primary;
                    font-size: 16px;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    top:-1px;

                    // @include xl-desktop {
                    //     width: 100px;
                    //     line-height: 50px;
                    //     font-size: 16px;

                    // }

                    @include large-desktop {
                        width: 90px;
                        line-height: 40px;
                        font-size: 14px;
                    }

                    @include desktop {
                        width: 80px;
                        line-height: 40px;
                        font-size: 14px;
                    }
                }
            }
        }

        .right {
            padding-top: 20px;

            @include xl-desktop {
                padding-top: 15px;

            }

            .ul {
                padding-bottom: 30px;

                // @include xl-desktop {
                //     padding-bottom: 30px;

                // }

                @include large-desktop {
                    padding-bottom: 20px;
                }

                @include desktop {
                    padding-bottom: 10px;
                }

                .li {
                    font-size: 18px;
                    color: #000;
                    margin-right: 40px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    // @include xl-desktop {
                    //     font-size: 19px;
                    //     margin-right: 30px;

                    // }

                    @include large-desktop {
                        font-size: 14px;
                        margin-right: 20px;
                    }

                    @include desktop {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $color-primary;
                    }
                }
            }

            .social {
                padding-bottom: 10px;

                // @include xl-desktop {
                //     padding-bottom: 15px;

                // }

                .li {
                    font-size: 14px;
                    color: #000;
                    padding: 4px 0;

                    img {
                        width: 20px;
                        margin-right: 5px;
                    }

                    // @include xl-desktop {
                    //     font-size: 16px;

                    //     img {
                    //         width: 22px;
                    //     }

                    // }

                    // @include large-desktop {
                    //     font-size: 14px;

                    //     img {
                    //         width: 20px;
                    //     }
                    // }

                    @include desktop {
                        font-size: 14px;

                        img {
                            width: 18px;
                        }
                    }
                }
            }

            .with {
                font-size: 14px;
                color: #000;
                margin-bottom: 15px;

                // @include xl-desktop {
                //     font-size: 16px;
                //     margin-bottom: 10px;

                // }

                @include large-desktop {
                    font-size: 14px;
                }
            }

            .ul2 {
                .li {
                    width: 24px;
                    margin-right: 24px;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    // @include xl-desktop {
                    //     width: 24px;
                    //     margin-right: 24px;

                    // }

                    @include large-desktop {
                        width: 20px;
                        margin-right: 20px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

.sl-comp-foot-bottom {
    background-color: #000;

    .text {
        height: 70px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);

        // @include xl-desktop {
        //     height: 70px;
        //     font-size: 14px;

        // }

        @include large-desktop {
            height: 40px;
            font-size: 12px;
        }

    }
}