.business {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                    padding-top: 10px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .adderss {
        padding: 30px 0 70px 0;

        // @include xl-desktop {
        //     padding: 30px 0 70px 0;
        // }

        @include large-desktop {
            padding: 20px 0 40px 0;
        }

        @include desktop {
            padding: 10px 0;
        }

        .title {
            font-size: 26px;
            color: #000;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;

            // @include xl-desktop {
            //     font-size: 32px;
            //     margin-bottom: 15px;
            // }

            @include large-desktop {
                font-size: 22px;
                margin-bottom: 10px;
            }

            @include desktop {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }

        .icon {
            width: 20px;
            display: block;
            margin: 0 auto 50px auto;

            // @include xl-desktop {
            //     width: 26px;
            //     margin: 0 auto 40px auto;
            // }

            @include large-desktop {
                width: 20px;
                margin: 0 auto 30px auto;
            }

            @include desktop {
                width: 20px;
                margin: 0 auto 20px auto;
            }
        }

        .item {
            height: 400px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;
            padding: 15% 10%;

            // @include xl-desktop {
            //     height: 500px;
            //     padding: 16% 10%;
            // }

            @include large-desktop {
                height: 350px;
                padding: 10% 6%;
                margin-bottom: 15px;
            }

            @include desktop {
                height: 300px;
                padding: 10% 6%;
            }

            .image {
                height: 45px;
                display: block;
                margin: 0 auto;

                // @include xl-desktop {
                //     height: 65px;
                // }

                @include large-desktop {
                    height: 45px;
                }

                @include desktop {
                    height: 35px;
                }
            }

            .sl-btn {
                height: 60px;
                border-radius: 35px;
                background-color: rgba(1, 130, 118, 0.5);
                font-size: 26px;
                color: #fff;
                line-height: 60px;
                text-align: center;
                font-weight: bold;
                margin: 10% auto 10% auto;

                // @include xl-desktop {
                //     height: 80px;
                //     border-radius: 40px;
                //     font-size: 40px;
                //     line-height: 80px;
                //     margin: 7% auto 16% auto;
                // }

                @include large-desktop {
                    height: 50px;
                    border-radius: 30px;
                    font-size: 22px;
                    line-height: 50px;
                    margin: 8% auto 12% auto;
                }

                @include desktop {
                    height: 45px;
                    border-radius: 25px;
                    font-size: 18px;
                    line-height: 45px;
                    margin: 5% auto 5% auto;
                }
            }

            .addr {
                font-size: 20px;
                color: #fff;
                text-align: center;
                margin: 0 auto;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    // }

                @include large-desktop {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }
        }

        .item2 {
            height: 250px;
            border-radius: 10px;
            padding: 10% 10%;
            background-color: #f2f2f2;
            margin: 10px 0;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            // @include xl-desktop {
            //     height: 300px;
            //     padding: 10% 12%;
            //     margin: 10px 0;
            //     border-radius: 10px;
            // }

            @include large-desktop {
                height: 200px;
                padding: 8% 8%;
            }

            @include desktop {
                height: 180px;
                padding: 5% 5%;
            }

            .sl-btn {
                width: 150px;
                height: 50px;
                border-radius: 25px;
                background-color: rgba(1, 130, 118, 1);
                font-size: 18px;
                color: #fff;
                line-height: 50px;
                text-align: center;
                font-weight: bold;
                margin: 4% auto 5% auto;

                // @include xl-desktop {
                //     width: 160px;
                //     height: 50px;
                //     border-radius: 25px;
                //     font-size: 26px;
                //     line-height: 50px;
                //     margin: 8% auto;
                // }

                @include large-desktop {
                    width: 120px;
                    height: 40px;
                    border-radius: 20px;
                    font-size: 16px;
                    line-height: 40px;
                    margin: 5% auto;
                }

                @include desktop {
                    width: 100px;
                    height: 35px;
                    border-radius: 15px;
                    font-size: 14px;
                    line-height: 35px;
                    margin: 5% auto;
                }
            }

            .addr {
                font-size: 18px;
                color: #828282;
                text-align: center;
                margin: 0 auto;

                // @include xl-desktop {
                //     font-size: 20px;
                // }

                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }

            &:hover {
                background-color: #e6e6e6;
            }
        }
    }







}