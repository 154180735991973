// .sl-comp-popup {
// 	position: fixed;
// 	left: 0;
// 	top: 0;
// 	right: 0;
//     bottom: 0;
// 	z-index: 999;
// 	background-color: rgba(0, 0, 0, 0.5);
//     .sl-popup{
//         height: 100%;
//         transform: translate(-50%, -50%);
//         width: 100%;
//         max-width: 750px;
//         background-color: #fff;
//         padding: 15px;
//         border-radius: 10px;
//         position: fixed;
//         left: 50%;
//         top: 50%;
//         max-height: 500px;
//         .popup-top{
//             height: 30px;
//             .close-popup{
//                 width: 16px;
//                 height: 16px;
//             }
//         }
//         .popup-content{
//             flex: 1;
//         }
//     }
    
    

// }
.sl-popup{
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    width: 750px;
    height: 500px;

    @include desktop {
        width:calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
    }
    .popup-top{
        height: 30px;
        .close-popup{
            width: 16px;
            height: 16px;
            cursor: pointer;
            &:hover{
                opacity: 0.7;
            }
        }
    }
    .popup-content{
        font-size: 18px;
        color: #333;
        font-family: SourceHanSansCN-Medium;
    }
}


.uni-popup {
    position: fixed;
    top: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    overflow: hidden
}

.uni-popup__mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    background: rgba(0, 0, 0, .4);
    opacity: 0
}

.uni-popup__mask.ani {
    transition: all .3s
}

.uni-popup__mask.uni-bottom,
.uni-popup__mask.uni-center,
.uni-popup__mask.uni-top {
    opacity: 1
}

.uni-popup__wrapper {
    position: absolute;
    z-index: 999;
    box-sizing: border-box
}

.uni-popup__wrapper.ani {
    transition: all .3s
}

.uni-popup__wrapper.top {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%)
}

.uni-popup__wrapper.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%)
}

.uni-popup__wrapper.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.2);
    opacity: 0
}

.uni-popup__wrapper-box {
    position: relative;
    box-sizing: border-box
}

.uni-popup__wrapper.uni-custom .uni-popup__wrapper-box {
    /* padding: 30upx;
    background: #fff */
}

.uni-popup__wrapper.uni-custom.center .uni-popup__wrapper-box {
    position: relative;
    /* max-width: 80%;
    max-height: 80%;
    overflow-y: scroll */
}

.uni-popup__wrapper.uni-custom.bottom .uni-popup__wrapper-box,
.uni-popup__wrapper.uni-custom.top .uni-popup__wrapper-box {
    width: 100%;
    /* max-height: 500upx; */
    overflow-y: scroll
}

.uni-popup__wrapper.uni-bottom,
.uni-popup__wrapper.uni-top {
    transform: translateY(0)
}

.uni-popup__wrapper.uni-center {
    transform: scale(1);
    opacity: 1
}
