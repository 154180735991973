.goods {
    overflow: hidden;
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 56px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 24px;
                    }
                }

                .subtitle {
                    font-size: 24px;
                    color: #fff;
                    text-align: center;
                    padding-top: 20px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 20px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .nav1 {
        padding: 0 0px 70px 0;
        position: relative;
        top: -30px;
        z-index: 1;

        // @include xl-desktop {
        //     padding: 0 0px 70px 0;
        //     top: -50px;
        // }

        @include large-desktop {
            padding: 0 0px 40px 0;
            top: -20px;
        }

        @include desktop {
            padding: 0 0px 20px 0;
            top: -10px;
        }


        .ul {
            margin: 0 -4px;

            .li {
                height: 60px;
                flex: 1;
                margin: 0 4px;
                border-radius: 10px;
                background-color: #9e9e9e;
                font-size: 22px;
                font-weight: bold;
                color: #fff;
                text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                // @include xl-desktop {
                //     height: 80px;
                //     font-size: 36px;
                // }

                @include large-desktop {
                    height: 50px;
                    font-size: 18px;
                }

                @include desktop {
                    height: 40px;
                    font-size: 16px;
                }

                .img {
                    width: 24px;
                    margin-left: 15px;

                    // @include xl-desktop {
                    //     width: 26px;
                    //     margin-left: 15px;
                    // }

                    @include large-desktop {
                        width: 18px;
                        margin-left: 10px;
                    }

                    @include desktop {
                        width: 15px;
                        margin-left: 5px;
                    }
                }

                &.active {
                    background-color: $color-primary;
                    color: #fff;
                }
            }
        }
    }

    .sl-swiper4 {
        background-color: #f2f2f2;
        padding: 50px 0 70px 0;

        // @include xl-desktop {
        //     padding: 50px 0 70px 0;
        // }

        @include large-desktop {
            padding: 40px 0 50px 0;
        }

        @include desktop {
            padding: 20px 0;
        }

        .left {
            padding: 0 40px;

            // @include xl-desktop {
            //     padding: 0 40px;
            // }

            @include large-desktop {
                padding: 0 20px;
            }

            @include desktop {
                padding: 0;
            }

            .title {
                font-size: 24px;
                color: #000;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 36px;
                // }

                @include large-desktop {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }

            .text {
                padding-top: 15px;

                p {
                    font-size: 16px;
                    color: #666;
                    opacity: 0.8;
                    text-align: justify;
                }

                // @include xl-desktop {
                //     padding-top: 15px;

                //     p {
                //         font-size: 18px;
                //     }
                // }

                @include large-desktop {
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }

                @include desktop {
                    padding-top: 5px;

                    p {
                        font-size: 12px;
                    }
                }
            }
        }

        .right {
            position: relative;
            top: -120px;
            margin-top: 15px;

            // @include xl-desktop {
            //     top: -120px;
            //     margin-top: 15px;
            // }

            @include large-desktop {
                top: 0;
                padding-bottom: 50px;
            }


            .swiper-box {
                width: 100%;
                height: 350px;
                position: relative;

                // @include xl-desktop {
                //     height: 450px;
                // }

                @include large-desktop {
                    height: 300px;
                }

                @include desktop {
                    height: 250px;
                }

                .homeSwiper4 {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    .swiper-slide {
                        position: relative;
                        border-radius: 10px;
                        overflow: hidden;
                        cursor: pointer;

                        .img-box {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            transition: all 0.3s ease-in-out;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }

                    }
                }
            }

            .swiper-left {
                padding-top: 60px;

                @include xl-desktop {
                    padding-top: 40px;
                }

                @include large-desktop {
                    padding-top: 30px;
                }

                @include desktop {
                    padding-top: 15px;
                }

                .homeSwiper4-button-prev {
                    margin-right: 40px;
                    cursor: pointer;

                    @include xl-desktop {
                        width: 40px;
                        margin-right: 30px;
                    }

                    @include large-desktop {
                        width: 35px;
                        margin-right: 20px;
                    }

                    @include desktop {
                        width: 30px;
                        margin-right: 20px;
                    }
                }

                .homeSwiper4-button-next {
                    cursor: pointer;
                    cursor: pointer;

                    @include xl-desktop {
                        width: 40px;
                    }

                    @include large-desktop {
                        width: 35px;
                    }

                    @include desktop {
                        width: 30px;
                    }
                }
            }

            .swiper-right {
                padding-top: 60px;

                .icon {
                    height: 40px;
                    margin-left: 20px;
                }
            }


        }
    }

    .advantage {
        // padding: 100px 100px 0 100px;
        padding: 70px 0px 0 0px;

        // @include xl-desktop {
        //     padding: 70px 0px 0 0px;
        // }

        @include large-desktop {
            padding: 50px 0px 0 0px;
        }

        @include desktop {
            padding: 30px 0px 0 0px;
        }

        .advantage-title {
            font-size: 28px;
            color: #000;
            font-weight: bold;

            // @include xl-desktop {
            //     font-size: 36px;
            // }

            @include large-desktop {
                font-size: 20px;
            }

            @include desktop {
                font-size: 16px;
            }

            i {
                width: 1px;
                height: 40px;
                background-color: $color-primary;
                transform: rotate(30deg);
                margin: 0 15px;

                // @include xl-desktop {
                //     margin: 0 15px;
                //     height: 50px;
                // }

                @include large-desktop {
                    margin: 0 10px;
                    height: 30px;
                }

                @include desktop {
                    margin: 0 5px;
                    height: 20px;
                }
            }

            span {
                font-size: 20px;
                color: $color-primary;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 22px;
                // }

                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }

        .tab {
            border-bottom: 1px solid $color-primary;
            padding-top: 30px;

            // @include xl-desktop {
            //     padding-top: 40px;
            // }

            @include large-desktop {
                padding-top: 20px;
            }

            @include desktop {
                padding-top: 10px;
            }

            .name {
                width: 200px;
                height: 40px;
                border-radius: 10px 10px 0 0;
                background-color: $color-primary;
                font-size: 20px;
                color: #fff;
                font-weight: bold;
                line-height: 40px;
                text-align: center;

                // @include xl-desktop {
                //     width: 250px;
                //     height: 56px;
                //     font-size: 26px;
                //     line-height: 56px;
                // }

                @include large-desktop {
                    width: 150px;
                    height: 30px;
                    font-size: 16px;
                    line-height: 30px;
                }

                @include desktop {
                    width: 120px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .img-li {
            padding-top: 20px;

            .image {
                width: 100%;
            }
        }

        .img-desc {
            padding: 30px 0 20px 0;

            p {
                font-size: 18px;
                color: #666;
                opacity: 0.7;
            }

            @include xl-desktop {
                padding: 20px 0 10px 0;

                p {
                    font-size: 16px;
                }
            }

            @include large-desktop {
                padding: 10px 0 10px 0;

                p {
                    font-size: 14px;
                }
            }
        }

        .sl-row {
            padding-top: 15px;

            .sl-item {
                margin: 15px 0;
                background-color: #f2f2f2;
                border-radius: 10px;
                overflow: hidden;

                @include xl-desktop {
                    margin: 10px 0;
                }

                @include large-desktop {
                    margin: 5px 0;
                }

                .right {
                    flex: 1;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .left {
                    padding: 60px 75px;

                    @include xl-desktop {
                        padding: 40px 50px;
                    }

                    @include large-desktop {
                        padding: 20px 30px;
                    }

                    @include desktop {
                        padding: 15px;
                    }

                    .title {
                        font-size: 20px;
                        color: $color-primary;
                        font-weight: bold;

                        // @include xl-desktop {
                        //     font-size: 26px;
                        // }

                        @include large-desktop {
                            font-size: 18px;
                        }

                        @include desktop {
                            font-size: 16px;
                        }
                    }

                    .text {
                        height: 150px;
                        padding-top: 20px;

                        p {
                            font-size: 16px;
                            color: #666;
                            opacity: 0.8;
                            text-align: justify;
                        }

                        // @include xl-desktop {
                        //     // height: auto;
                        //     height: 150px;
                        //     padding-top: 20px;

                        //     p {
                        //         font-size: 18px;
                        //     }
                        // }

                        @include large-desktop {
                            padding-top: 15px;

                            p {
                                font-size: 14px;
                            }
                        }

                        @include desktop {
                            padding-top: 10px;

                            p {
                                font-size: 12px;
                            }
                        }
                    }

                    .btn-all {
                        font-size: 18px;
                        color: $color-primary;
                        cursor: pointer;

                        @include xl-desktop {
                            font-size: 16px;
                        }

                        @include large-desktop {
                            font-size: 14px;
                            margin-top: 5px;
                        }

                        @include desktop {
                            font-size: 14px;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }


    }

    .area {
        padding: 50px 0 0 0;

        // @include xl-desktop {
        //     padding: 70px 0 0 0;
        // }

        @include large-desktop {
            padding: 40px 0 0 0;
        }

        @include desktop {
            padding: 30px 0 0 0;
        }

        .advantage-title {
            font-size: 28px;
            color: #000;
            font-weight: bold;

            // @include xl-desktop {
            //     font-size: 36px;
            // }

            @include large-desktop {
                font-size: 20px;
            }

            @include desktop {
                font-size: 16px;
            }

            i {
                width: 1px;
                height: 40px;
                background-color: $color-primary;
                transform: rotate(30deg);
                margin: 0 15px;

                // @include xl-desktop {
                //     margin: 0 15px;
                //     height: 50px;
                // }

                @include large-desktop {
                    margin: 0 10px;
                    height: 30px;
                }

                @include desktop {
                    margin: 0 5px;
                    height: 20px;
                }
            }

            span {
                font-size: 20px;
                color: $color-primary;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 22px;
                // }

                @include large-desktop {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }

        .area-item {
            margin-top: 30px;

            @include xl-desktop {
                margin-top: 20px;
            }

            @include large-desktop {
                margin-top: 10px;
            }

            .img-box {
                width: 100%;
                height: 300px;
                border-radius: 10px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

                @include xl-desktop {
                    height: 250px;
                }

                @include large-desktop {
                    height: 180px;
                }

                @include desktop {
                    height: 100px;
                }
            }

            .title {
                height: 60px;
                font-size: 18px;
                color: #333;
                line-height: 60px;
                text-align: center;

                @include xl-desktop {
                    height: 50px;
                    font-size: 16px;
                    line-height: 50px;
                }

                @include large-desktop {
                    height: 40px;
                    font-size: 14px;
                    line-height: 40px;
                }

                @include desktop {
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                }
            }
        }
    }

    .sl-swiper5 {
        margin-top: 30px;
        background-color: $color-primary;

        .left {
            // padding: 0 80px;
            height: 100%;

            @include xl-desktop {
                // padding: 0 50px;
            }

            @include large-desktop {
                padding: 15px 0;
            }

            @include desktop {
                padding: 15px 0px;
            }

            .title {
                font-size: 24px;
                color: #fff;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 36px;
                // }

                @include large-desktop {
                    font-size: 20px;
                }

                @include desktop {
                    font-size: 18px;
                }
            }

            .text {
                padding-top: 10px;
                max-width: 460px;
                p {
                    font-size: 16px;
                    color: #fff;
                    opacity: 0.8;
                    text-align: justify;
                }

                // @include xl-desktop {
                //     padding-top: 10px;

                //     p {
                //         font-size: 20px;
                //     }
                // }

                @include large-desktop {
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }

                @include desktop {
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }
            }

            .btn-all {
                width: 90px;
                height: 30px;
                border-radius: 15px;
                border: 1px solid #fff;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 30px;
                margin-top: 50px;

                // @include xl-desktop {
                //     width: 100px;
                //     height: 36px;
                //     border-radius: 18px;
                //     font-size: 16px;
                //     line-height: 36px;
                //     margin-top: 50px;
                // }

                @include large-desktop {
                    width: 80px;
                    height: 32px;
                    border-radius: 16px;
                    font-size: 12px;
                    line-height: 32px;
                    margin-top: 20px;
                }

                @include desktop {}

                &:hover {
                    background-color: #fff;
                    color: $color-primary;
                }
            }
        }

        .right {
            position: relative;
            top: -50px;

            // @include xl-desktop {
            //     top: -50px;
            // }

            @include large-desktop {
                top: 0;
                margin: 15px 0;
            }

            .img-box {
                width: 100%;
                height: 25vw;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 10px;

                @include large-desktop {
                    height: 300px;
                }

                @include desktop {
                    height: 200px;
                }
            }

        }
    }

    .design {
        // padding: 100px 100px 0 100px;
        padding: 0;
        // @include xl-desktop {
        //     padding: 70px 0 0 0;
        // }

        // @include large-desktop {
        //     padding: 30px 0 0 0;
        // }

        // @include desktop {
        //     padding: 0px 0 0 0;
        // }

        .left {
            .el-form-item__label {
                font-size: 16px;
                color: #828282;

                // @include xl-desktop {
                //     font-size: 20px;
                // }

                @include large-desktop {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 12px;
                }
            }

            .el-input {
                height: 100%;

                .el-input__inner {
                    height: 100%;
                    font-size: 16px;

                    // @include xl-desktop {
                    //     font-size: 18px;
                    // }

                    @include large-desktop {
                        font-size: 14px;
                    }

                    @include desktop {
                        font-size: 12px;
                    }
                }
            }



            .el-select {
                height: 100%;

                .el-input__icon {
                    color: $color-primary;
                    font-size: 18px;
                    line-height: 40px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     line-height: 50px;
                    // }

                    @include large-desktop {
                        font-size: 24px;
                        line-height: 46px;
                    }

                    @include desktop {
                        font-size: 20px;
                        line-height: 40px;
                    }
                }
            }


            .sl-form-item-box {
                height: 40px;
                border-radius: 6px;
                border: 1px solid rgba(0,0,0,0.3);

                .icon {
                    width: 20px;
                    margin-right: 10px;
                }

                // @include xl-desktop {
                //     height: 50px;

                //     .icon {
                //         width: 28px;
                //     }
                // }

                @include large-desktop {
                    height: 30px;

                    .icon {
                        width: 20px;
                    }
                }

                @include desktop {
                    height: 30px;

                    .icon {
                        width: 15px;
                    }
                }
            }
			.sl-btn{
				margin-top: 40px;
				height: 40px;
				border-radius: 4px;
				font-size: 16px;
				line-height: 40px;
				text-align: center;
				background-color: #018876;
				color: #fff;
				cursor: pointer;
				&:hover{
					opacity: 0.6;
				}
			}

            .history {
                padding-top: 50px;

                // @include xl-desktop {
                //     padding-top: 80px;
                // }

                @include large-desktop {
                    padding-top: 15px;
                }

                @include desktop {
                    padding-top: 0px;
                }

                .title {
                    height: 40px;
                    font-size: 16px;
                    color: #333;
                    line-height: 40px;
                    border-bottom: 1px solid #999;
                    margin-bottom: 8px;

                    // @include xl-desktop {
                    //     height: 50px;
                    //     font-size: 22px;
                    //     line-height: 50px;
                    // }

                    @include large-desktop {
                        height: 30px;
                        font-size: 16px;
                        line-height: 30px;
                    }

                    @include desktop {
                        height: 40px;
                        font-size: 14px;
                        line-height: 40px;
                    }
                }

                .img {
                    width: 100%;
                    height: 80px;
                    border-radius: 8px;
                    margin: 8px 0;
                    cursor: pointer;

                    // @include xl-desktop {
                    //     height: 90px;
                    // }

                    @include large-desktop {
                        height: 60px;
                    }

                    @include desktop {
                        height: 40px;
                    }
                }
            }
        }

        .right {
            .sl-row {
                .sl-item {
                    margin: 15px 0;
                    cursor: pointer;

                    .info {
                        border-radius: 8px 8px 0 0;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        .img-box{
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }

                        .name {
                            font-size: 16px;
                            color: #828282;
                            text-align: right;
                            height: 40px;
                            line-height: 40px;
                            padding: 0 10px;

                            // @include xl-desktop {
                            //     font-size: 16px;
                            //     height: 35px;
                            //     line-height: 35px;
                            //     padding: 0 15px;
                            // }

                            @include large-desktop {
                                font-size: 14px;
                                height: 30px;
                                line-height: 30px;
                                padding: 0 10px;
                            }

                            @include desktop {
                                font-size: 12px;
                                height: 30px;
                                line-height: 30px;
                                padding: 0 10px;
                            }
                        }

                        .img-box {
                            margin: 0 20px 20px 20px;
                            height: 100px;

                            // @include xl-desktop {
                            //     margin: 0 30px 30px 30px;
                            //     height: 150px;
                            // }

                            @include large-desktop {
                                margin: 0 20px 20px 20px;
                                height: 80px;
                            }

                            @include desktop {
                                margin: 0 10px 10px 10px;
                                height: 60px;
                            }
                        }


                    }

                    .title {
                        height: 48px;
                        color: #666;
                        background-color: #f3f3f3;
                        border-radius: 0 0 8px 8px;
                        font-size: 16px;
                        line-height: 48px;
                        padding: 0 10px;
                        text-align: center;

                        // @include xl-desktop {
                        //     height: 50px;
                        //     font-size: 20px;
                        //     line-height: 50px;
                        //     padding: 0 15px;
                        // }

                        @include large-desktop {
                            height: 30px;
                            font-size: 14px;
                            line-height: 30px;
                            padding: 0 10px;
                        }

                        @include desktop {
                            height: 30px;
                            font-size: 14px;
                            line-height: 30px;
                            padding: 0 10px;
                        }
                    }
                }
            }

            .sl-pagination {
                padding: 20px 0;

                .el-pagination {
                    transform: scale(1.2);
                }

                // @include xl-desktop {
                //     padding: 30px 0;

                //     .el-pagination {
                //         transform: scale(1.2);
                //     }
                // }

                @include large-desktop {
                    padding: 20px 0;

                    .el-pagination {
                        transform: scale(1.1);
                    }
                }

                @include desktop {
                    padding: 10px 0;

                    .el-pagination {
                        transform: scale(1.0);
                    }
                }
            }
        }
    }

    .handle {
        // padding: 60px 100px;

        // @include xl-desktop {
        //     padding: 50px 0;
        // }

        // @include large-desktop {
        //     padding: 30px 0;
        // }

        // @include desktop {
        //     padding: 0;
        // }

        .handle-item {
            width: 100%;
            cursor: pointer;
            position: relative;

            .img-box {
                height: 220px;
                border-radius: 8px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
                position: relative;

                // @include xl-desktop {
                //     height: 240px;
                // }

                @include large-desktop {
                    height: 180px;
                }

                @include desktop {
                    height: 120px;
                }
                .zoom-frame {
                    position: absolute;
                    // border: 2px solid #fff;
                    // box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
                    cursor: move;
                    // pointer-events: none;

                    @include desktop {
                        display: none;
                    }
                }
            }

            .title {
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                color: #666;
                text-align: center;
                padding: 0 10px;

                // @include xl-desktop {
                //     height: 60px;
                //     line-height: 60px;
                //     font-size: 20px;
                //     padding: 0 15px;
                // }

                @include large-desktop {
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    padding: 0 10px;
                }

                @include desktop {
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    padding: 0 10px;
                }
            }

            .magnifier {
                position: absolute;
                border: 2px solid #000;
                overflow: hidden;
                pointer-events: none;
                cursor: none;
                z-index: 1;
                width: 100%;
                height: 100%;
            }

        }
    }



}

.el-select-dropdown {
    .el-select-dropdown__item {
        font-size: 14px;
        height: 40px;
        line-height: 40px;
    }
}

.zoom-preview {
    width: 875px;
    height: 580px;
    border-radius: 16px;
    padding: 40px;
    position: fixed;
    background: #fff;
    border: 1px solid #ddd;
    overflow: hidden;
    z-index: 1000;

    @include desktop {
        display: none;
    }
    
    img {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
    }
}