html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary,
input,
select,
button,
input::-webkit-input-placeholder {
	margin: 0;
	padding: 0;
	border: 0;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-overflow-scrolling: touch;
	// font-family: "MicrosoftYaHei";
	
	outline: none;
}

article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
	display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
	max-width: 100%;
}

img {
	display: inline-block;
}

/* force a vertical scrollbar to prevent a jumpy page */
/*html {overflow-y: scroll;}*/
/*gavin edit*/
html {
	overflow-y: auto;
}

/* we use a lot of ULs then aren't bulleted.
  don't forget to restore the bullets within content. */
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	background: transparent;
	color: inherit;
	text-decoration: none !important;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted #000;
	cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

th {
	font-weight: bold;
	vertical-align: bottom;
}

td {
	font-weight: normal;
	vertical-align: top;
}

hr {
	display: block;
	height: 0px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
}

pre {
	white-space: pre;
	/* CSS2 */
	white-space: pre-wrap;
	/* CSS 2.1 */
	white-space: pre-line;
	/* CSS 3 (and 2.1 as well, actually) */
	word-wrap: break-word;
	/* IE */
}

input[type="radio"] {
	vertical-align: text-bottom;
}

input[type="checkbox"] {
	vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
	vertical-align: baseline;
}

.ie6 input {
	vertical-align: text-bottom;
}

select,
input,
textarea {
	font: 99% sans-serif;
}

table {
	font-size: inherit;
	font: 100%;
}

small {
	font-size: 85%;
}

strong {
	font-weight: bold;
}

td,
td img {
	vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
	font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
	cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
	margin: 0;
}

input {
	outline: none !important;
	/* 去掉默认的聚焦边框 */
	border: none !important;
	/* 去掉边框 */
	background: none !important;
	/* 去掉默认背景 */
	box-shadow: none !important;
	/* 去掉阴影 */
}

/* make buttons play nice in IE */
button,
input[type=button] {
	width: auto;
	overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
	-ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* 禁用select默认下拉箭头 */
select::-ms-expand {
	display: none;
}

/* 禁用PC端表单输入框默认清除按钮 */
/*input[type=text]::-ms-clear,input[type=tel]::-ms-clear,input[type=number]::-ms-clear{display:none;}*/
/* 禁用radio和checkbox默认样式 */
/*input[type=radio]::-ms-check,input[type=checkbox]::-ms-check{display: none;}*/

/* let's clear some floats */
.clearfix:before,
.clearfix:after {
	content: "\0020";
	display: block;
	height: 0;
	overflow: hidden;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

/* start--文本行对齐--start */
.s-text-left {
	text-align: left;
}

.s-text-center {
	text-align: center;
}

.s-text-right {
	text-align: right;
}

/* end--文本行对齐--end */

/* start--文本行数限制--start */
.s-line-1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@for $i from 2 to 10 {
	.s-line-#{$i} {
		-webkit-line-clamp: $i;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
}

/* end--文本行数限制--end */

/* start--通用颜色配置--start */
$colors: (
	"f": #ffffff,
	"0": #000000,
	"1": #111111,
	"2": #222222,
	"3": #333333,
	"4": #444444,
	"5": #555555,
	"6": #666666,
	"7": #777777,
	"8": #888888,
	"82": #828282,
	"9": #999999,
	"BD": #BDBDBD,
	"C": #CCCCCC,
	"E": #EEEEEE,
	"E5": #E5E5E5,
	"primary":$color-primary,
	"assistant":$color-assistant,
	"success":$color-success,
	"warning":$color-warning,
	"error":$color-error,
);

@each $colorKey, $color in $colors {
	.s-color-#{$colorKey} {
		color: $color !important;
	}

	.s-bg-#{$colorKey} {
		background-color: $color !important;
	}
}

/* end--通用颜色配置--end */

[class*="flex-"] {
	display: -webkit-box;
	/* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box;
	/* Firefox 17- */
	display: -webkit-flex;
	/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex;
	/* Firefox 18+ */
	display: -ms-flexbox;
	/* IE 10 */
	display: flex;
	/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
}

.flex-y-top {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.flex-y-bottom {
	flex-direction: column-reverse;
}

.flex-x-right {
	flex-direction: row-reverse;
}

/* // 交叉轴上对齐 居中flex-level */
.flex-align-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

/* // 交叉轴上对齐 元素第一行文字的基线对齐flex-bottom */
.flex-align-baseline {
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	-webkit-align-items: baseline;
	align-items: baseline;
}

/* // 主轴对齐 左右 左中右 基于子元素的数量flex-lr */
.flex-justify-between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

/* //换行flex-br */
.flex-wrap {
	-webkit-box-wrap: wrap;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

/* // 主轴对齐 居右flex-right */
.flex-justify-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

/* // 主轴对齐 居左flex-left */

.flex-justify-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

/* // 主轴对齐 居中 */
.flex-justify-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}

/* // 主轴对齐 居下 */
.flex-align-end {
	align-items: flex-end;
}

.overflow-y {
	overflow-y: scroll;
	overflow-x: hidden;
}

.overflow-y::-webkit-scrollbar {
	width: 0 !important;
}

.overflow-x {
	overflow-x: scroll;
	overflow-y: hidden;
}

.overflow-x::-webkit-scrollbar {
	width: 0 !important;
}

.images {
	width: 100%;
	height: 100%;
	display: inline-block;
}

// vue特有指令
[v-cloak] {
	display: none;
}
// .container{
// 	max-width: 1200px;
// 	padding-left: 15px;
// 	padding-right: 15px;
// 	margin-left: auto;
// 	margin-right: auto;
// }

// @font-face{
// 	font-family: SourceHanSansCN-Medium;
// 	src: url('/images/font/SourceHanSansCN-Medium.otf'),
// }
// @font-face{
// 	font-family: SourceHanSansCN-Bold;
// 	src: url('/images/font/SourceHanSansCN-Bold.otf'),
// }
// @font-face{
// 	font-family: SourceHanSansCN-Regular;
// 	src: url('/images/font/SourceHanSansCN-Regular.otf'),
// }
.sl-Regular{
	font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
	// font-family: SourceHanSansCN-Regular;
}
.sl-Medium{
	font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
	// font-family: SourceHanSansCN-Medium;
}
.sl-Bold{
	font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
	// font-family: SourceHanSansCN-Bold;
}
#translate{
	display: none;
}

.pagination-box{
	position: relative;
	.text1{
		position: absolute;
		left: -17%;
		font-size: 16px;
	}
	.text2{
		position: absolute;
		right: -17%;
		font-size: 16px;
	}

	@include large-desktop {
		.text1{
			position: absolute;
			left: -8%;
		}
		.text2{
			position: absolute;
			right: -8%;
		}
	}

	@include desktop {
		.text1{
			position: absolute;
			left: -3%;
		}
		.text2{
			position: absolute;
			right: -3%;
		}
	}
}
.el-pagination{
	
	.btn-prev{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #019176;
		position: relative;
		top: -7px;
		right: 10px;
		.el-icon{
			font-size: 18px !important;
			position: relative;
			left: 2px;
			color: #019176;
		}
	}
	.btn-next{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #019176;
		position: relative;
		top: -7px;
		left: 10px;
		.el-icon{
			font-size: 18px !important;
			position: relative;
			left: -2px;
			color: #019176;
		}
	}
	.el-pager{
		.number{
			width: 26px;
			height: 26px;
			border-radius: 50%;
			border: 1px solid #e4e5e5;
			color: #727171;
			min-width: auto !important;
			line-height: 24px;
			margin: 0 2px;
		}
		.active{
			background-color: #019176;
			color: #fff;
			border: 1px solid #019176;
		}
		.el-icon{
			width: 26px;
			height: 26px;
			border-radius: 50%;
			border: 1px solid #e4e5e5;
			color: #727171;
			min-width: auto !important;
			line-height: 24px;
			margin: 0 2px;
			&::before{
				line-height: 24px;
			}
		}
		
	}
}