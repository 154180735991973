.news-detail {
    .detail {
        padding: 100px;

        @include xl-desktop {
            padding: 70px 0;
        }

        @include large-desktop {
            padding: 40px 0;
        }

        @include desktop {
            padding: 20px 0;
        }

        .left {
            .years {
                max-width: 210px;
                font-size: 36px;
                color: $color-primary;
                border-bottom: 1px solid $color-primary;

                @include xl-desktop {
                    font-size: 30px;
                }

                @include large-desktop {
                    font-size: 26px;
                }

                @include desktop {
                    font-size: 24px;
                }
            }

            .month {
                font-size: 20px;
                color: $color-primary;
                margin-top: 10px;

                @include xl-desktop {
                    font-size: 18px;
                }

                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }

            .desc {
                padding-top: 60px;

                p {
                    font-size: 20px;
                    color: #828282;
                    padding: 5px 0;
                }

                @include xl-desktop {
                    padding-top: 40px;

                    p {
                        font-size: 18px;
                    }
                }

                @include large-desktop {
                    padding-top: 30px;

                    p {
                        font-size: 16px;
                    }
                }

                @include desktop {
                    padding-top: 15px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .right {
            .title {
                padding-bottom: 20px;
                border-bottom: 1px solid $color-primary;

                p {
                    font-size: 36px;
                    color: $color-primary;

                    @include xl-desktop {
                        font-size: 30px;
                    }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 24px;
                    }
                }
            }

            .content {
                padding: 30px 0;

                @include xl-desktop {
                    padding: 25px 0;
                }

                @include large-desktop {
                    padding: 20px 0;
                }

                @include desktop {
                    padding: 15px 0;
                }
            }
        }
    }
}