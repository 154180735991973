.about {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 10px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .tit {
        padding: 60px 0 0 0;

        // @include xl-desktop {
        //     padding: 60px 0 0 0;
        // }

        @include large-desktop {
            padding: 40px 0 0 0;
        }

        @include desktop {
            padding: 20px 0 0 0;
        }

        .advantage-title {
            font-size: 26px;
            color: #000;
            font-weight: bold;

            // @include xl-desktop {
            //     font-size: 36px;
            // }

            @include large-desktop {
                font-size: 26px;
            }

            @include desktop {
                font-size: 16px;
            }

            i {
                width: 1px;
                height: 40px;
                background-color: $color-primary;
                transform: rotate(30deg);
                margin: 0 10px;

                // @include xl-desktop {
                //     margin: 0 15px;
                //     height: 50px;
                // }

                @include large-desktop {
                    margin: 0 10px;
                    height: 30px;
                }

                @include desktop {
                    margin: 0 5px;
                    height: 20px;
                }
            }

            span {
                font-size: 18px;
                color: $color-primary;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 22px;
                // }

                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }
    }

    .info {
        padding: 10px 0px 30px 0px;

        // @include xl-desktop {
        //     padding: 10px 0px 30px 0px;
        // }

        @include large-desktop {
            padding: 10px 0px 20px 0px;
        }

        @include desktop {
            padding: 10px 0px;
        }

        .left {
            height: 100%;

            .text {
                font-size: 18px;
                color: #828282;
                line-height: 30px;
                max-width: 940px;

                // @include xl-desktop {
                //     font-size: 20px;
                //     line-height: 30px;
                // }

                @include large-desktop {
                    font-size: 16px;
                    line-height: 26px;
                }

                @include desktop {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .right {
            height: 100%;

            .logo {
                height: 120px;

                // @include xl-desktop {
                //     height: 130px;
                // }

                @include large-desktop {
                    height: 100px;
                    margin-top: 15px;
                }

                @include desktop {
                    height: 70px;
                }
            }
        }
    }

    .sl-row {
        padding: 40px 0px;

        // @include xl-desktop {
        //     padding: 40px 0px;
        // }

        @include large-desktop {
            padding: 30px 0px;
        }

        @include desktop {
            padding: 15px 0px;
        }

        .sl-item {
            margin: 10px 0;
            background-color: #f2f2f2;
            border-radius: 4px;
            overflow: hidden;

            // @include xl-desktop {
            //     margin: 10px 0;
            // }

            @include large-desktop {
                margin: 5px 0;
            }

            .img-box {
                width: 100%;
                height: 200px;
                border-radius: 4px;
                background-position: center;
                background-repeat: no-repeat;
                // background-size: contain;
                background-size: cover;
                background-color: #ccc;

                // @include xl-desktop {
                //     height: 220px;
                // }

                @include large-desktop {
                    height: 150px;
                }

                @include desktop {
                    height: 100px;
                }
            }

            .span {
                height: 150px;
                padding-top: 30px;

                // @include xl-desktop {
                //     height: 160px;
                //     padding-top: 20px;
                // }

                @include large-desktop {
                    height: 100px;
                    padding-top: 10px;
                }

                @include desktop {
                    height: 70px;
                }

                .yar {
                    border-left: 8px solid $color-primary;
                    padding-left: 15px;
                    padding-right: 10px;

                    // @include xl-desktop {
                    //     padding-left: 20px;
                    //     border-left: 8px solid $color-primary;
                    // }

                    @include large-desktop {
                        padding-left: 10px;
                        border-left: 6px solid $color-primary;
                    }

                    @include desktop {
                        padding-left: 5px;
                        border-left: 4px solid $color-primary;
                    }

                    .title {
                        font-size: 20px;
                        color: $color-primary;
                        font-weight: bold;

                        // @include xl-desktop {
                        //     font-size: 24px;
                        // }

                        @include large-desktop {
                            font-size: 18px;
                        }

                        @include desktop {
                            font-size: 16px;
                        }
                    }

                    .desc {
                        font-size:14px;
                        color: #828282;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .sl-item2 {
            background-color: #f2f2f2;
            border-radius: 10px;
            overflow: hidden;
            margin: 10px 0;
            height: 350px;
            padding-top: 20px;

            // @include xl-desktop {
            //     height: 360px;
            //     padding-top: 20px;
            // }

            @include large-desktop {
                height: 250px;
                padding-top: 10px;
            }

            @include desktop {
                height: 170px;
            }

            .title {
                font-size: 20px;
                color: $color-primary;
                font-weight: bold;
                text-align: center;

                // @include xl-desktop {
                //     font-size: 24px;
                // }

                @include large-desktop {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }
        }
    }




}