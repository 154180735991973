.news {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 15px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .tit {
        padding: 60px 0 0 0;

        // @include xl-desktop {
        //     padding: 60px 0 0 0;
        // }

        @include large-desktop {
            padding: 40px 0 0 0;
        }

        @include desktop {
            padding: 20px 0 0 0;
        }

        .tit-title {
            font-size: 24px;
            color: #000;
            font-weight: bold;

            // @include xl-desktop {
            //     font-size: 36px;
            // }

            @include large-desktop {
                font-size: 18px;
            }

            @include desktop {
                font-size: 16px;
            }

            i {
                width: 1px;
                height: 40px;
                background-color: $color-primary;
                transform: rotate(30deg);
                margin: 0 10px;

                // @include xl-desktop {
                //     margin: 0 15px;
                //     height: 50px;
                // }

                @include large-desktop {
                    margin: 0 10px;
                    height: 30px;
                }

                @include desktop {
                    margin: 0 5px;
                    height: 20px;
                }
            }

            span {
                font-size: 20px;
                color: $color-primary;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 22px;
                // }

                @include large-desktop {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }

        }

        .tit-all {
            font-size: 14px;
            color: $color-primary;
            height: 100%;
            cursor: pointer;

            // @include xl-desktop {
            //     font-size: 16px;
            // }

            @include large-desktop {
                font-size: 12px;
            }

            @include desktop {
                font-size: 12px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    // .handle {
    //     padding: 60px 100px;

    //     @include xl-desktop {
    //         padding: 40px 70px;
    //     }

    //     @include large-desktop {
    //         padding: 20px 40px;
    //     }

    //     @include desktop {
    //         padding: 15px;
    //     }

    //     .handle-item {
    //         width: 100%;
    //         cursor: pointer;

    //         .img-box {
    //             height: 14vw;
    //             border-radius: 8px;
    //         }

    //         .title {
    //             height: 80px;
    //             line-height: 80px;
    //             font-size: 24px;
    //             color: #666;
    //             text-align: center;
    //             padding: 0 20px;
    //         }

    //         .magnifier {
    //             position: absolute;
    //             border: 2px solid #000;
    //             overflow: hidden;
    //             pointer-events: none;
    //             cursor: none;
    //             z-index: 1;
    //             width: 100%;
    //             height: 100%;
    //         }

    //     }
    // }

    .dynamic {
        padding: 40px 70px;

        // @include xl-desktop {
        //     padding: 40px 70px;
        // }

        @include large-desktop {
            padding: 20px;
        }

        @include desktop {
            padding: 0px;
        }

        .left {
            .img-box {
                height: 100%;
                width: 100%;
                padding: 8px 0;

                // @include xl-desktop {
                //     // height: 400px;
                // }

                @include large-desktop {
                    height: 250px;
                }

                @include desktop {
                    height: 220px;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }

            .text-box {
                margin: 8px 0;
                background-color: #f2f2f2;
                border-radius: 10px;
                padding: 0 30px;
                cursor: pointer;

                // @include xl-desktop {
                //     padding: 0 30px;
                // }

                @include large-desktop {
                    padding: 0 20px;
                }

                @include desktop {
                    padding: 0 15px;
                }

                .u {
                    padding: 20px 0 10px 0;
                    border-bottom: 1px solid $color-primary;

                    .time {
                        width: 80px;

                        // @include xl-desktop {
                        //     width: 90px;
                        // }

                        .day {
                            font-size: 24px;
                            color: #000;

                            // @include xl-desktop {
                            //     font-size: 30px;
                            // }

                            @include large-desktop {
                                font-size: 20px;
                            }

                            @include desktop {
                                font-size: 18px;
                            }
                        }

                        .date {
                            font-size: 18px;
                            color: #999;

                            // @include xl-desktop {
                            //     font-size: 18px;
                            // }

                            @include large-desktop {
                                font-size: 16px;
                            }

                            @include desktop {
                                font-size: 14px;
                            }
                        }
                    }

                    .txt {
                        width: calc(100% - 90px);
                        font-size: 18px;
                        color: #000;
                        font-weight: 600;

                        // @include xl-desktop {
                        //     width: calc(100% - 90px);
                        //     font-size: 20px;
                        // }

                        @include large-desktop {
                            font-size: 16px;
                        }

                        @include desktop {
                            font-size: 14px;
                        }
                    }
                }

                .n {
                    padding: 10px 0 20px 0;

                    .txt {
                        font-size: 14px;
                        color: #999;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                    }

                    .btn-all {
                        font-size: 14px;
                        color: $color-primary;
                        cursor: pointer;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                    }
                }

                &:hover {
                    background-color: $color-primary;
					.u{
						border-bottom: 1px solid #fff;
					}
                    .txt,
                    .btn-all {
                        color: #fff;
                    }

                    .time {
                        .day {
                            color: #fff;
                        }

                        .date {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .right {
            margin-top: 8px;
            height: 496px;

            .sl-row {
                .sl-item {
                    background-color: #f2f2f2;
                    border-radius: 10px;
                    margin-bottom: 15px;
                    padding: 20px 30px;
                    cursor: pointer;

                    // @include xl-desktop {
                    //     padding: 20px 30px;
                    // }

                    @include large-desktop {
                        padding: 15px 20px;
                    }

                    @include desktop {
                        padding: 15px;
                    }

                    .time {
                        width: 110px;

                        @include xl-desktop {
                            width: 90px;
                        }

                        .day {
                            font-size: 24px;
                            color: #000;

                            // @include xl-desktop {
                            //     font-size: 30px;
                            // }

                            @include large-desktop {
                                font-size: 20px;
                            }

                            @include desktop {
                                font-size:  18px;
                            }

                        }

                        .date {
                            font-size: 18px;
                            color: #999;

                            // @include xl-desktop {
                            //     font-size: 18px;
                            // }

                            @include large-desktop {
                                font-size: 16px;
                            }

                            @include desktop {
                                font-size: 14px;
                            }
                        }
                    }

                    .cont {
                        width: calc(100% - 90px);

                        .title {
                            font-size: 18px;
                            color: #000;
                            font-weight: 600;

                            // @include xl-desktop {
                            //     width: calc(100% - 90px);
                            //     font-size: 20px;
                            // }

                            @include large-desktop {
                                font-size: 16px;
                            }

                            @include desktop {
                                font-size: 14px;
                            }

                        }

                        .txt {
                            height: 80px;
                            padding-top: 10px;

                            p {
                                font-size: 14px;
                                color: #999;

                                // @include xl-desktop {
                                //     font-size: 16px;
                                // }

                                @include large-desktop {
                                    font-size: 14px;
                                }
                            }
                        }

                        .btn-all {
                            font-size: 14px;
                            color: $color-primary;
                            cursor: pointer;

                            // @include xl-desktop {
                            //     font-size: 16px;
                            // }

                            @include large-desktop {
                                font-size: 14px;
                            }
                        }
                    }

                    &:hover {
                        background-color: $color-primary;

                        .title,
                        .btn-all {
                            color: #fff;
                        }

                        .txt p {
                            color: #fff;
                        }

                        .time {
                            .day {
                                color: #fff;
                            }

                            .date {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .area {
        padding:0;

        // @include xl-desktop {
        //     padding: 60px 0 0 0;
        // }

        @include large-desktop {
            // padding: 40px 0 0 0;
        }

        @include desktop {
            // padding: 20px 0 0 0;
        }


        .area-item {
            margin-top: 20px;
            cursor: pointer;

            // @include xl-desktop {
            //     margin-top: 20px;
            // }

            @include large-desktop {
                margin-top: 10px;
            }

            @include desktop {
                margin-top: 0px;
            }

            .img-box {
                width: 100%;
                height: 180px;
                border-radius: 10px;

                // @include xl-desktop {
                //     height: 220px;
                // }

                @include large-desktop {
                    height: 160px;
                }

                @include desktop {
                    height: 120px;
                }
            }

            .title {
                height: 48px;
                font-size: 14px;
                color: #333;
                line-height: 48px;
                text-align: center;

                // @include xl-desktop {
                //     height: 48px;
                //     font-size: 16px;
                //     line-height: 48px;
                // }

                @include large-desktop {
                    height: 36px;
                    font-size: 14px;
                    line-height: 36px;
                }

                @include desktop {
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                }
            }

            &:hover {
                .title {
                    color: $color-primary;
                }
            }
        }
    }

    .exhibition {
        padding: 30px 0;

        // @include xl-desktop {
        //     padding: 30px 0;
        // }

        @include large-desktop {
            padding: 20px 0;
        }

        @include desktop {
            padding: 10px 0;
        }

        .left {
            height: 100%;

            .img-box {
                height: 100%;
                width: 100%;
                border-radius: 10px;

                // @include xl-desktop {
                //     height: 100%;
                // }

                @include large-desktop {
                    height: 300px;
                }

                @include desktop {
                    height: 200px;
                }
            }
        }

        .right {
            height: 500px;
            border-radius: 10px;
            background-color: #f2f2f2;

            @include large-desktop {
                margin-top: 10px;
                height: auto;
            }

            @include desktop {
                margin-top: 10px;
                height: auto;
            }

            .sl-row {
                .sl-item {
                    border-bottom: 1px solid #d3d3d3;
                    height: 60px;
                    padding: 0 20px;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    // @include xl-desktop {
                    //     height: 60px;
                    //     padding: 0 20px;
                    // }

                    @include large-desktop {
                        height: 50px;
                        padding: 0 15px;
                    }

                    @include desktop {
                        height: 40px;
                        padding: 0 10px;
                    }

                    .label {
                        font-size: 16px;
                        color: #828282;
                        padding-left: 15px;
                        position: relative;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        //     padding-left: 15px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                            padding-left: 10px;
                        }

                        @include desktop {
                            font-size: 12px;
                            padding-left: 10px;
                        }

                        span {
                            width: 2px;
                            height: 16px;
                            background-color: $color-primary;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .time {
                        font-size: 12px;
                        color: #828282;

                        // @include xl-desktop {
                        //     font-size: 12px;
                        // }

                    }

                    &:hover {
                        background-color: $color-primary;

                        .label {
                            color: #fff;

                            span {
                                background-color: #fff;
                            }
                        }

                        .time {
                            color: #fff;
                        }
                    }

                }
            }
        }
    }




}