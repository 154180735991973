.join {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 10px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .about {
        padding: 100px 0;
        background-color: #f2f2f2;

        // @include xl-desktop {
        //     padding: 100px 0;
        // }

        @include large-desktop {
            padding: 70px 0;
        }

        @include desktop {
            padding: 50px 0;
        }

        .title {
            font-size: 28px;
            color: #000;
            font-weight: bold;
            text-align: center;
            padding-bottom: 10px;

            // @include xl-desktop {
            //     font-size: 30px;
            //     padding-bottom: 15px;
            // }

            @include large-desktop {
                font-size: 24px;
                padding-bottom: 10px;
            }

            @include desktop {
                font-size: 24px;
                padding-bottom: 10px;
            }
        }

        .desc {
            font-size: 18px;
            color: #828282;
            line-height: 30px;
            text-align: justify;

            // @include xl-desktop {
            //     font-size: 20px;
            //     line-height: 30px;
            // }

            @include large-desktop {
                font-size: 16px;
                line-height: 26px;
            }

            @include desktop {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .sl-btn {
            margin: 40px auto 0 auto;
            width: 400px;
            height: 60px;
            border-radius: 40px;
            border: 2px solid $color-primary;
            font-size: 18px;
            color: $color-primary;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            .icon {
                width: 30px;
                margin-right: 10px;
            }

            // @include xl-desktop {
            //     margin: 50px auto 0 auto;
            //     width: 400px;
            //     height: 70px;
            //     border-radius: 35px;
            //     font-size: 20px;

            //     .icon {
            //         width: 34px;
            //     }
            // }

            @include large-desktop {
                margin: 40px auto 0 auto;
                width: 300px;
                height: 60px;
                border-radius: 30px;
                font-size: 18px;

                .icon {
                    width: 30px;
                }
            }

            @include desktop {
                margin: 30px auto 0 auto;
                width: 200px;
                height: 50px;
                border-radius: 25px;
                font-size: 16px;

                .icon {
                    width: 26px;
                }
            }



            &:hover {
                opacity: 0.7;
            }
        }
    }

    .about2 {
        padding: 50px 0;
        background-color: #fff;

        // @include xl-desktop {
        //     padding: 100px 0;
        // }

        @include large-desktop {
            padding: 40px 0;
        }

        @include desktop {
            padding: 30px 0;
        }

        .title {
            font-size: 26px;
            color: #000;
            font-weight: bold;
            text-align: center;
            padding-bottom: 10px;

            // @include xl-desktop {
            //     font-size: 30px;
            //     padding-bottom: 15px;
            // }

            @include large-desktop {
                font-size: 22px;
                padding-bottom: 10px;
            }

            @include desktop {
                font-size: 20px;
                padding-bottom: 10px;
            }
        }

        .desc {
            font-size: 18px;
            color: #828282;
            line-height: 30px;
            text-align: center;

            // @include xl-desktop {
            //     font-size: 20px;
            //     line-height: 30px;
            // }

            @include large-desktop {
                font-size: 16px;
                line-height: 28px;
            }

            @include desktop {
                font-size: 14px;
                line-height: 24px;
            }
        }
        .row{
            @include desktop {
                padding-left: 5px;
                padding-right: 5px;
            }
            .wow{
                // @include xl-desktop {
                //     padding-left: 15px;
                //     padding-right: 15px;
                // }
    
                @include large-desktop {
                    padding-left: 15px;
                    padding-right: 15px;
                }
    
                @include desktop {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }

        .sl-btn {
            margin: 30px auto 0 auto;
            height: 50px;
            border-radius: 30px;
            border: 2px solid $color-primary;
            font-size: 18px;
            color: $color-primary;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            

            // @include xl-desktop {
            //     margin: 50px auto 0 auto;
            //     height: 60px;
            //     border-radius: 30px;
            //     font-size: 22px;

            // }

            @include large-desktop {
                margin: 30px auto 0 auto;
                height: 40px;
                border-radius: 25px;
                font-size: 16px;
            }

            @include desktop {
                margin: 30px auto 0 auto;
                height: 40px;
                border-radius: 20px;
                font-size: 14px;
            }

            .icon {
                width: 40px;
                margin-right: 10px;

                @include xl-desktop {
                    width: 32px;

                }

                @include large-desktop {
                    width: 26px;
                }

                @include desktop {
                    width: 20px;
                }
            }



            &:hover {
                opacity: 0.7;
            }
        }
    }

    .sl-swiper2 {
        background-color: #fff;
        padding: 0 0 80px 0;

        // @include xl-desktop {
        //     padding: 0 0 80px 0;

        // }

        @include large-desktop {
            padding: 0 0 90px 0;
        }

        @include desktop {
            padding: 0 0 60px 0;
        }

        .swiper-box {
            width: 100%;
            height: 200px;
            position: relative;

            // @include xl-desktop {
            //     height: 250px;
            // }

            @include large-desktop {
                height: 180px;
            }

            @include desktop {
                height: 150px;
            }

            .homeSwiper2 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide {
                    width: 80%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 10px;
                    }

                }

                .homeSwiper2-pagination {
                    bottom: -60px;
                    text-align: center;

                    @include xl-desktop {
                        bottom: -50px;
                    }

                    @include large-desktop {
                        bottom: -40px;
                    }

                    @include desktop {
                        bottom: -30px;
                    }

                    .swiper-pagination-bullet {
                        width: 110px;
                        height: 5px;
                        background-color: #d1d1d1;
                        border-radius: 0;

                        @include xl-desktop {
                            width: 80px;
                            height: 4px;
                        }

                        @include large-desktop {
                            width: 60px;
                            height: 3px;
                        }

                        @include desktop {
                            width: 40px;
                            height: 3px;
                        }
                    }

                    .swiper-pagination-bullet-active {
                        background-color: $color-primary;
                    }
                }
            }
        }
    }





}