.index {
  overflow: hidden;
    .sl-banner{
        
        background-color: #c1c1c1;
        position: relative;
        .cont{
            width: 100%;
            position: relative;
            overflow: hidden;
            .img-box{
                background-position: center;
                background-position: no-repeat;
                background-size: cover;
                height: 100%;
            }
            .learn-more-hover{
              font-size: 14px;
              color: #fff;
              height: 30px;
              line-height: 30px;
              padding: 0 20px;
              border-radius: 15px;
              border: 1px solid #fff;
              cursor: pointer;
              margin-top: 20px;
              &:hover{
                background-color: #fff;
                color:#018675;
              }
            }
            

        }
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
          font-size: 50px;
          // @include x6-desktop {
          //   font-size: 60px;
          // }

          @include large-desktop {
            font-size: 36px;
          }

          @include desktop {
            font-size: 26px;
          }
        }
    }


    
    .hidden {

        opacity: 0;
  
        display: none;
  
        transition: 500ms;
  
      }
  
      .show {
  
        opacity: 1 !important;
  
        transition: 500ms;
        z-index: 2;
  
      }


    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
				transition: all 0.3s;
            }

            .making-img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 110px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    @include xl-desktop {
                        font-size: 80px;
                    }

                    @include large-desktop {
                        font-size: 40px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 36px;
                    color: #fff;
                    text-align: center;
                    padding-top: 20px;
					transition: all 0.3s;

                    @include xl-desktop {
                        font-size: 28px;
                        padding-top: 15px;
                    }

                    @include large-desktop {
                        font-size: 18px;
                        padding-top: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 5px;
                    }
                }

                .btn-all {
                    height: 50px;
                    border-radius: 25px;
                    border: 1px solid #fff;
                    margin: 60px auto 0 auto;
                    font-size: 24px;
                    color: #fff;
                    line-height: 50px;
                    width: 160px;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    @include xl-desktop {
                        height: 36px;
                        margin: 40px auto 0 auto;
                        line-height: 36px;
                        width: 120px;
                        font-size: 18px;
                    }

                    @include large-desktop {
                        height: 30px;
                        margin: 30px auto 0 auto;
                        line-height: 30px;
                        width: 90px;
                        font-size: 14px;
                    }

                    &:hover {
                        background-color: #fff;
                        color: #000;
                    }
                }
            }
        }
    }

    .ad2 {
        .sl-carousel {
            height: 520px;
            position: relative;
            background-position:bottom center;
            background-size:cover;
            background-repeat: no-repeat;
            // height: 500px;
            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }
            @include desktop {
              height: 350px;
            }

            .making {
                position: absolute;
                right: 0;
                top: 8%;
                
                .cont{
                  background-color: #fff;
                  width: 60vw;
                  border-radius: 0 0 0 40px;
                  padding-top: 20px;
                  padding-bottom: 60px;
                  padding-left: 60px;
                  height: 350px;
                  position: relative;
                  // @include xl-desktop {
                  //   height: 30vw;
                  //   padding-bottom: 30px;
                  //   padding-left: 30px;

                  // }

                  @include large-desktop {
                      
                  }

                  @include desktop {
                      width: 90vw;
                      padding-bottom: 10px;
                      padding-left: 10px;
                      padding-top: 0px;
                      border-radius: 0 0 0 20px;
                      height: 250px;
                  }
                  .tit {
                    padding-bottom: 30px;
                    display: inline-block;
        
                    @include xl-desktop {
                        padding-bottom: 20px;
                    }
        
                    @include large-desktop {
                        padding-bottom: 10px;
                    }
        
                    @include desktop {
                        padding-bottom: 5px;
                    }
        
                    .name-en {
                        height: 40px;
                        font-size: 20px;
                        color: $color-primary;
                        line-height: 40px;
                        width: 14vw;
                        border-bottom: 1px solid $color-primary;
                        font-family: SourceHanSansCN-Medium;
        
                        // @include xl-desktop {
                        //     height: 50px;
                        //     font-size: 22px;
                        //     line-height: 50px;
                        // }
        
                        @include large-desktop {
                            height: 36px;
                            font-size: 18px;
                            line-height: 36px;
                        }
        
                        @include desktop {
                          width: 24vw;
                            height: 30px;
                            font-size: 14px;
                            line-height: 30px;
                        }
        
                    }
        
                    .name {
                        font-size: 26px;
                        color: #000;
        
                        span {
                            font-size: 28px;
                            font-weight: bold;
                        }
        
                        // @include xl-desktop {
                        //     font-size: 30px;
        
                        //     span {
                        //         font-size: 32px;
                        //     }
                        // }
        
                        @include large-desktop {
                            font-size: 18px;
        
                            span {
                                font-size: 20px;
                            }
                        }
        
                        @include desktop {
                            font-size: 16px;
        
                            span {
                                font-size: 22px;
                            }
                        }
                    }
                  }
          
                  .text {
                      p {
                          // width: 40vw;
                          font-size: 18px;
                          color: #727171;
                          font-family: SourceHanSansCN-Regular;
                          line-height: 1.6em;
          
                          // @include xl-desktop {
                          //     font-size: 20px;
                          // }
          
                          @include large-desktop {
                              font-size: 16px;
                              width: auto;
                          }
          
                          @include desktop {
                              font-size: 14px;
                              -webkit-line-clamp: 4;
                          }
                      }
                  }
                  .btn-all {
                    position: absolute;
                    bottom: 30px;
                    width: 100px;
                    height: 36px;
                    border-radius: 18px;
                    background-color: $color-primary;
                    font-size: 14px;
                    color: #fff;
                    line-height: 36px;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    // @include xl-desktop {
                    //   bottom: 20px;
                    // }
  
                    @include large-desktop {
                        bottom: 30px;
                    }
  
                    @include desktop {
                      font-size: 14px;
                      height: 26px;
                      line-height: 26px;
                      width: 90px;
                      bottom: 10px;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                    
                  }
                }

                // .left {
                //     height: 100%;

                //     .logo {
                //         height: 140px;

                //         @include xl-desktop {
                //             height: 100px;
                //             margin: 10px 0;
                //         }

                //         @include large-desktop {
                //             height: 80px;
                //             margin: 10px 0;
                //         }

                //         @include desktop {
                //             height: 30px;
                //             margin: 10px 0;
                //         }
                //     }

                // }


                // .title {
                //     font-size: 34px;
                //     color: #fff;
                //     font-family: SourceHanSansCN-Medium;

                // }

                // .text {
                //     padding-top: 30px;

                //     p {
                //         font-size: 22px;
                //         color: #fff;
                //         line-height: 36px;
                //         opacity: 0.8;
                //         font-family: SourceHanSansCN-Regular;
                //     }
                // }

                // .btn-all {
                //     margin-top: 85px;
                //     width: 180px;
                //     height: 60px;
                //     border-radius: 30px;
                //     background-color: $color-primary;
                //     font-size: 20px;
                //     color: #fff;
                //     line-height: 60px;
                //     text-align: center;
                //     cursor: pointer;
                //     transition: all 0.3s ease-in-out;

                //     &:hover {
                //         background-color: #fff;
                //         color: $color-primary;
                //     }
                // }

                // @include xl-desktop {
                //     .title {
                //         font-size: 28px;
                //     }

                //     .text {
                //         padding-top: 15px;

                //         p {
                //             font-size: 20px;
                //             line-height: 30px;
                //         }
                //     }

                //     .btn-all {
                //         margin-top: 50px;
                //         width: 120px;
                //         height: 40px;
                //         border-radius: 20px;
                //         font-size: 16px;
                //         line-height: 40px;
                //     }


                // }

                // @include large-desktop {
                //     .title {
                //         font-size: 26px;
                //     }

                //     .text {
                //         padding-top: 15px;

                //         p {
                //             font-size: 18px;
                //             line-height: 26px;
                //         }
                //     }

                //     .btn-all {
                //         margin-top: 30px;
                //         width: 100px;
                //         height: 30px;
                //         border-radius: 15px;
                //         font-size: 14px;
                //         line-height: 30px;
                //     }
                // }

                // @include desktop {
                //     .title {
                //         font-size: 16px;
                //     }

                //     .text {
                //         padding-top: 5px;

                //         p {
                //             font-size: 12px;
                //             line-height: 18px;
                //             -webkit-line-clamp: 2;
                //         }
                //     }

                //     .btn-all {
                //         margin-top: 15px;
                //         width: 90px;
                //         height: 30px;
                //         border-radius: 15px;
                //         font-size: 12px;
                //         line-height: 30px;
                //     }
                // }

            }
        }
    }

    .box-title {
        padding: 70px 90px 40px 90px;

        @include xl-desktop {
            padding: 30px 0;
        }

        @include large-desktop {
            padding: 10px 0;
        }

        @include desktop {
            padding: 10px 0;
        }

        .tit {
            padding-bottom: 20px;
            display: inline-block;

            @include xl-desktop {
                padding-bottom: 10px;
            }

            @include large-desktop {
                padding-bottom: 5px;
            }

            @include desktop {
                padding-bottom: 5px;
            }

            .name-en {
                // height: 60px;
                // font-size: 24px;
                color: $color-primary;
                // line-height: 60px;
                border-bottom: 1px solid $color-primary;
                font-family: SourceHanSansCN-Medium;
                height: 50px;
                font-size: 20px;
                line-height: 50px;

                // @include xl-desktop {
                //     height: 50px;
                //     font-size: 22px;
                //     line-height: 50px;
                // }

                @include large-desktop {
                    height: 40px;
                    font-size: 20px;
                    line-height: 40px;
                }

                @include desktop {
                    height: 30px;
                    font-size: 18px;
                    line-height: 30px;
                }

            }

            .name {
                font-size: 24px;
                color: #000;

                span {
                    font-size: 26px;
                    font-weight: bold;
                }

                // @include xl-desktop {
                //     font-size: 24px;

                //     span {
                //         font-size: 32px;
                //     }
                // }

                @include large-desktop {
                    font-size: 20px;

                    span {
                        font-size: 22px;
                    }
                }

                @include desktop {
                    font-size: 16px;

                    span {
                        font-size: 18px;
                    }
                }
            }
        }

        .text {
            height: 100%;

            p {
                font-size: 16px;
                color: #727171;
                font-family: SourceHanSansCN-Regular;

                // @include xl-desktop {
                //     font-size: 20px;
                // }

                // @include large-desktop {
                //     font-size: 16px;
                // }

                @include desktop {
                    font-size: 14px;
                }
            }
        }

        .ul {
            margin: 0 -12px;

            .li {
                height: 30px;
                line-height: 30px;
                font-size: 16px;
                color: #727171;
                padding: 0 10px;
                border-radius: 15px;
                background-color: #f3f3f3;
                margin: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                // @include xl-desktop {
                //     height: 40px;
                //     line-height: 40px;
                //     font-size: 20px;
                //     padding: 0 15px;
                //     border-radius: 20px;
                //     margin: 8px;
                // }

                // @include large-desktop {
                //     height: 30px;
                //     line-height: 36px;
                //     font-size: 18px;
                //     padding: 0 10px;
                //     border-radius: 18px;
                //     margin: 5px;
                // }

                @include desktop {
                    height: 24px;
                    line-height: 24px;
                    font-size: 14px;
                    padding: 0 10px;
                    border-radius: 12px;
                    margin: 5px;
                }

                span {
                    font-weight: bold;
                }

                &:hover {
                    background-color: $color-primary;
                    color: #fff;
                }

                &.active {
                    background-color: $color-primary;
                    color: #fff;
                }
            }
        }

        .btn-all {
            font-size: 18px;
            color: $color-primary;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            @include xl-desktop {
                font-size: 16px;
            }

            @include large-desktop {
                font-size: 14px;
            }

            @include desktop {
                font-size: 12px;
            }

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .sl-swiper {
        padding: 0 100px 80px 100px;

        @include xl-desktop {
            padding: 0 50px 40px 50px;
        }

        @include large-desktop {
            padding: 0 40px 20px 40px;
        }

        @include desktop {
            padding: 0 30px 10px 30px;
        }

        .swiper-box {
            width: 100%;
            height: 350px;
            position: relative;

            
            // @include xl-desktop {
            //     height: 450px;
            // }
            // @include x6-desktop {
            //   height: 400px;
            // }

            @include large-desktop {
                height: 300px;
            }

            @include desktop {
                height: 300px;
            }

            .homeSwiper1 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide {
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;
                    cursor: pointer;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
						            background-color: #eee;
                    }

                    &:hover .img-box {
                        transform: scale(1.1);
                    }

                    .info {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: rgba(0, 0, 0, 0.1);
                        padding: 10px;
                        transition: all 0.3s ease-in-out;
                        z-index: 1;
                        overflow: hidden;

                        .name {
                            font-size: 18px;
                            // font-weight: bold;
                            color: #fff;
                            text-align: center;
                            font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        }

                        // @include xl-desktop {
                        //     padding: 15px;

                        //     .name {
                        //         font-size: 20px;
                        //     }
                        // }

                        @include large-desktop {
                            padding: 8px;

                            .name {
                                font-size: 16px;
                            }
                        }

                        @include desktop {
                            padding: 8px;

                            .name {
                                font-size: 14px;
                            }
                        }
                    }

                    &:hover .info {
                        bottom: -100%;
                    }

                    .info2 {
                        position: absolute;
                        left: 0;
                        bottom: -100%;
                        right: 0;
                        padding: 20px 5px 5px 5px;
                        background-color: $color-primary;
                        transition: all 0.3s ease-in-out;
                        overflow: hidden;

                        .name {
                            font-size: 18px;
                            // font-weight: bold;
                            color: #fff;
                            text-align: center;
                            padding-bottom: 15px;
                            font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        }

                        .text {
                            font-size: 16px;
                            color: #fff;
                        }

                        .right {
                            .icon {
                                width: 26px;
                            }
                        }

                        // @include xl-desktop {
                        //     padding: 25px 10px 5px 10px;

                        //     .name {
                        //         font-size: 20px;
                        //         padding-bottom: 10px;
                        //     }

                        //     .text {
                        //         font-size: 16px;
                        //     }

                        //     .right {
                        //         .icon {
                        //             width: 26px;
                        //         }
                        //     }
                        // }

                        @include large-desktop {
                            padding: 20px 5px 5px 5px;

                            .name {
                                font-size: 16px;
                                padding-bottom: 5px;
                            }

                            .text {
                                font-size: 14px;
                            }

                            .right {
                                .icon {
                                    width: 20px;
                                }
                            }
                        }

                        @include desktop {
                            padding: 15px 5px 5px 5px;

                            .name {
                                font-size: 14px;
                                padding-bottom: 2px;
                            }

                            .text {
                                font-size: 12px;
                            }

                            .right {
                                .icon {
                                    width: 16px;
                                }
                            }
                        }
                    }

                    &:hover .info2 {
                        bottom: 0;
                    }
                }
            }
        }

        .homeSwiper1-button-prev {
            width: 50px;
            position: absolute;
            left: -70px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            @include xl-desktop {
                width: 40px;
                left: -50px;
            }

            @include large-desktop {
                width: 30px;
                left: -40px;
            }

            @include desktop {
                width: 20px;
                left: -30px;
            }
        }

        .homeSwiper1-button-next {
            width: 50px;
            position: absolute;
            right: -70px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            @include xl-desktop {
                width: 40px;
                right: -50px;
            }

            @include large-desktop {
                width: 30px;
                right: -40px;
            }

            @include desktop {
                width: 20px;
                right: -30px;
            }
        }
    }

    .sl-zoom {
        padding: 0 100px 50px 100px;

        @include xl-desktop {
            padding: 0 0 40px 0;
        }

        @include large-desktop {
            padding: 0 0 30px 0;
        }

        @include desktop {
            padding: 0 0 20px 0;
        }

        .zoom-box {
            width: 100%;
            height: 600px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;

            // @include xl-desktop {
            //     height: 600px;
            // }

            @include large-desktop {
                height: 450px;
            }

            @include desktop {
                height: 350px;
            }

            .item {
                width: 88px;
                overflow: hidden;
                cursor: pointer;
                transition: all 0.8s ease-in-out;
                position: relative;

                @include xl-desktop {
                    width: 60px;
                }

                @include large-desktop {
                    width: 40px;
                }

                @include desktop {
                    width: 30px;
                }

                .img-box {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    transition: all 0.8s ease-in-out;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .making {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(1, 136, 118, 0.7);
                    font-size: 24px;
                    color: #fff;
                    writing-mode: vertical-rl;
                    text-orientation: upright;
                    letter-spacing: 15px;
                    transition: all 0.8s ease-in-out;

                    // @include xl-desktop {
                    //     font-size: 30px;
                    // }

                    @include large-desktop {
                        font-size: 20px;
                    }

                    @include desktop {
                        font-size: 18px;
                    }
                }

                .making2 {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.8s ease-in-out;

                    .making2-box {
                        background-color: rgba(0, 0, 0, 0.2);
                        padding: 40px 60px;
                        max-width: 650px;
                        border-radius: 10px;

                        .title {
                            font-size: 30px;
                            color: #fff;
                            padding-bottom: 20px;
                        }

                        .subtitle {
                            font-size: 20px;
                            color: #fff;
                            line-height: 36px;
                        }

                        // @include xl-desktop {
                        //     padding: 40px 60px;
                        //     border-radius: 10px;

                        //     .title {
                        //         font-size: 40px;
                        //         padding-bottom: 15px;
                        //     }

                        //     .subtitle {
                        //         font-size: 20px;
                        //         line-height: 30px;
                        //     }
                        // }

                        @include large-desktop {
                            padding: 30px 40px;
                            border-radius: 10px;

                            .title {
                                font-size: 24px;
                                padding-bottom: 10px;
                            }

                            .subtitle {
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }

                        @include desktop {
                            padding: 20px 20px;
                            border-radius: 10px;

                            .title {
                                font-size: 22px;
                                padding-bottom: 5px;
                            }

                            .subtitle {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                    }
                }

                &.active {
                    flex: 1;
                }

                &.active .making {
                    opacity: 0;
                }

                &.active .making2 {
                    opacity: 1;
                }
            }


        }

        .zoom-box-btns {
            padding-top: 30px;

            .btn-prev,
            .btn-next {
                width: 46px;
                height: 46px;
                margin: 0 26px;
                cursor: pointer;
            }

            @include xl-desktop {
                padding-top: 24px;

                .btn-prev,
                .btn-next {
                    width: 40px;
                    height: 40px;
                    margin: 0 20px;
                }
            }

            @include large-desktop {
                padding-top: 20px;

                .btn-prev,
                .btn-next {
                    width: 36px;
                    height: 36px;
                    margin: 0 16px;
                }
            }

            @include desktop {
                padding-top: 10px;

                .btn-prev,
                .btn-next {
                    width: 30px;
                    height: 30px;
                    margin: 0 10px;
                }
            }

            &.disabled {
                opacity: 0.2;
            }
        }
    }

    .sl-swiper2 {
        background-color: #f3f3f3;
        padding: 0 100px 130px 100px;

        @include xl-desktop {
            padding: 0 0 80px 0;
        }

        @include large-desktop {
            padding: 0 0 60px 0;
        }

        @include desktop {
            padding: 0 0 40px 0;
        }

        .swiper-box {
            width: 100%;
            height: 680px;
            position: relative;

            @include xl-desktop {
                height: 600px;
            }
            @include x6-desktop {
              height: 550px;
            }

            @include large-desktop {
                height: 450px;
            }

            @include desktop {
                height: 300px;
            }

            .homeSwiper2 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide {
                    width: 80%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    .making {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        padding: 40px;

                        @include xl-desktop {
                            padding: 30px;
                        }

                        @include large-desktop {
                            padding: 20px;
                        }

                        @include desktop {
                            padding: 10px;
                        }

                        .making-box {
                            max-width: 500px;
                            background-color: rgba(0, 0, 0, 0.2);
                            border-radius: 15px;
                            padding: 50px;

                            @include xl-desktop {
                                border-radius: 15px;
                                padding: 50px;
                            }

                            @include large-desktop {
                                border-radius: 10px;
                                padding: 25px;
                            }

                            @include desktop {
                                border-radius: 5px;
                                padding: 10px;
                            }

                            .title {
                                font-size: 40px;
                                color: #fff;
                                padding-bottom: 20px;

                                @include xl-desktop {
                                    font-size: 30px;
                                    padding-bottom: 15px;
                                }

                                @include large-desktop {
                                    font-size: 26px;
                                    padding-bottom: 10px;
                                }

                                @include desktop {
                                    font-size: 20px;
                                    padding-bottom: 5px;
                                }
                            }

                            .subtitle {
                                font-size: 22px;
                                color: #fff;
                                line-height: 36px;
                                opacity: 0.8;

                                @include xl-desktop {
                                    font-size: 18px;
                                    line-height: 30px;
                                }

                                @include large-desktop {
                                    font-size: 16px;
                                    line-height: 26px;
                                }

                                @include desktop {
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }

                            .btn-all {
                                margin-top: 48px;
                                width: 120px;
                                height: 40px;
                                border-radius: 20px;
                                background-color: $color-primary;
                                font-size: 18px;
                                color: #fff;
                                line-height: 40px;
                                text-align: center;
                                cursor: pointer;

                                @include xl-desktop {
                                    margin-top: 40px;
                                    width: 100px;
                                    height: 36px;
                                    border-radius: 18px;
                                    line-height: 36px;
                                    font-size: 16px;
                                }

                                @include large-desktop {
                                    margin-top: 30px;
                                    width: 90px;
                                    height: 30px;
                                    border-radius: 15px;
                                    line-height: 30px;
                                    font-size: 14px;
                                }

                                @include desktop {
                                    margin-top: 30px;
                                    width: 80px;
                                    height: 30px;
                                    border-radius: 15px;
                                    line-height: 30px;
                                    font-size: 12px;
                                }

                                &:hover {
                                    background-color: #fff;
                                    color: $color-primary;
                                }
                            }
                        }
                    }
                }

                .homeSwiper2-pagination {
                    bottom: -60px;
                    text-align: left;

                    .swiper-pagination-bullet {
                        width: 110px;
                        height: 5px;
                        background-color: #d1d1d1;
                        border-radius: 0;
                    }

                    @include xl-desktop {
                        bottom: -55px;

                        .swiper-pagination-bullet {
                            width: 80px;
                            height: 4px;
                        }
                    }

                    @include large-desktop {
                        bottom: -50px;

                        .swiper-pagination-bullet {
                            width: 50px;
                            height: 3px;
                        }
                    }

                    @include desktop {
                        bottom: -50px;

                        .swiper-pagination-bullet {
                            width: 30px;
                            height: 3px;
                        }
                    }

                    .swiper-pagination-bullet-active {
                        background-color: $color-primary;
                    }
                }
            }
        }
    }

    .sl-example {
        background-position: center;
        background-size: cover;
        background-repeat: repeat;

        .ul {
          padding-right: 50px;
          @include x6-desktop {
            padding-right: 0;
          }
          @include large-desktop {
            padding-right: 0;
          }
            .li {
                // min-width: 180px;
                // height: 80px;
                // border-radius: 15px;
                background-color: #c1c1c1;
                // font-size: 20px;
                color: #fff;
                // line-height: 80px;
                text-align: center;
                margin: 2px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                min-width: 100px;
                height: 40px;
                border-radius: 10px;
                font-size: 16px;
                line-height: 40px;

                // @include xl-desktop {
                //     min-width: 120px;
                //     height: 48px;
                //     border-radius: 10px;
                //     font-size: 18px;
                //     line-height: 48px;
                // }

                @include large-desktop {
                    min-width: 90px;
                    height: 32px;
                    border-radius: 8px;
                    font-size: 14px;
                    line-height: 32px;
                }

                @include desktop {
                    min-width: 70px;
                    height: 26px;
                    border-radius: 5px;
                    font-size: 12px;
                    line-height: 26px;
                }
            }

            .li:hover {
                background-color: $color-primary;
            }

            .li.active {
                background-color: $color-primary;
            }
        }

        .box-title {
            padding: 0 100px 50px 100px;

            @include xl-desktop {
                padding: 0 0px 40px 0;
            }

            @include large-desktop {
                padding: 0 0px 30px 0;
            }

            @include desktop {
                padding: 0 0px 20px 0;
            }
        }

        .sl-row {
            padding-bottom: 90px;

            @include xl-desktop {
                padding-bottom: 70px;
            }

            @include large-desktop {
                padding-bottom: 50px;
            }

            @include desktop {
                padding-bottom: 30px;
            }

            .sl-item {
                height: 500px;
                position: relative;

                // @include xl-desktop {
                //     height: 700px;
                // }
                // @include x6-desktop {
                //   height: 600px;
                // }

                @include large-desktop {
                    height: 400px;
                }

                @include desktop {
                    height: 250px;
                }

                .img-box {
                    height: 100%;
                    width: 100%;
                    clip-path: url(#myShape);
                    position: relative;
                    top: -24px;
                }

                .making {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    width: 420px;
                    padding: 0 50px;
                    background-color: $color-primary;

                    @include xl-desktop {
                        width: 300px;
                        padding: 0 30px;
                    }

                    @include large-desktop {
                        width: 250px;
                        padding: 0 20px;
                    }

                    @include desktop {
                        width: 150px;
                        padding: 0 10px;
                    }

                    .making-box {
                        .title {
                            font-size: 24px;
                            color: #fff;

                            // @include xl-desktop {
                            //     font-size: 30px;
                            // }

                            @include large-desktop {
                                font-size: 18px;
                            }

                            @include desktop {
                                font-size: 16px;
                            }
                        }

                        .subtitle {
                            padding-top: 20px;
                            padding-bottom: 50px;


                            p {
                                font-size: 16px;
                                color: #fff;
                                line-height: 28px;
                                text-align: justify;
                            }

                            // @include xl-desktop {
                            //     padding-top: 30px;
                            //     padding-bottom: 50px;

                            //     p {
                            //         font-size: 18px;
                            //         line-height: 28px;
                            //     }
                            // }

                            @include large-desktop {
                                padding-top: 24px;
                                padding-bottom: 30px;

                                p {
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }

                            @include desktop {
                                padding-top: 10px;
                                padding-bottom: 20px;

                                p {
                                    font-size: 12px;
                                    line-height: 20px;
                                }
                            }
                        }

                        .btn-all {
                            width: 90px;
                            height: 30px;
                            border-radius: 15px;
                            border: 1px solid #fff;
                            font-size: 16px;
                            color: #fff;
                            line-height: 30px;
                            text-align: center;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
                            margin-top: 15px;

                            // @include xl-desktop {
                            //     width: 100px;
                            //     height: 36px;
                            //     border-radius: 18px;
                            //     font-size: 16px;
                            //     line-height: 36px;
                            // }

                            @include large-desktop {
                                width: 86px;
                                height: 30px;
                                border-radius: 15px;
                                font-size: 14px;
                                line-height: 30px;
                            }

                            @include desktop {
                                width: 70px;
                                height: 26px;
                                border-radius: 15px;
                                font-size: 12px;
                                line-height: 26px;
                            }

                            &:hover {
                                background-color: #fff;
                                color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
    }

    .sl-swiper3 {
        padding-bottom: 120px;

        @include xl-desktop {
            padding-bottom: 100px;
        }

        @include large-desktop {
            padding-bottom: 80px;
        }

        @include desktop {
            padding-bottom: 60px;
        }

        .swiper-box {
            width: 100%;
            height: 200px;
            position: relative;

            // @include xl-desktop {
            //     height: 240px;
            // }

            @include large-desktop {
                height: 180px;
            }

            @include desktop {
                height: 180px;
            }

            .homeSwiper3 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide {
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;
                    cursor: pointer;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    &:hover .img-box {
                        transform: scale(1.1);
                    }

                    .info2 {
                        position: absolute;
                        left: 0;
                        bottom: -100%;
                        right: 0;
                        padding: 20px 20px 10px 20px;
                        // background-color: $color-primary;
                        background: linear-gradient(to bottom, rgba(1, 136, 118, 0), rgba(1, 136, 118, 0.3), rgba(1, 136, 118, 0.6));
                        transition: all 0.3s ease-in-out;
                        overflow: hidden;

                        .name {
                            font-size: 18px;
                            font-weight: bold;
                            color: #fff;
                            padding-bottom: 10px;
                        }

                        .text {
                            font-size: 14px;
                            color: #fff;
                        }

                        .right {
                            .icon {
                                width: 30px;
                            }
                        }

                        // @include xl-desktop {
                        //     padding: 30px 30px 15px 30px;

                        //     .name {
                        //         font-size: 20px;
                        //         padding-bottom: 12px;
                        //     }

                        //     .text {
                        //         font-size: 16px;
                        //     }

                        //     .right {
                        //         .icon {
                        //             width: 26px;
                        //         }
                        //     }
                        // }

                        @include large-desktop {
                            padding:10px;

                            .name {
                                font-size: 16px;
                                padding-bottom: 10px;
                            }

                            .text {
                                font-size: 12px;
                            }

                            .right {
                                .icon {
                                    width: 24px;
                                }
                            }
                        }

                        @include desktop {
                            padding: 10px;

                            .name {
                                font-size: 16px;
                                padding-bottom: 5px;
                            }

                            .text {
                                font-size: 12px;
                            }

                            .right {
                                .icon {
                                    width: 20px;
                                }
                            }
                        }
                    }

                    &:hover .info2 {
                        bottom: 0;
                    }
                }
            }
        }

        .homeSwiper3-button-prev {
            margin: 30px 35px;
            cursor: pointer;

            @include xl-desktop {
                margin: 26px 30px;
                width: 50px;
            }

            @include large-desktop {
                margin: 20px 25px;
                width: 40px;
            }

            @include desktop {
                margin: 12px 10px;
                width: 30px;
            }
        }

        .homeSwiper3-button-next {
            margin: 30px 35px;
            cursor: pointer;

            @include xl-desktop {
                margin: 26px 30px;
                width: 50px;
            }

            @include large-desktop {
                margin: 20px 25px;
                width: 40px;
            }

            @include desktop {
                margin: 12px 10px;
                width: 30px;
            }
        }
    }


















    @media print, screen and (min-width: 768px) {
        #u-news .tab-news .m-txt-ttl7 {
          margin-bottom: 0;
        }
      }
      #u-news .tab-news .m-box-tabhead .tabhead-list {
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        margin-left: 0;
      }
      #u-news .tab-news .m-box-tabhead .tabhead-list .list-item:before {
        display: none;
      }
      @media print, screen and (min-width: 768px) {
        #u-news .tab-news .m-box-tabhead .tabhead-list .list-item {
          font-size: 1.4rem;
        }
      }
      
      .u-list-link {
        margin-top: 20px;
        overflow: hidden;
      }
      @media print, screen and (min-width: 768px) {
        .u-list-link {
          margin-top: 40px;
        }
      }
      @media print, screen and (min-width: 768px) {
        .u-list-link .link-list {
          margin: 0 -22px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
        }
      }
      @media print {
        .u-list-link .link-list {
          display: block;
          letter-spacing: -0.4em;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .u-list-link .link-list .list-item {
          width: calc(100% / 2);
          width: calc((100% / 2) - 0.1px) \0 ;
          padding: 0 22px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-list-link .link-list .list-item {
          width: calc(100% / 4);
          width: calc((100% / 4) - 0.1px) \0 ;
          padding: 0 22px;
        }
      }
      @media print {
        .u-list-link .link-list .list-item {
          width: 25%;
          display: inline-block;
          padding: 0 22px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          vertical-align: top;
          letter-spacing: normal;
        }
      }
      .u-list-link .link-list .list-item + .list-item {
        margin-top: 40px;
      }
      @media print, screen and (min-width: 768px) {
        .u-list-link .link-list .list-item + .list-item {
          margin-top: 0;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .u-list-link .link-list .list-item:nth-child(n+3) {
          margin-top: 40px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-list-link .link-list .list-item:nth-child(n+5) {
          margin-top: 40px;
        }
      }
      .u-list-link .link-list .list-item > a {
        height: 100%;
      }
      
      .u-btn-common {
        position: relative;
        display: block;
      }
      .u-btn-common::before {
        content: "";
        display: block;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #e6e6e6;
      }
      .u-btn-common::after {
        content: "";
        display: block;
        width: 20px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: #222222;
      }
      .u-btn-common .btn-inner {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        padding: 0px 31px 15px 22px;
        display: inline-block;
        position: relative;
      }
      [lang=cn] .u-btn-common .btn-inner {
        font-weight: 700;
      }
      @media print, screen and (min-width: 768px) {
        .u-btn-common .btn-inner {
          font-size: 2rem;
          padding: 0px 0 30px 40px;
        }
      }
      .u-btn-common .btn-inner::after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background: url(../../svg/i_arrow-222222.svg) center no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 2px;
        bottom: 15px;
        left: 0;
        margin: auto;
      }
      @media print, screen and (min-width: 768px) {
        .u-btn-common .btn-inner::after {
          bottom: 30px;
          left: 4px;
        }
      }
      [data-whatinput=keyboard] .u-btn-common .btn-inner:focus::after {
        -webkit-animation-name: arrowLinkRight;
                animation-name: arrowLinkRight;
        -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-animation-delay: 0;
                animation-delay: 0;
      }
      @media print, screen and (min-width: 1025px) {
        .u-btn-common .btn-inner:hover::after {
          -webkit-animation-name: arrowLinkRight;
                  animation-name: arrowLinkRight;
          -webkit-animation-duration: 0.75s;
                  animation-duration: 0.75s;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          -webkit-animation-delay: 0;
                  animation-delay: 0;
        }
      }
      
      #u-products {
        padding-top: 30px;
      }
      @media print, screen and (min-width: 768px) {
        #u-products {
          padding-top: 60px;
        }
      }
      
      #u-company {
        padding-top: 30px;
      }
      @media print, screen and (min-width: 768px) {
        #u-company {
          padding-top: 60px;
        }
      }
      
      .products-inner,
      .company-inner {
        width: 100%;
        overflow: hidden;
      }
      .products-inner .company-keyvisual,
      .products-inner .products-keyvisual,
      .company-inner .company-keyvisual,
      .company-inner .products-keyvisual {
        position: relative;
        z-index: 1;
      }
      .products-inner .company-keyvisual:before,
      .products-inner .products-keyvisual:before,
      .company-inner .company-keyvisual:before,
      .company-inner .products-keyvisual:before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100vw;
        height: 50%;
        background: #f6f6f6;
      }
      .products-inner .company-keyvisual .keyvisual-img,
      .products-inner .products-keyvisual .keyvisual-img,
      .company-inner .company-keyvisual .keyvisual-img,
      .company-inner .products-keyvisual .keyvisual-img {
        position: relative;
        z-index: 10;
      }
      .products-inner .company-keyvisual .keyvisual-img img,
      .products-inner .products-keyvisual .keyvisual-img img,
      .company-inner .company-keyvisual .keyvisual-img img,
      .company-inner .products-keyvisual .keyvisual-img img {
        width: 100%;
      }
      .products-inner .company-keyvisual .keyvisual-ttl,
      .products-inner .products-keyvisual .keyvisual-ttl,
      .company-inner .company-keyvisual .keyvisual-ttl,
      .company-inner .products-keyvisual .keyvisual-ttl {
        font-size: 3.6rem;
        font-weight: 500;
        color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        left: 0;
        right: 0;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: center;
        line-height: 1.25;
        z-index: 20;
      }
      [lang=cn] .products-inner .company-keyvisual .keyvisual-ttl,
      [lang=cn] .products-inner .products-keyvisual .keyvisual-ttl,
      [lang=cn] .company-inner .company-keyvisual .keyvisual-ttl,
      [lang=cn] .company-inner .products-keyvisual .keyvisual-ttl {
        font-weight: 700;
      }
      [lang=ja] .products-inner .company-keyvisual .keyvisual-ttl,
      [lang=ja] .products-inner .products-keyvisual .keyvisual-ttl,
      [lang=ja] .company-inner .company-keyvisual .keyvisual-ttl,
      [lang=ja] .company-inner .products-keyvisual .keyvisual-ttl {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=en] .products-inner .company-keyvisual .keyvisual-ttl,
      [lang=en] .products-inner .products-keyvisual .keyvisual-ttl,
      [lang=en] .company-inner .company-keyvisual .keyvisual-ttl,
      [lang=en] .company-inner .products-keyvisual .keyvisual-ttl {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=cn] .products-inner .company-keyvisual .keyvisual-ttl,
      [lang=cn] .products-inner .products-keyvisual .keyvisual-ttl,
      [lang=cn] .company-inner .company-keyvisual .keyvisual-ttl,
      [lang=cn] .company-inner .products-keyvisual .keyvisual-ttl {
        letter-spacing: 0.05em;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .company-keyvisual .keyvisual-ttl,
      .products-inner .products-keyvisual .keyvisual-ttl,
      .company-inner .company-keyvisual .keyvisual-ttl,
      .company-inner .products-keyvisual .keyvisual-ttl {
          font-size: 6.4rem;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .company-keyvisual .keyvisual-ttl,
      .products-inner .products-keyvisual .keyvisual-ttl,
      .company-inner .company-keyvisual .keyvisual-ttl,
      .company-inner .products-keyvisual .keyvisual-ttl {
          font-size: 8rem;
        }
      }
      .products-inner .company-keyvisual .keyvisual-start_stop,
      .products-inner .products-keyvisual .keyvisual-start_stop,
      .company-inner .company-keyvisual .keyvisual-start_stop,
      .company-inner .products-keyvisual .keyvisual-start_stop {
        position: absolute;
        z-index:999;
        bottom: 20px;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .company-keyvisual .keyvisual-start_stop,
      .products-inner .products-keyvisual .keyvisual-start_stop,
      .company-inner .company-keyvisual .keyvisual-start_stop,
      .company-inner .products-keyvisual .keyvisual-start_stop {
          bottom: 50px;
        }
      }
      .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn {
        position: relative;
        background: center no-repeat;
        background-size: 100% auto;
        border: none;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        background-image: url(../../svg/i_stop_white.svg);
      }
      .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:before,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:before,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:before,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:before {
        content: "";
        position: absolute;
        top: -22px;
        bottom: -22px;
        left: -22px;
        right: -22px;
        width: 44px;
        height: 44px;
        display: block;
        margin: auto;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn {
          width: 36px;
          height: 36px;
          -webkit-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
        }
      }
      [data-whatinput=keyboard] .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
      [data-whatinput=keyboard] .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
      [data-whatinput=keyboard] .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus,
      [data-whatinput=keyboard] .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus {
        background-image: url(../../svg/i_stop_white_on.svg);
      }
      [data-whatinput=keyboard] .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
      [data-whatinput=keyboard] .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
      [data-whatinput=keyboard] .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active,
      [data-whatinput=keyboard] .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:focus.is-active {
        background-image: url(../../svg/i_start_white.svg);
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover {
          background-image: url(../../svg/i_stop_white_on.svg);
        }
        .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn:hover.is-active {
          background-image: url(../../svg/i_start_white.svg);
        }
      }
      .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn .btn-txt {
        position: absolute !important;
        overflow: hidden !important;
        clip: rect(0 0 0 0) !important;
        width: 1px !important;
        height: 1px !important;
        margin: -1px !important;
        padding: 0 !important;
        border: 0 !important;
      }
      .products-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
      .products-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
      .company-inner .company-keyvisual .keyvisual-start_stop .start_stop-btn.is-active,
      .company-inner .products-keyvisual .keyvisual-start_stop .start_stop-btn.is-active {
        background-image: url(../../svg/i_start_white_on.svg);
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-keyvisual,
      .company-inner .products-keyvisual {
          max-width: 1520px;
          margin: 0 auto;
          position: relative;
          margin-right: auto;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .products-keyvisual:before,
      .company-inner .products-keyvisual:before {
          margin-right: calc(730px - 50vw);
        }
      }
      .products-inner .products-keyvisual .keyvisual-img,
      .company-inner .products-keyvisual .keyvisual-img {
        margin-right: 20px;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .products-keyvisual .keyvisual-img,
      .company-inner .products-keyvisual .keyvisual-img {
          margin-right: 30px;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .products-keyvisual .keyvisual-img,
      .company-inner .products-keyvisual .keyvisual-img {
          margin-left: calc(730px - 50vw);
          margin-right: 0;
          padding-right: 30px;
        }
      }
      .products-inner .products-keyvisual .keyvisual-ttl,
      .company-inner .products-keyvisual .keyvisual-ttl {
        padding-right: 20px;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-keyvisual .keyvisual-ttl,
      .company-inner .products-keyvisual .keyvisual-ttl {
          padding-left: 0;
          padding-right: 0;
          margin-left: 480px;
          margin-right: 30px;
        }
      }
      .products-inner .products-keyvisual .keyvisual-start_stop,
      .company-inner .products-keyvisual .keyvisual-start_stop {
        right: 40px;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .products-keyvisual .keyvisual-start_stop,
      .company-inner .products-keyvisual .keyvisual-start_stop {
          right: 80px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .company-keyvisual,
      .company-inner .company-keyvisual {
          max-width: 1520px;
          margin: 0 auto;
          position: relative;
          margin-right: auto;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .company-keyvisual:before,
      .company-inner .company-keyvisual:before {
          margin-left: calc(730px - 50vw);
        }
      }
      .products-inner .company-keyvisual .keyvisual-img,
      .company-inner .company-keyvisual .keyvisual-img {
        margin-left: 20px;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .company-keyvisual .keyvisual-img,
      .company-inner .company-keyvisual .keyvisual-img {
          margin-left: 30px;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .company-keyvisual .keyvisual-img,
      .company-inner .company-keyvisual .keyvisual-img {
          margin-right: calc(730px - 50vw);
          margin-left: 0;
          padding-left: 30px;
        }
      }
      .products-inner .company-keyvisual .keyvisual-ttl,
      .company-inner .company-keyvisual .keyvisual-ttl {
        padding-left: 20px;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .company-keyvisual .keyvisual-ttl,
      .company-inner .company-keyvisual .keyvisual-ttl {
          padding-right: 0;
          padding-left: 0;
          margin-right: 480px;
          margin-left: 30px;
        }
      }
      .products-inner .company-keyvisual .keyvisual-start_stop,
      .company-inner .company-keyvisual .keyvisual-start_stop {
        left: 40px;
      }
      @media print, screen and (min-width: 768px) {
        .products-inner .company-keyvisual .keyvisual-start_stop,
      .company-inner .company-keyvisual .keyvisual-start_stop {
          left: 80px;
        }
      }
      .products-inner .products-content,
      .products-inner .company-content,
      .company-inner .products-content,
      .company-inner .company-content {
        background-color: #f6f6f6;
        padding-bottom: 55px;
        position: relative;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content,
      .products-inner .company-content,
      .company-inner .products-content,
      .company-inner .company-content {
          padding-top: 90px;
          padding-bottom: 60px;
        }
      }
      .products-inner .products-content .content-inner,
      .products-inner .company-content .content-inner,
      .company-inner .products-content .content-inner,
      .company-inner .company-content .content-inner {
        margin: 0 auto;
        position: relative;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content .content-inner,
      .products-inner .company-content .content-inner,
      .company-inner .products-content .content-inner,
      .company-inner .company-content .content-inner {
          bottom: calc(100% - 60px);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0 30px;
        }
      }
      @media print, screen and (min-width: 1520px) {
        .products-inner .products-content .content-inner,
      .products-inner .company-content .content-inner,
      .company-inner .products-content .content-inner,
      .company-inner .company-content .content-inner {
          max-width: 1520px;
        }
      }
      .products-inner .products-content .content-inner .content-summary,
      .products-inner .company-content .content-inner .content-summary,
      .company-inner .products-content .content-inner .content-summary,
      .company-inner .company-content .content-inner .content-summary {
        z-index: 10;
        background-color: #fff;
        padding: 30px 20px 40px;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content .content-inner .content-summary,
      .products-inner .company-content .content-inner .content-summary,
      .company-inner .products-content .content-inner .content-summary,
      .company-inner .company-content .content-inner .content-summary {
          position: absolute;
          margin-left: 0;
          width: 450px;
          padding: 60px 70px 60px;
          min-height: 365px;
          bottom: calc(100% + 30px);
        }
      }
      .products-inner .products-content .content-inner .content-summary .summary-ttl,
      .products-inner .company-content .content-inner .content-summary .summary-ttl,
      .company-inner .products-content .content-inner .content-summary .summary-ttl,
      .company-inner .company-content .content-inner .content-summary .summary-ttl {
        color: #222222;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.5;
      }
      [lang=cn] .products-inner .products-content .content-inner .content-summary .summary-ttl,
      [lang=cn] .products-inner .company-content .content-inner .content-summary .summary-ttl,
      [lang=cn] .company-inner .products-content .content-inner .content-summary .summary-ttl,
      [lang=cn] .company-inner .company-content .content-inner .content-summary .summary-ttl {
        font-weight: 700;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content .content-inner .content-summary .summary-ttl,
      .products-inner .company-content .content-inner .content-summary .summary-ttl,
      .company-inner .products-content .content-inner .content-summary .summary-ttl,
      .company-inner .company-content .content-inner .content-summary .summary-ttl {
          font-size: 3rem;
        }
      }
      .products-inner .products-content .content-inner .content-summary .summary-desc,
      .products-inner .company-content .content-inner .content-summary .summary-desc,
      .company-inner .products-content .content-inner .content-summary .summary-desc,
      .company-inner .company-content .content-inner .content-summary .summary-desc {
        color: #222222;
        margin-top: 15px;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content .content-inner .content-summary .summary-desc,
      .products-inner .company-content .content-inner .content-summary .summary-desc,
      .company-inner .products-content .content-inner .content-summary .summary-desc,
      .company-inner .company-content .content-inner .content-summary .summary-desc {
          margin-top: 20px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-content .u-list-link,
      .products-inner .company-content .u-list-link,
      .company-inner .products-content .u-list-link,
      .company-inner .company-content .u-list-link {
          margin-top: 0;
        }
      }
      
      .company-content .content-inner {
        padding-left: 20px;
        padding-right: 0;
      }
      @media print, screen and (min-width: 768px) {
        .company-content .content-inner {
          padding-left: 30px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .company-content .content-inner {
          -webkit-box-pack: end;
              -ms-flex-pack: end;
                  justify-content: flex-end;
          padding: 0 30px;
        }
      }
      
      .products-content .content-inner {
        padding-right: 20px;
        padding-left: 0;
      }
      @media print, screen and (min-width: 768px) {
        .products-content .content-inner {
          padding-right: 30px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-content .content-inner {
          padding: 0 30px;
        }
      }
      
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-link {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          margin: 0 -30px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-link .link-item {
          width: calc(100% / 2);
          width: calc((100% / 2) - 0.1px) \0 ;
          padding: 0 30px;
        }
      }
      @media print {
        .products-inner .products-link .link-item {
          width: 50%;
          display: inline-block;
          padding: 0 30px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          vertical-align: top;
          letter-spacing: normal;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-link .link-item .u-list-link .link-list {
          margin: 0 -30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
        }
      }
      @media print {
        .products-inner .products-link .link-item .u-list-link .link-list {
          display: block;
          letter-spacing: -0.4em;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-link .link-item .u-list-link .link-list .list-item {
          width: calc(100% / 2);
          width: calc((100% / 2) - 0.1px) \0 ;
          padding: 0 30px;
        }
      }
      @media print {
        .products-inner .products-link .link-item .u-list-link .link-list .list-item {
          width: 50%;
          display: inline-block;
          padding: 0 30px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          vertical-align: top;
          letter-spacing: normal;
        }
      }
      .products-inner .products-link .link-item:nth-child(n+3) {
        margin-top: 40px;
      }
      @media print, screen and (min-width: 1025px) {
        .products-inner .products-link .m-list-icon {
          margin-top: 0;
        }
      }
      
      #u-instagram {
        background-color: #303030;
        padding: 40px 30px 35px;
        overflow: hidden;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram {
          padding: 60px 30px 45px;
        }
      }
      #u-instagram .instagram-wrap {
        margin: 0 auto;
        position: relative;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap {
          max-width: 1460px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl {
          position: absolute;
          left: 0;
          width: 355px;
          height: 250px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          z-index: 2;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner {
        background-color: #303030;
        width: 100%;
        padding: 0 16px 36px;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner {
          padding: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon {
        width: 100%;
        text-align: center;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon img {
        width: 30px;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-icon img {
          width: 44px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
        margin-top: 10px;
        width: 100%;
        color: #fff;
        font-size: 3rem;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.07em;
      }
      [lang=ja] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=en] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
        letter-spacing: 0.05em;
      }
      [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
        font-weight: 700;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-txt {
          font-size: 3rem;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link {
        margin-top: -5px;
        width: 100%;
        text-align: center;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link {
          margin-top: 0px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
        color: #fff;
        font-size: 1.4rem;
        text-decoration: none;
        letter-spacing: 0.03em;
      }
      [lang=ja] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=en] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=cn] #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
        letter-spacing: 0.05em;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[target=_blank]:not(.noticon):after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 22px;
        margin-left: 5px;
        background: url("../../svg/i_blank-fff.svg") no-repeat right top;
        background-size: 100% auto;
        position: relative;
        top: 0.1em;
        width: 12px;
        top: 0.2em;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".pdf"]:not(.noticon):after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 22px;
        margin-left: 5px;
        background: url("../../svg/i_pdf-fff.svg") no-repeat right top;
        background-size: 100% auto;
        position: relative;
        top: 0.1em;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".doc"]:not(.noticon):after, #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".docx"]:not(.noticon):after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 22px;
        margin-left: 5px;
        background: url("../../svg/i_word-fff.svg") no-repeat right top;
        background-size: 100% auto;
        position: relative;
        top: 0.1em;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".xls"]:not(.noticon):after, #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".xlsx"]:not(.noticon):after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 22px;
        margin-left: 5px;
        background: url("../../svg/i_excel-fff.svg") no-repeat right top;
        background-size: 100% auto;
        position: relative;
        top: 0.1em;
      }
      #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a[href$=".zip"]:not(.noticon):after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 22px;
        margin-left: 5px;
        background: url("../../svg/i_zip-fff.svg") no-repeat right top;
        background-size: 100% auto;
        position: relative;
        top: 0.1em;
        top: 0.2em;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .instagram-ttl .ttl-inner .ttl-inner2 .ttl-link > a {
          font-size: 1.4rem;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item {
        position: relative;
        padding: 0 16px;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item {
          margin: 0 auto;
          padding: 0;
          padding-left: 355px;
          -webkit-box-sizing: content-box;
                  box-sizing: content-box;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner {
        position: relative;
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container {
        overflow: visible;
        padding-bottom: 15px;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container {
          margin: 0;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-wrapper .swiper-slide {
        width: 200px;
        height: 200px;
      }
      @media print, screen and (min-width: 768px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-wrapper .swiper-slide {
          width: 250px;
          height: 250px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
        position: relative;
        background-color: rgba(255, 255, 255, 0.08);
        margin: 30px 155px 15px 0;
        width: calc(100% - 155px);
        height: 3px;
        display: none;
      }
      @media print, screen and (min-width: 768px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
          margin: 40px 155px 15px 0;
        }
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
          margin-left: -355px;
          width: calc(100% + 355px - 155px);
        }
      }
      @media print, screen and (min-width: 1460px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination {
          width: calc(100% + 355px - 155px);
          margin-left: -355px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .item-swiper.swiper-container .swiper-pagination .swiper-pagination-progressbar-fill {
        background-color: white;
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button {
        position: absolute;
        bottom: 15px;
        background-color: transparent;
        width: 50px;
        height: 30px;
        border: none;
        z-index: 1;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        outline: none;
        padding: 0;
      }
      @media print, screen and (min-width: 768px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button {
          width: 72px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button::after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        // background: url(../../svg/i_arrow-fff.svg) center no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button .button-txt {
        position: absolute !important;
        overflow: hidden !important;
        clip: rect(0 0 0 0) !important;
        width: 1px !important;
        height: 1px !important;
        margin: -1px !important;
        padding: 0 !important;
        border: 0 !important;
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-prev {
        right: 38px;
        -webkit-transform: rotateZ(180deg);
                transform: rotateZ(180deg);
      }
      @media print, screen and (min-width: 768px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-prev {
          right: 72px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next {
        right: -12px;
      }
      @media print, screen and (min-width: 768px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next {
          right: 0px;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.button-next::before {
        content: "";
        display: block;
        width: 1px;
        height: 18px;
        background-color: rgba(255, 255, 255, 0.08);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button.swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
      [data-whatinput=keyboard] #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button:focus::after {
        -webkit-animation-name: arrowLinkRight;
                animation-name: arrowLinkRight;
        -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-animation-delay: 0;
                animation-delay: 0;
      }
      @media print, screen and (min-width: 1025px) {
        #u-instagram .instagram-wrap .instagram-inner .u-list-item .item-inner .swiper-button:hover::after {
          -webkit-animation-name: arrowLinkRight;
                  animation-name: arrowLinkRight;
          -webkit-animation-duration: 0.75s;
                  animation-duration: 0.75s;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          -webkit-animation-delay: 0;
                  animation-delay: 0;
        }
      }
      #u-instagram .instagram-wrap .instagram-inner .u-list-item.is-active .item-inner .item-swiper.swiper-container .swiper-pagination {
        display: block;
      }
      
      .u-box-picture {
        display: block;
        height: 100%;
        background-color: #f6f6f6;
      }
      .u-box-picture .picture-img {
        display: block;
        height: 100%;
      }
      .u-box-picture .picture-img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }
      
      .u-box-keyvisual .keyvisual-inner {
        position: relative;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image {
        height: calc(100vh - 80px);
        height: calc(var(--vh, 1vh) * 100 - 96px);
        min-height: 350px;
        position: relative;
        overflow: hidden;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-image {
          height: calc(100vh - 80px);
          height: calc(var(--vh, 1vh) * 100 - 80px);
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg {
        background: center no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1000px;
        right: 50%;
        overflow: hidden;
        -webkit-transform: skew(-45deg);
                transform: skew(-45deg);
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj {
        -webkit-transform: skew(45deg);
                transform: skew(45deg);
        -webkit-transform-origin: 25% center;
                transform-origin: 25% center;
        background: center no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -50vw;
        width: 100vw;
      }
      
      /*[lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
        background-image: url(../../images/top/p_keyvisual_design_3_en.jpg);
      }
      @media print, screen and (min-width: 768px) {
        [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
          background-image: url(../../images/top/p_keyvisual_design_3_pc_en.jpg);
        }
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
        background-image: url(../../images/top/p_keyvisual_design_3_en.jpg);
      }
      @media print, screen and (min-width: 768px) {
        [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {
          background-image: url(../../images/top/p_keyvisual_design_3_pc_en.jpg);
        }
      }*/
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter {
        position: absolute;
        top: -25vw;
        bottom: -25vw;
        left: -250vw;
        right: 50%;
        overflow: hidden;
        -webkit-transform: skew(-45deg);
                transform: skew(-45deg);
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter .filter-obj {
        // background-color: #018876;
        background-color: #222222;
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: 0;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: right center;
                transform-origin: right center;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .image-filter .filter-obj.rotate {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .img-bg {
        right: -1000px;
        left: 50%;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .img-bg .bg-obj {
        left: -50vw;
        -webkit-transform-origin: 75% center;
                transform-origin: 75% center;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .image-filter {
        top: -25vw;
        bottom: -25vw;
        right: -250vw;
        left: calc(50% - 1px);
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner.image-right .image-filter .filter-obj {
        // background-color: #222222;
        background-color: #018675;
        right: -100%;
        left: 0;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: left center;
                transform-origin: left center;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-chemistry-visible=true] .image-filter .filter-obj, .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-design-visible=true] .image-filter .filter-obj {
        -webkit-animation-name: keyvisual_open;
                animation-name: keyvisual_open;
        -webkit-animation-duration: 0.6s;
                animation-duration: 0.6s;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-direction: alternate;
                animation-direction: alternate;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        -webkit-animation-play-state: running;
                animation-play-state: running;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-chemistry-visible=false] .image-filter .filter-obj, .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner[data-keyvisual-design-visible=false] .image-filter .filter-obj {
        -webkit-animation-name: keyvisual_close;
                animation-name: keyvisual_close;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 0.9);
                animation-timing-function: cubic-bezier(0, 0, 0, 0.9);
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-direction: alternate;
                animation-direction: alternate;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        -webkit-animation-play-state: running;
                animation-play-state: running;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj[data-keyvisual-chemistry-zoom=true], .u-box-keyvisual .keyvisual-inner .keyvisual-image .image-inner .img-bg .bg-obj[data-keyvisual-design-zoom=true] {
        -webkit-animation-name: keyvisual_zoom;
                animation-name: keyvisual_zoom;
        -webkit-animation-duration: 6s;
                animation-duration: 6s;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-direction: alternate;
                animation-direction: alternate;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        -webkit-animation-play-state: running;
                animation-play-state: running;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt {
        position: absolute;
        top: 15px;
        bottom: 0;
        left: 0;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt {
          top: 0;
          bottom: 20px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
        // font-size: 3.05rem;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        font-family: "Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      [lang=ja] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
        letter-spacing: 0.05em;
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
        font-weight: 700;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-ttl {
          // font-size: 80px;
          letter-spacing: 0.1em;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        line-height: 1.25;
        margin-top: 9px;
        letter-spacing: 0.1em;
        padding-right: 25px;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub {
          padding-right: 43px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
        font-size: 1.7rem;
        color: #fff;
        font-weight: 500;
      }
      [lang=ja] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=en] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
        font-family: Noto Sans SC;
        letter-spacing: 0.05em;
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
        letter-spacing: 0.05em;
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
        font-weight: 700;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt {
          font-size: 4rem;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt.txt-right {
        margin-left: 13px;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .sub-txt.txt-right {
          margin-left: 40px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross {
        display: inline-block;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src {
        margin-left: 16px;
        margin-right: 16px;
        display: inline-block;
        width: 20px;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src {
          width: 36px;
          /* margin-left: 30px; */
          margin-right: 30px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-sub .ttl-cross .cross-src[data-keyvisual-cross=is-anim] {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        -webkit-transition: all 1s cubic-bezier(0.69, 0.01, 0.48, 0.99);
        transition: all 1s cubic-bezier(0.69, 0.01, 0.48, 0.99);
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 1.5;
        margin-top: 15px;
        letter-spacing: 0.1em;
        font-weight: 500;
        padding: 0 16px;
        font-family:"Noto Sans SC","microsoft yahei", "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
      [lang=cn] .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
        font-weight: 700;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-txt .txt-copy {
          font-size: 18px;
          margin-top: 23px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
        position: absolute;
        background: center no-repeat;
        background-size: 100% auto;
        bottom: 50px;
        right: 160px;
        border: none;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
      }
      // .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn:before {
      //   content: "";
      //   position: absolute;
      //   top: -22px;
      //   bottom: -22px;
      //   left: -22px;
      //   right: -22px;
      //   width: 44px;
      //   height: 44px;
      //   display: block;
      //   margin: auto;
      // }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
          width: 36px;
          height: 36px;
          bottom: 96px;
          right: 229px;
          -webkit-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
        }
      }
      // .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn .btn-txt {
      //   position: absolute !important;
      //   overflow: hidden !important;
      //   clip: rect(0 0 0 0) !important;
      //   width: 1px !important;
      //   height: 1px !important;
      //   margin: -1px !important;
      //   padding: 0 !important;
      //   border: 0 !important;
      // }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn img{
        width: 100%;
        height: 100%;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=start] .img1 {
        // background-image: url(../../svg/i_start_white_on.svg);
        display: block;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=start] .img2{
        display: none;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=stop] .img1{
        display: none;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn[data-keyvisual-stop_start=stop] .img2 {
        // background-image: url(../../svg/i_stop_white.svg);
        display: block;
      }

      [data-whatinput=keyboard] .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn:focus[data-keyvisual-stop_start=start] {
        // background-image: url(../../svg/i_start_white.svg);
      }
      [data-whatinput=keyboard] .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn:focus[data-keyvisual-stop_start=stop] {
        // background-image: url(../../svg/i_stop_white_on.svg);
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn:hover[data-keyvisual-stop_start=start] {
          // background-image: url(../../svg/i_start_white.svg);
        }
        .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn:hover[data-keyvisual-stop_start=stop] {
          // background-image: url(../../svg/i_stop_white_on.svg);
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
        position: absolute;
        overflow: hidden;
        display: block;
        bottom: 20px;
        /* left: 20px; */
        width: 90px;
        right: 40px;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
          bottom: 50px;
          right: 50px;
          left: auto;
          width: 130px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link::before {
        content: "";
        display: block;
        width: 17px;
        height: 16px;
        // background: url(images/icon/b.png) center no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-transform: translateY(0) rotateZ(90deg);
                transform: translateY(0) rotateZ(90deg);
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link::before {
          width: 24px;
          height: 22px;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link .link-circle {
        -webkit-animation-name: scrolldown_circle;
                animation-name: scrolldown_circle;
        -webkit-animation-duration: 14s;
                animation-duration: 14s;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-fill-mode: none;
                animation-fill-mode: none;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link .link-circle2{
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -28px;
        margin-left: -25px;
        width: 50px;
        height: 50px;
      }
      [data-whatinput=keyboard] .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link:focus::before {
        -webkit-animation-name: arrowLinkBottom;
                animation-name: arrowLinkBottom;
        -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-animation-delay: 0;
                animation-delay: 0;
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link:hover::before {
          -webkit-animation-name: arrowLinkBottom;
                  animation-name: arrowLinkBottom;
          -webkit-animation-duration: 0.75s;
                  animation-duration: 0.75s;
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          -webkit-animation-delay: 0;
                  animation-delay: 0;
        }
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link[data-keyvisual-scroll-anim=true] .link-circle {
        -webkit-animation-play-state: running;
                animation-play-state: running;
      }
      .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link[data-keyvisual-scroll-anim=false] .link-circle {
        -webkit-animation-play-state: paused;
                animation-play-state: paused;
      }
      .u-box-keyvisual .keyvisual-important .important-inner {
        padding: 30px 16px;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-important .important-inner {
          max-width: 1460px;
          margin: 0 auto 0;
          padding: 0 30px;
          -webkit-box-sizing: content-box;
                  box-sizing: content-box;
        }
      }
      .u-box-keyvisual .keyvisual-important .important-inner .important-wrap {
        position: relative;
        background-color: #fff;
      }
      .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child {
        margin-top: 0 !important;
      }
      .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child.m-box-section {
        padding-top: 0;
      }
      .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > *:first-child.m-list-desc.desc-inlineblock {
        margin-top: -10px !important;
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > .position-left:first-child + *,
      .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > .position-right:first-child + * {
          margin-top: 0 !important;
        }
      }
      @media screen and (max-width: 767px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child [data-gs-col]:first-child > *:first-child {
          margin-top: 0 !important;
        }
        .u-box-keyvisual .keyvisual-inner .keyvisual-scroll .scroll-link {
          right: 20px;
        }
        .u-box-keyvisual .keyvisual-inner .keyvisual-start_stop .start_stop-btn {
          right: 130px;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="4"]:nth-child(-n+3) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > [data-gs]:first-child > [data-gs-col="12"]:nth-child(-n+1) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs]:first-child > [data-gs-col="3"]:nth-child(-n+4) > [data-gs]:first-child > [data-gs-col="6"]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child > [data-gs-col] > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child[data-gs-tab="1"] > [data-gs-col]:first-child > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap > [data-gs-group]:first-child[data-gs-tab="2"] > [data-gs-col]:nth-child(-n+2) > *:first-child {
          margin-top: 0 !important;
        }
      }
      @media print, screen and (min-width: 768px) {
        .u-box-keyvisual .keyvisual-important .important-inner .important-wrap {
          margin-top: -110px;
          background-color: #8F001F;
          color: white;
          width: 220px;
          height: 220px;
          padding: 10px 50px;
        }
      }
      
      @-webkit-keyframes keyvisual_zoom {
        0% {
          -webkit-transform: skew(45deg);
                  transform: skew(45deg);
        }
        100% {
          -webkit-transform: scale(1.2) skew(45deg);
                  transform: scale(1.2) skew(45deg);
        }
      }
      
      @keyframes keyvisual_zoom {
        0% {
          -webkit-transform: skew(45deg);
                  transform: skew(45deg);
        }
        100% {
          -webkit-transform: scale(1.2) skew(45deg);
                  transform: scale(1.2) skew(45deg);
        }
      }
      @-webkit-keyframes keyvisual_open {
        0% {
          -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg);
        }
        100% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
      }
      @keyframes keyvisual_open {
        0% {
          -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg);
        }
        100% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
      }
      @-webkit-keyframes keyvisual_close {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
        }
      }
      @keyframes keyvisual_close {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
        }
      }
      @-webkit-keyframes scrolldown_circle {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(-360deg);
                  transform: rotate(-360deg);
        }
      }
      @keyframes scrolldown_circle {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
        }
        100% {
          -webkit-transform: rotate(-360deg);
                  transform: rotate(-360deg);
        }
      }
      .instaPost .postList {
        margin: 0 -1.5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
      }
      @media print {
        .instaPost .postList {
          display: block;
          letter-spacing: -0.4em;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .instaPost .postList {
          margin: 0 -2px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
        }
      }
      @media print {
        .instaPost .postList {
          display: block;
          letter-spacing: -0.4em;
        }
      }
      .instaPost .postList .listItem {
        width: calc(100% / 3);
        width: calc((100% / 3) - 0.1px) \0 ;
        padding: 0 1.5px;
      }
      @media print {
        .instaPost .postList .listItem {
          width: 33.3333333333%;
          display: inline-block;
          padding: 0 1.5px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          vertical-align: top;
          letter-spacing: normal;
        }
      }
      @media print, screen and (min-width: 1025px) {
        .instaPost .postList .listItem {
          width: calc(100% / 4);
          width: calc((100% / 4) - 0.1px) \0 ;
          padding: 0 2px;
        }
      }
      @media print {
        .instaPost .postList .listItem {
          width: 25%;
          display: inline-block;
          padding: 0 2px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          vertical-align: top;
          letter-spacing: normal;
        }
      }
      .instaPost .postList .listItem .itemInner {
        display: block;
      }
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-chemistry-image="1"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_611111111.jpg);}
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="1"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_311.jpg);}
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-chemistry-image="2"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_2111111.jpg);}
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="2"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_11111.jpg);}
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-chemistry-image="3"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_41111111111.jpg);}
    // .keyvisual-image .image-inner .img-bg[data-keyvisual-design-image="3"] .bg-obj {background-image: url(https://www.aica-wilsonart.com.cn/uploads/banner/banner_511.jpg);}

  .num_Science_bg {

    // background-color: #cccccc;

    width: 100%;

    height: 750px;

    position: relative;
    background-color: #f5f5f5;

  }

  .num_text_content {

    width: 100%;

    margin: auto;

    // padding-top: 50px;

    position: relative;

  }

  .text_width {
    width: 95%;
    max-width: 1320px;

    margin: auto;

  }

  .num_Science_img_list {

    width: 100%;

    height: 650px;

    position: absolute;

    // top: 100px;

  }

  .lbt-box {

    position: relative;

    max-width: 1090px;
    width: 80%;
    height: 100%;

    margin: auto;

  }



  .imgs {

    width: 100%;

    height: 100%;

    perspective: 1000px;

    margin-bottom: 30px;

  }



  .imgitem {

    position: absolute;

    height: 650px;

    top: 0;

    bottom: 0;

    left: 0;

    right: 0;

    margin: auto;

    z-index: 0;


    opacity: 0;
    .making {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 40px;

      @include xl-desktop {
          padding: 30px;
      }

      @include large-desktop {
          padding: 20px;
      }

      @include desktop {
          padding: 10px;
      }

      .making-box {
          max-width: 500px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 15px;
          padding: 50px;

          @include xl-desktop {
              border-radius: 15px;
              padding: 50px;
          }

          @include large-desktop {
              border-radius: 10px;
              padding: 25px;
          }

          @include desktop {
              border-radius: 5px;
              padding: 10px;
          }

          .title {
              font-size: 24px;
              color: #fff;
              padding-bottom: 20px;

              // @include xl-desktop {
              //     font-size: 30px;
              //     padding-bottom: 15px;
              // }

              @include large-desktop {
                  font-size: 20px;
                  padding-bottom: 10px;
              }

              @include desktop {
                  font-size: 18px;
                  padding-bottom: 5px;
              }
          }

          .subtitle {
              font-size: 18px;
              color: #fff;
              line-height: 30px;
              opacity: 0.8;

              // @include xl-desktop {
              //     font-size: 18px;
              //     line-height: 30px;
              // }

              @include large-desktop {
                  font-size: 16px;
                  line-height: 24px;
              }

              @include desktop {
                  font-size: 14px;
                  line-height: 20px;
              }
          }

          .btn-all {
              // margin-top: 48px;
              // width: 120px;
              // height: 36px;
              // border-radius: 20px;
              background-color: $color-primary;
              // font-size: 16px;
              color: #fff;
              // line-height: 40px;
              text-align: center;
              cursor: pointer;
              margin-top: 40px;
              width: 100px;
              height: 36px;
              border-radius: 18px;
              line-height: 36px;
              font-size: 16px;

              // @include xl-desktop {
              //     margin-top: 40px;
              //     width: 100px;
              //     height: 36px;
              //     border-radius: 18px;
              //     line-height: 36px;
              //     font-size: 16px;
              // }

              @include large-desktop {
                  margin-top: 30px;
                  width: 90px;
                  height: 30px;
                  border-radius: 15px;
                  line-height: 30px;
                  font-size: 14px;
              }

              @include desktop {
                  margin-top: 30px;
                  width: 80px;
                  height: 30px;
                  border-radius: 15px;
                  line-height: 30px;
                  font-size: 12px;
              }

              &:hover {
                  background-color: #fff;
                  color: $color-primary;
              }
          }
      }
  }

  }



  .imgitem img {

    width: 100%;

    height: 100%;

    object-fit: cover;

  }



  .cur-img {

    z-index: 100;

    opacity: 1;

    transition: 1.2s;

  }



  .pre-img {

    transform: translateX(-294px) scale(1);

    z-index: 9;

    opacity: 0.9;

    transition: 0.8s;

  }



  .next-img {

    transform: translateX(293px) scale(1);

    z-index: 9;

    opacity: 0.9;

    transition: 0.8s;

  }

  #pre {

    display: inline-block;

    cursor: pointer;

  }

  #next {

    display: inline-block;

    cursor: pointer;

  }

  .number_sort {

    display: flex;

    justify-content: space-between;

    max-width: 840px;
    width: 60%;
    position: absolute;

    bottom: -40px;

  }
  .prduce_right_list_bg {
    max-width: 431px;
    width: 40% !important;
    height: 384px;
    position: absolute;
    right: -38px;
    background-color: white;
    z-index: 4;
    top: 248px;
    padding: 49px 56px 0;
    line-height: 1;
  }
  .prduce_right_list_bg .learn-more {
    text-align: center;
    width: 100%;
    height: 90px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
  }
  .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h2 {
    margin: 30px 0 20px 0;
  }
  .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8 {
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 25px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  .page_list {

    display: flex;

    text-align: center;

  }

  .page_list div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 2px;
    background-color: #ccc;
    margin-right: 4px;
    display: inline-block;

    // max-width: 33px;
    // width: 4vw;
    // max-height: 33px;
    // height: 4vw;
    // border: 1px solid #4d4d4d;

    // border-radius: 50%;

    // margin-right: 1vw;

    // font-size: 13px;

    cursor: pointer;

  }

  .page_list .active {

    border: 1px solid #018876;

    color: #a31326;

  }
  .btn_play {
    max-width: 33px;
    width: 4vw;
    max-height: 33px;
    height: 4vw;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .produce_list_text_p {
      display: flex;
    }
  }
  @media (max-width: 1500px){
    
  }
  @media (max-width: 1300px){

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8{
      letter-spacing: 2px !important;
    }
  }
  @media (max-width: 1150px){

  }
  @media (max-width: 768px) {
    .num_Science_bg {
      height: 500px;
      height: auto !important;
    }

    .num_Science_bg .num_text_content {
      padding-top: 20px;
      padding-bottom: 40px;
    }

    .num_Science_bg .num_text_content .text_width {
      width: 100%;
      padding: 0 5%;
    }

    .num_Science_bg .num_text_content .num_Science_img_list {
      height: 226px;
      position: static;
    }

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box {
      width: 100%;
    }
    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .imgs {
      overflow: hidden;
    }
    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .imgs .imgitem {
      width: 100%;
      height: 210px;
    }

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg {
      width: 46% !important;
      right: 5% !important;
      height: 230px !important;
      top: 70px !important;
      padding: 16px 5% 0 !important;
      overflow: hidden;
      line-height: normal;
    }

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg p {
      font-size: 12px !important;
    }

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h2 {
      font-size: 16px !important;
      letter-spacing: 2px !important;
      margin: 10px 0 !important;
    }

    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h3 {
      font-size: 12px !important;
      letter-spacing: 2px !important;
      line-height: 16px !important;
    }
    .num_Science_bg .num_text_content .num_Science_img_list .lbt-box .prduce_right_list_bg h8 {
      font-size: 12px !important;
      line-height: 18px !important;
      -webkit-line-clamp: 4;
    }

    .learn-more {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      height: 80px !important;
    }

    .learn-more div {
      width: 90px !important;
      height: 28px !important;
      line-height: 26px !important;
      border-width: 1px !important;
      font-size: 12px !important;

    }

    .number_sort {
      z-index: 2;
      bottom: -20px;
      width: 100%;
    }

    .number_sort .page_list {
      display: inline-block;
      text-align: left;
      width: 90%;
      margin: 0 5%;
    }

    .number_sort .page_list div {
      width: 20px;
      height: 2px;
      background-color: #ccc;
      margin-right: 4px;
      display: inline-block;
      // width: 24px;
      // height: 24px;
      // border: 1px solid #4d4d4d;
      // border-radius: 50%;
      // display: inline-block;
      // line-height: 22px;
      // margin-right: 8px;
      // font-size: 12px;
      // cursor: pointer;
      // text-align: center;
    }

    .number_sort .arrow {
      display: none !important;
    }
  }
}