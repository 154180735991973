.connection {
    .connection {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 100px 15px;

        // @include xl-desktop {
        //     padding: 100px 15px;
        // }

        @include large-desktop {
            padding: 50px 15px;
        }

        @include desktop {
            padding: 15px;
        }

        .connection-box {
            border-radius: 20px;
            background-color: rgba(255, 255, 255, 0.8);

            .left {
                padding: 40px 60px;

                // @include xl-desktop {
                //     padding: 40px 60px;
                // }

                @include large-desktop {
                    padding: 30px;
                }

                @include desktop {
                    padding: 15px 0px;
                }

                .title {
                    font-size: 24px;
                    color: #000;
                    font-weight: bold;

                    // @include xl-desktop {
                    //     font-size: 30px;
                    // }

                    @include large-desktop {
                        font-size: 20px;
                    }

                    @include desktop {
                        font-size: 18px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #000;
                    padding: 15px 0;
                    font-weight: bold;

                    // @include xl-desktop {
                    //     font-size: 20px;
                    //     padding: 15px 0;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding: 10px 0;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding: 10px 0;
                    }
                }

                .ul {
                    padding-bottom: 20px;

                    // @include xl-desktop {
                    //     padding-bottom: 20px;
                    // }

                    @include large-desktop {
                        padding-bottom: 15px;
                    }

                    @include desktop {
                        padding-bottom: 10px;
                    }

                    .li {
                        font-size: 16px;
                        color: $color-primary;
                        padding: 5px 0;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }

                        span {
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: $color-primary;
                            display: inline-block;
                            margin-right: 10px;
                            position: relative;
                            top: -4px;
                        }
                    }
                }

                .ul-box {
                    padding-top: 0px;

                    // @include xl-desktop {
                    //     padding-top: 20px;
                    // }

                    @include large-desktop {
                        // padding-top: 10px;
                    }

                    @include desktop {
                        // padding-top: 0px;
                    }

                    .li-title {
                        font-size: 16px;
                        color: #000;
                        padding: 5px 0;
                        font-weight: bold;

                        // @include xl-desktop {
                        //     font-size: 20px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }
                    }

                    .li {
                        font-size: 14px;
                        color: #828282;
                        padding: 5px 0;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }

                        span {
                            display: inline-block;
                            width: 10px;
                        }
                    }
                }
            }

            .right {
                padding: 40px 60px;

                // @include xl-desktop {
                //     padding: 40px 60px;
                // }

                @include large-desktop {
                    padding: 30px;
                }

                @include desktop {
                    padding: 0;
                }

                .title {
                    font-size: 24px;
                    color: #000;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 30px;
                    // }

                    @include large-desktop {
                        font-size: 20px;
                    }

                    @include desktop {
                        font-size: 18px;
                    }
                }

                .form {
                    margin: 10px -5px;

                    // @include xl-desktop {
                    //     margin: 10px -5px;
                    // }

                    @include large-desktop {
                        margin: 5px 0;
                    }

                }

                .el-input {
                    height: 100%;

                    .el-input__inner {
                        height: 100%;
                        font-size: 16px;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 14px;
                        }
                    }
                }



                .el-select {
                    height: 100%;

                    .el-input__icon {
                        color: $color-primary;
                        font-size: 18px;
                        line-height: 40px;

                        // @include xl-desktop {
                        //     font-size: 24px;
                        //     line-height: 40px;
                        // }

                        @include large-desktop {
                            font-size: 16px;
                            line-height: 30px;
                        }

                        @include desktop {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }


                .sl-form-item-box {
                    height: 40px;
                    margin: 0 10px;
                    flex: 1;
                    border-radius: 10px;
                    background-color: #fff;
                    border: 1px solid #999;

                    // @include xl-desktop {
                    //     height: 40px;
                    // }

                    @include large-desktop {
                        height: 32px;
                        border-radius: 5px;
                        margin: 0 5px;
                    }

                    @include desktop {
                        height: 30px;
                    }

                    .icon {
                        width: 33px;
                        margin-right: 10px;
                    }
                }

                .sl-form-item-textarea {
                    border-radius: 10px;
                    border: 1px solid #999;
                    overflow: hidden;
                    background-color: #fff;
                    padding: 10px;

                    .el-textarea__inner {
                        background-color: rgba(0, 0, 0, 0);
                        border: none;
                        font-size: 16px;
                        color: #333;
                        height: 500px;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        //     height: 500px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                            height: 400px;
                        }

                        @include desktop {
                            font-size: 14px;
                            height: 300px;
                        }
                    }
                }

                .btn-box {
                    width: 150px;
                    height: 50px;
                    border-radius: 25px;
                    background-color: $color-primary;
                    text-align: center;
                    line-height: 50px;
                    color: #fff;
                    font-size: 16px;
                    margin: 30px auto;
                    cursor: pointer;
                    transition: all 0.3s ease;

                    // @include xl-desktop {
                    //     width: 250px;
                    //     height: 60px;
                    //     border-radius: 30px;
                    //     line-height: 60px;
                    //     font-size: 20px;
                    //     margin: 40px auto;
                    // }

                    @include large-desktop {
                        width: 150px;
                        height: 40px;
                        border-radius: 20px;
                        line-height: 40px;
                        font-size: 16px;
                        margin: 30px auto;
                    }

                    @include desktop {
                        width: 120px;
                        height: 30px;
                        border-radius: 15px;
                        line-height: 30px;
                        font-size: 14px;
                        margin: 20px auto;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

}