.technology {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 20px;
                    color: #fff;
                    text-align: center;
                    padding-top: 15px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .advantage {
        padding: 30px 0 70px 0;

        // @include xl-desktop {
        //     padding: 30px 0 70px 0;
        // }

        @include large-desktop {
            padding: 20px 0 50px 0;
        }

        @include desktop {
            padding: 10px 0 30px 0;
        }

        .tit {
            max-width: 1200px;
            margin: 40px auto;

            // @include xl-desktop {
            //     margin: 70px auto 40px auto;
            // }

            @include large-desktop {
                margin:30px auto;
            }

            @include desktop {
                margin: 0 auto;
            }

            .tit-title {
                font-size: 26px;
                color: #000;
                font-weight: bold;
                text-align: center;

                // @include xl-desktop {
                //     font-size: 32px;
                // }

                @include large-desktop {
                    font-size: 20px;
                }

                @include desktop {
                    font-size: 18px;
                }
            }

            .tit-desc {
                padding-top: 15px;

                p {
                    font-size: 18px;
                    color: #828282;
                    text-align: center;
                    max-width: 1150px;
                }

                // @include xl-desktop {
                //     padding-top: 15px;

                //     p {
                //         font-size: 20px;
                //     }
                // }

                @include large-desktop {
                    padding-top: 10px;

                    p {
                        font-size: 16px;
                    }
                }

                @include desktop {
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .sl-row {
            padding-top: 15px;

            @include xl-desktop {
                padding-top: 0;
            }

            .sl-item {
                margin: 15px 0;
                background-color: #f2f2f2;
                border-radius: 10px;
                overflow: hidden;

                .right {
                    flex: 1;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .left {
                    padding: 40px 50px;

                    // @include xl-desktop {
                    //     padding: 40px 50px;
                    // }

                    @include large-desktop {
                        padding: 30px 40px;
                    }

                    @include desktop {
                        padding: 15px;
                    }

                    .title {
                        font-size: 20px;
                        color: $color-primary;
                        font-weight: bold;

                        // @include xl-desktop {
                        //     font-size: 26px;
                        // }

                        @include large-desktop {
                            font-size: 18px;
                        }

                        @include desktop {
                            font-size: 16px;
                        }
                    }

                    .text {
                        height: 110px;
                        padding-top: 20px;

                        p {
                            font-size: 16px;
                            color: #666;
                            opacity: 0.8;
                            text-align: justify;
                        }

                        // @include xl-desktop {
                        //     padding-top: 20px;
                        //     // height: auto;
                        //     height: 150px;
                        //     p {
                        //         font-size: 16px;
                        //     }
                        // }

                        @include large-desktop {
                            padding-top: 10px;
                            height: 100px;
                            p {
                                font-size: 14px;
                            }
                        }

                    }

                    .btn-all {
                        font-size: 14px;
                        color: $color-primary;
                        cursor: pointer;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                            padding-top: 10px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }


    }






}