.sl-comp-header {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
    padding: 30px 100px;
	z-index: 10;
	background-color: #fff;

    @include xl-desktop {
        padding: 10px 0;
    }

    .sl-header-logo {
        height: 50px;
    }

    .sl-header-menu {
        .el-dropdown-link {
            .el-icon-arrow-down {
                color: $color-primary;
            }
        }

        .sl-header-menu-li {
            margin-left: 15px;

            @include large-desktop {
                margin-left: 5px;
            }

            img {
                display: block;
            }
        }

        .navs {
            // padding-top: 30px;
            padding-top: 15px;

            .nav {
                margin-right: 32px;

                .nav-item {
                    font-size: 14px;
                    color: #000;
                    height: 40px;
                    line-height: 40px;
                    position: relative;
                    margin-left: 20px;
                    cursor: pointer;
                    font-family: SourceHanSansCN-Medium;

                    &:hover {
                        color: $color-primary;
                    }

                    &.active {
                        color: $color-primary;
                    }

                    span {
                        height: 2px;
                        width: 100%;
                        background-color: $color-primary;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: all 0.3s ease-in-out;
                        display: none;
                    }

                    &.active span {
                        display: block;
                    }
                    &:hover span {
                        display: block;
                    }

                    .nav-sub {
                        position: absolute;
                        top: 38px;
                        left: 50%;
                        transform: translateX(-50%);
                        
                        
                        z-index: 100;
                        
                        display: none;
                        transition: all 0.3s ease-in-out;
                        padding-top: 30px;
                        .nav-sub2{
                            background-color: #fff;
                            border-radius: 10px;
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                            overflow: hidden;
                        }

                        .goods-nav-sub-item {
                            .type {
                                padding: 30px 12px;
                                border-bottom: 2px solid #d3d4d4;

                                .name {
                                    flex: 1;
                                    margin: 0 12px;
                                    height: 40px;
                                    border-radius: 20px;
                                    background-color: #f7f7f7;
                                    font-size: 16px;
                                    color: #727171;
                                    line-height: 40px;
                                    text-align: center;
                                    width: 240px;

                                    &.active {
                                        background-color: $color-primary;
                                        color: #fff;
                                    }
                                    &.hover {
                                        background-color: $color-primary;
                                        color: #fff;
                                    }
                                }


                            }

                            .goods-list {
                                padding: 20px;

                                .left, .right {
                                    flex: 1;

                                    .goods-li {
                                        height: 34px;
                                        font-size: 14px;
                                        color: #727171;
                                        line-height: 30px;
                                        padding: 0 15px;
                                        margin: 5px 0;

                                        &:hover {
                                            background-color: #f7f7f7;
                                            color: $color-primary;

                                        }
										&.active {
										    background-color: #f7f7f7;
										    color: $color-primary;
										
										}
                                    }


                                }

                                .right {
                                    flex: 1;
                                }
                            }
                        }

                        .nav-sub-item {
                            height: 30px;
                            font-size: 14px;
                            color: #727171;
                            line-height: 30px;
                            padding: 0 15px;
                            margin: 5px 0;
                            width: 130px;
							text-align: center;

                            &:hover {
                                background-color: #f7f7f7;
                                color: $color-primary;

                            }
                        }
                    }

                    &:hover .nav-sub {
                        display: block;
                    }
                }
            }

            .sl-header-search {
                width: 250px;
                height: 30px;
                border-radius: 15px;
                position: relative;
                border: 1px solid $color-primary;

                // margin-right: 30px;
                @include xl-desktop {
                    width: 180px;
                }

                .icon {
                    width: 18px;
                    margin: 0 10px;
                }

                input {
                    flex: 1;
                    width: calc(100% - 30px);
                    height: 22px;
                    border-right: 1px solid $color-primary !important;
                    padding-left: 24px;
                    font-size: 14px;

                }
            }

            .all {
                margin-left: 20px;
                width: 30px;
                cursor: pointer;
            }
        }


    }

}

.sl-comp-header-mini {
    height: 40px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;

    .left {
        height: 100%;

        .sl-header-logo {
            height: 24px;
        }
    }

    .right {
        height: 40px;

        .all {
            width: 28px;
        }
    }
}

.sl-comp-header-popup{
    
    .popup-moake{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
        display: none;
        transition: all 0.3s ease-in-out;
    }
    .popup-content{
        position: fixed;
        top: 0;
        left:0;
        bottom: 0;
        transition: all 0.3s ease-in-out;
        transform: translate(-100%, -0);
        background-color: #fff;
        padding:0 20px 20px 20px;
        width:80%;
        z-index: 101;
        .popup-title{
            padding: 15px 0;
            .close{
                width: 18px;
                height: 18px;
            }
        }
        .popup-menu{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-y:scroll; 
            overflow-x:hidden;
            .el-collapse-item{
                .el-collapse-item__arrow{
                    display: none;
                }
            }
            .sub_menu{
                .el-collapse-item__arrow{
                    display: block;
                }
            }
            .el-collapse-item__content{
                padding-bottom: 0;
            }
            .popup-menu-item{
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                color: #333;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                padding-left: 15px;
                border-bottom: 1px solid #eee;
            }
            &::-webkit-scrollbar{
                width: 0px;
            }
        }
    }
    &.showPopup{
        .popup-moake{
            display: block;
        }
        .popup-content{
            transform: translate(0, 0);
        }
    }

    
}

.float{
    position: fixed;
    right: 2%;
    bottom: 5%;
    z-index: 100;
    .float-item{
        width: 50px;
        height: 50px;
        margin-left: 15px;
        cursor: pointer;
    }
}