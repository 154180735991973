.case {
    .ad {
        .sl-carousel {
            position: relative;

            .img {
                margin: 0 auto;
                display: block;
                width: 100%;
            }

            .making {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .title {
                    font-size: 36px;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;

                    // @include xl-desktop {
                    //     font-size: 50px;
                    // }

                    @include large-desktop {
                        font-size: 26px;
                    }

                    @include desktop {
                        font-size: 20px;
                    }
                }

                .subtitle {
                    font-size: 18px;
                    color: #fff;
                    text-align: center;
                    padding-top: 15px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     padding-top: 15px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        padding-top: 10px;
                    }

                    @include desktop {
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .case-swiper {
        padding: 40px 70px;

        // @include xl-desktop {
        //     padding: 40px 70px;
        // }

        @include large-desktop {
            padding: 30px 50px;
        }

        @include desktop {
            padding: 15px 30px;
        }

        .title {
            font-size: 28px;
            color: #000;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;

            // @include xl-desktop {
            //     font-size: 36px;
            //     margin-bottom: 36px;
            // }

            @include large-desktop {
                font-size: 24px;
                margin-bottom: 20px;
            }

            @include desktop {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }

        .swiper-box {
            width: 100%;
            height: 430px;
            padding: 0 50px;
            position: relative;

            // @include xl-desktop {
            //     height: 500px;
            //     padding: 0 100px;
            // }
            // @include x6-desktop {
            //     height: 500px;
            //     padding: 0 100px;
            // }

            @include large-desktop {
                height: 370px;
                padding: 0 70px;
            }

            @include desktop {
                height: 300px;
                padding: 0 50px;
            }

            .homeSwiper1 {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide {
                    height: 100%;

                    .item {
                        height: 100%;
                        padding: 10px;

                        .cont {
                            height: calc(100% - 20px);
                            cursor: pointer;
                            background-color: #fff;
                            border-radius: 10px;
                            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

                            .span {
                                padding:15px;

                                // @include xl-desktop {
                                //     padding: 20px 15px;
                                // }

                                @include large-desktop {
                                    padding: 10px 10px;
                                }

                                .logo {
                                    height: 170px;
                                    display: block;
                                    margin: 0 auto;
									background-position: center;
									background-repeat: no-repeat;
									background-size: cover;

                                    // @include xl-desktop {
                                    //     height: 170px;
                                    // }

                                    @include large-desktop {
                                        height: 150px;
                                    }

                                    @include desktop {
                                        height: 120px;
                                    }
                                }

                                .ul {
                                    padding: 10px 0;
                                    // height: 60px;

                                    // @include xl-desktop {
                                    //     padding: 30px 0 20px 0;
                                    //     // height: 50px;
                                    // }

                                    @include large-desktop {
                                        padding: 15px;
                                        // height: 40px;
                                    }

                                    @include desktop {
                                        padding: 10px;
                                        // height: 30px;
                                    }

                                    .li {
                                        font-size: 16px;
                                        color: #828282;

                                        span {
                                            display: inline-block;
                                            width: 10px;
                                            height: 10px;
                                            border-radius: 50%;
                                            margin-right: 10px;
                                            background-color: $color-primary;
                                        }

                                        // @include xl-desktop {
                                        //     font-size: 18px;

                                        //     span {
                                        //         width: 10px;
                                        //         height: 10px;
                                        //         margin-right: 10px;
                                        //     }
                                        // }

                                        @include large-desktop {
                                            font-size: 16px;

                                            span {
                                                width: 8px;
                                                height: 8px;
                                                margin-right: 10px;
                                            }
                                        }

                                        @include desktop {
                                            font-size: 14px;

                                            span {
                                                width: 8px;
                                                height: 8px;
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }

                                .desc {
                                    font-size: 14px;
                                    color: #828282;
                                    -webkit-line-clamp: 3;

                                    // @include xl-desktop {
                                    //     font-size: 18px;
                                    // }
                                    // @include x6-desktop {
                                    //     -webkit-line-clamp: 3;
                                    // }

                                    @include large-desktop {
                                        font-size: 14px;
                                        -webkit-line-clamp: 2;
                                    }

                                    @include desktop {
                                        font-size: 14px;
                                        -webkit-line-clamp: 1;
                                    }
                                }

                                .btn-all {
                                    margin-top: 40px;
                                    width: 80px;
                                    height: 30px;
                                    border-radius: 15px;
                                    background-color: $color-primary;
                                    color: #fff;
                                    font-size: 12px;
                                    text-align: center;
                                    line-height: 30px;
                                    cursor: pointer;

                                    // @include xl-desktop {
                                    //     margin-top: 40px;
                                    //     width: 100px;
                                    //     height: 36px;
                                    //     border-radius: 18px;
                                    //     font-size: 16px;
                                    //     line-height: 36px;
                                    // }
                                    // @include x6-desktop {
                                    //     margin-top: 30px;
                                    // }

                                    @include large-desktop {
                                        margin-top: 30px;
                                        width: 80px;
                                        height: 30px;
                                        border-radius: 15px;
                                        font-size: 14px;
                                        line-height: 30px;
                                    }

                                    @include desktop {
                                        margin-top: 20px;
                                        width: 80px;
                                        height: 26px;
                                        border-radius: 13px;
                                        font-size: 12px;
                                        line-height: 26px;
                                    }

                                }
                            }

                        }
                    }



                }
            }
        }

        .homeSwiper1-button-prev {
            width: 50px;
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            @include xl-desktop {
                width: 40px;
                left: 0px;
            }

            @include large-desktop {
                width: 30px;
                left: 0px;
            }

            @include desktop {
                width: 20px;
                left: 0px;
            }
        }

        .homeSwiper1-button-next {
            width: 50px;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            @include xl-desktop {
                width: 40px;
                right: 0px;
            }

            @include large-desktop {
                width: 30px;
                right: 0px;
            }

            @include desktop {
                width: 20px;
                right: 0px;
            }
        }
    }

    .case-type {
        padding: 40px 0;

        // @include xl-desktop {
        //     padding: 40px 0;
        // }

        @include large-desktop {
            padding: 20px 0;
        }

        @include desktop {
            padding: 0;
        }

        .title {
            font-size: 28px;
            color: #000;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;

            // @include xl-desktop {
            //     font-size: 40px;
            //     margin-bottom: 40px;
            // }

            @include large-desktop {
                font-size: 24px;
                margin-bottom: 20px;
            }

            @include desktop {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }

        .sl-row {
            padding-bottom: 40px;
            border-bottom: 2px solid $color-primary;

            // @include xl-desktop {
            //     padding-bottom: 90px;
            // }

            @include large-desktop {
                padding-bottom: 30px;
            }

            @include desktop {
                padding-bottom: 20px;
            }

            .sl-item {
                min-width: 200px;
                padding: 0 15px;
                height: 40px;
                border-radius: 20px;
                border: 1px solid $color-primary;
                font-size: 16px;
                color: $color-primary;
                line-height: 40px;
                text-align: center;
                margin:20px 8px;
                cursor: pointer;

                // @include xl-desktop {
                //     min-width: 250px;
                //     padding: 0 20px;
                //     height: 50px;
                //     border-radius: 25px;
                //     font-size: 20px;
                //     line-height: 50px;
                // }

                @include large-desktop {
                    min-width: 150px;
                    padding: 0 15px;
                    height: 40px;
                    border-radius: 20px;
                    font-size: 14px;
                    line-height: 40px;
                    margin:10px 8px;
                }

                @include desktop {
                    min-width: 100px;
                    padding: 0 10px;
                    height: 30px;
                    border-radius: 15px;
                    font-size: 14px;
                    line-height: 30px;
                    margin:5px;
                }

                // &:hover {
                //     background-color: $color-primary;
                //     color: #fff;
                // }
            }
        }
    }

    .example {
        // padding: 20px 0;

        // @include xl-desktop {
        //     padding: 30px 0;
        // }

        @include large-desktop {
            // padding: 20px 0;
        }

        @include desktop {
            // padding: 15px 0;
        }

        .left {
            .sl-form-item-box {
                height: 36px;
                border-radius: 6px;
                border: 1px solid #999;
                margin-right: 10px;

                .icon {
                    width: 26px;
                    margin-right: 10px;
                }

                // @include xl-desktop {
                //     height: 48px;
                //     border-radius: 5px;

                //     .icon {
                //         width: 28px;
                //     }
                // }

                @include large-desktop {
                    height: 30px;

                    .icon {
                        width: 24px;
                    }
                }

                @include desktop {
                    height: 30px;

                    .icon {
                        width: 20px;
                    }
                }
            }

            .el-input {
                height: 100%;

                .el-input__inner {
                    height: 100%;
                    font-size: 16px;

                    // @include xl-desktop {
                    //     font-size: 18px;
                    // }

                    @include large-desktop {
                        font-size: 14px;
                    }

                    @include desktop {
                        font-size: 12px;
                    }
                }
            }



            .el-select {
                height: 100%;

                .el-input__icon {
                    color: $color-primary;
                    font-size: 20px;
                    line-height: 30px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     line-height: 40px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                        line-height: 30px;
                    }

                    @include desktop {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }

        .right {
            width: 100%;

            .txt {
                font-size: 18px;
                color: #000;

                // @include xl-desktop {
                //     font-size: 20px;
                // }

                @include large-desktop {
                    padding-top: 15px;
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }

                span {
                    color: $color-primary;
                }
            }

            .el-input {
                height: 100%;

                .el-input__inner {
                    height: 100%;
                    font-size: 18px;

                    // @include xl-desktop {
                    //     font-size: 18px;
                    // }

                    @include large-desktop {
                        margin-top: 15px;
                        font-size: 16px;
                    }

                    @include desktop {
                        font-size: 14px;
                    }
                }
            }



            .el-select {
                height: 100%;

                .el-input__icon {
                    color: $color-primary;
                    font-size: 20px;
                    line-height: 28px;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    //     line-height: 26px;
                    // }

                    @include large-desktop {
                        margin-top: 5px;
                        font-size: 18px;
                        line-height: 22px;
                    }

                    @include desktop {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }

        .sl-item {
            background-color: #f3f3f3;
            border-radius: 10px;
            overflow: hidden;
            margin: 15px 0;
            cursor: pointer;

            .img-box {
                width: 100%;
                height: 300px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                // @include xl-desktop {
                //     height: 300px;
                // }

                @include large-desktop {
                    height: 200px;
                }

                @include desktop {
                    height: 100px;
                }
            }

            .info {
                // height: 310px;
                padding: 30px 15px;

                // @include xl-desktop {
                //     height: auto;
                //     padding: 30px 15px;
                // }

                @include large-desktop {
                    padding: 20px 10px;
                }

                @include desktop {
                    padding: 10px;
                }

                .title {
                    padding-bottom: 10px;
                    font-size: 18px;
                    color: #000;
                    border-bottom: 1px solid $color-primary;

                    // @include xl-desktop {
                    //     padding-bottom: 15px;
                    //     font-size: 24px;
                    // }

                    @include large-desktop {
                        padding-bottom: 10px;
                        font-size: 16px;
                    }

                    @include desktop {
                        padding-bottom: 5px;
                        font-size: 14px;
                    }
                }

                .desc {
                    padding-top: 10px;

                    p {
                        font-size: 16px;
                        color: #828282;
                        text-align: justify;

                        // @include xl-desktop {
                        //     font-size: 18px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
            }
        }

        .sl-pagination {
            padding: 50px 0;

            .el-pagination {
                transform: scale(1.3);
            }

            @include xl-desktop {
                padding: 30px 0;

                .el-pagination {
                    transform: scale(1.2);
                }
            }

            @include large-desktop {
                padding: 20px 0;

                .el-pagination {
                    transform: scale(1.1);
                }
            }

            @include desktop {
                padding: 10px 0;

                .el-pagination {
                    transform: scale(1);
                }
            }
        }
    }






}