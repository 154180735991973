// Variables
$color-primary: #018876;
/*主题色*/
$color-assistant: #1774ff;
/*副题色*/
$color-success: #4cd964;
/*成功提示色*/
$color-warning: #f0ad4e;
/*警告提示色*/
$color-error: #dd524d;
/*错误警告色*/