.profession {
    .ad {
        .sl-carousel {
            position: relative;

            .mySwiper2 {
                overflow: hidden;
                // width: 100%;
                // height: 50vw;
                .swiper-wrapper{
                    height: auto !important; /* 确保高度是自适应的 */
                }
                .swiper-slide {
                    // width: 100%;
                    height: 100%;
                    position: relative;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }

            .mySwiper {
                overflow: hidden;
                position: absolute;
                bottom: 15px;
                left: 0;
                right: 0;
                z-index: 1;
                height:  60px;
                padding: 0 10px;

                // @include xl-desktop {
                //     height: 70px;
                //     padding: 0 10px;
                //     bottom: 15px;
                // }

                @include large-desktop {
                    height: 50px;
                    padding: 0 10px;
                    bottom: 10px;
                }

                @include desktop {
                    height: 40px;
                    padding: 0 5px;
                    bottom: 10px;
                }

                .swiper-slide {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    cursor: pointer;

                    .img-box {
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 10px;

                        @include xl-desktop {
                            border-radius: 8px;
                        }

                        @include large-desktop {
                            border-radius: 6px;
                        }

                        @include desktop {
                            border-radius: 4px;
                        }
                    }

                    .making {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 16px;
                        color: #fff;
                        font-weight: bold;
                        border-radius: 8px;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        //     border-radius: 8px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                            border-radius: 6px;
                        }

                        @include desktop {
                            font-size: 12px;
                            border-radius: 5px;
                        }
                    }
                }

                .swiper-slide-thumb-active {
                    .making {
                        background-color: rgba(1, 136, 118, 0.6);
                    }
                }
            }


        }
    }

    .tit {
        padding: 50px 0 0 0;

        // @include xl-desktop {
        //     padding: 60px 0 0 0;
        // }

        @include large-desktop {
            padding: 40px 0 0 0;
        }

        @include desktop {
            padding: 20px 0 0 0;
        }

        .tit-title {
            font-size: 24px;
            color: #000;
            font-weight: bold;

            // @include xl-desktop {
            //     font-size: 36px;
            // }

            @include large-desktop {
                font-size: 20px;
            }

            @include desktop {
                font-size: 16px;
            }

            i {
                width: 1px;
                height: 40px;
                background-color: $color-primary;
                transform: rotate(30deg);
                margin: 0 10px;

                // @include xl-desktop {
                //     margin: 0 15px;
                //     height: 50px;
                // }

                @include large-desktop {
                    margin: 0 10px;
                    height: 30px;
                }

                @include desktop {
                    margin: 0 5px;
                    height: 20px;
                }

            }

            span {
                font-size: 18px;
                color: $color-primary;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 22px;
                // }

                @include large-desktop {
                    font-size: 16px;
                }

                @include desktop {
                    font-size: 14px;
                }
            }
        }

        .tit-all {
            font-size: 16px;
            color: $color-primary;
            height: 100%;
            cursor: pointer;

            // @include xl-desktop {
            //     font-size: 16px;
            // }

            @include large-desktop {
                font-size: 14px;
            }

            @include desktop {
                font-size: 12px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .right {
            .sl-row {
                .sl-item {
                    margin: 15px 0;
                    cursor: pointer;

                    .info {
                        border-radius: 8px 8px 0 0;
                        background-color: #fff;
                        border-left: 1px solid #ccc;
                        border-top: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                        padding-bottom: 20px;
                        // @include xl-desktop {
                        //     padding-bottom: 35px;
                        // }

                        @include large-desktop {
                            padding-bottom: 15px;
                        }

                        @include desktop {
                            padding-bottom: 10px;
                        }
                        .name {
                            font-size: 14px;
                            color: #828282;
                            text-align: right;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 10px;

                            // @include xl-desktop {
                            //     height: 30px;
                            //     line-height: 30px;
                            //     padding: 0 15px;
                            //     font-size: 16px;
                            // }

                            @include large-desktop {
                                height: 20px;
                                line-height: 20px;
                                padding: 0 10px;
                                font-size: 14px;
                            }

                            @include desktop {
                                height: 15px;
                                line-height: 15px;
                                padding: 0 5px;
                                font-size: 12px;
                            }
                        }

                        .img-box {
                            margin: 0 20px;
                            height: 100px;

                            // @include xl-desktop {
                            //     margin: 0 35px;
                            //     height: 130px;
                            // }

                            @include large-desktop {
                                margin: 0 15px;
                                height: 80px;
                            }

                            @include desktop {
                                margin: 0 15px;
                                height: 60px;
                            }
                        }


                    }

                    .title {
                        height: 40px;
                        color: #666;
                        background-color: #f3f3f3;
                        border-radius: 0 0 8px 8px;
                        font-size: 16px;
                        line-height: 40px;
                        padding: 0 10px;
                        text-align: center;

                        // @include xl-desktop {
                        //     height: 48px;
                        //     line-height: 48px;
                        //     padding: 0 15px;
                        //     font-size: 18px;
                        // }

                        @include large-desktop {
                            height: 30px;
                            line-height: 30px;
                            padding: 0 10px;
                            font-size: 14px;
                        }

                        @include desktop {
                            height: 20px;
                            line-height: 20px;
                            padding: 0 5px;
                            font-size: 12px;
                        }
                    }
                }
            }

        }

        .tit-desc {
            font-size: 16px;
            color: #828282;
            padding: 20px 0;

            // @include xl-desktop {
            //     font-size: 18px;
            //     padding: 20px 0;
            // }

            @include large-desktop {
                font-size: 14px;
                padding: 10px 0;
            }

            @include desktop {
                font-size: 12px;
                padding: 5px 0;
            }
        }
    }

    .handle {
        padding: 30px 0;

        // @include xl-desktop {
        //     padding: 40px 0;
        // }

        @include large-desktop {
            padding: 20px 0 0 0;
        }

        @include desktop {
            padding: 10px 0 0 0;
        }

        .handle-item {
            width: 100%;
            cursor: pointer;

            .img-box {
                height: 200px;
                border-radius: 8px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                // @include xl-desktop {
                //     height: 250px;
                // }

                @include large-desktop {
                    height: 150px;
                }

                @include desktop {
                    height: 100px;
                }
            }

            .title {
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                color: #666;
                text-align: center;
                padding: 0 10px;

                // @include xl-desktop {
                //     height: 60px;
                //     line-height: 60px;
                //     font-size: 20px;
                //     padding: 0 15px;
                // }

                @include large-desktop {
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    padding: 0 10px;
                }

                @include desktop {
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                    padding: 0 5px;
                }
            }

            .magnifier {
                position: absolute;
                border: 2px solid #000;
                overflow: hidden;
                pointer-events: none;
                cursor: none;
                z-index: 1;
                width: 100%;
                height: 100%;
            }

        }
    }

    .sl-swiper5 {
        margin-top: 20px;
        background-color: $color-primary;

        // @include xl-desktop {
        //     margin-top: 25px;
        // }

        @include large-desktop {
            margin-top: 15px;
            padding-bottom: 15px;
        }

        @include desktop {
            margin-top: 10px;
        }

        .left {
            // padding: 0 80px;
            height: 100%;

            // @include xl-desktop {
            //     // padding: 0 50px;
            // }

            @include large-desktop {
                padding: 15px 0;
                display: block;
            }

            @include desktop {
                padding: 15px 0px;
            }

            .title {
                font-size: 24px;
                color: #fff;
                font-weight: bold;

                // @include xl-desktop {
                //     font-size: 40px;
                // }

                @include large-desktop {
                    font-size: 20px;
                }

                @include desktop {
                    font-size: 18px;
                }
            }

            .text {
                padding-top: 10px;

                p {
                    font-size: 16px;
                    color: #fff;
                    opacity: 0.8;
                    text-align: justify;
                }

                // @include xl-desktop {
                //     padding-top: 15px;

                //     p {
                //         font-size: 18px;
                //     }
                // }

                @include large-desktop {
                    padding-top: 10px;

                    p {
                        font-size: 14px;
                    }
                }

                @include desktop {
                    padding-top: 5px;

                    p {
                        font-size: 12px;
                    }
                }
            }

            .btn-all {
                width: 80px;
                height: 30px;
                border-radius: 15px;
                border: 1px solid #fff;
                font-size: 12px;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 30px;
                margin-top: 40px;

                // @include xl-desktop {
                //     width: 100px;
                //     height: 30px;
                //     border-radius: 15px;
                //     font-size: 16px;
                //     line-height: 30px;
                //     margin-top: 40px;

                // }

                @include large-desktop {
                    width: 70px;
                    height: 24px;
                    border-radius: 12px;
                    font-size: 12px;
                    line-height: 24px;
                    margin-top: 15px;
                }

                @include desktop {
                    width: 60px;
                    height: 20px;
                    border-radius: 12px;
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 10px;
                }

                &:hover {
                    background-color: #fff;
                    color: $color-primary;
                }
            }
        }

        .right {
            position: relative;
            top: -40px;

            // @include xl-desktop {
            //     top: -50px;

            // }

            @include large-desktop {
                top: -30px;
            }

            @include desktop {
                top: 0;
            }

            .img-box {
                width: 100%;
                height: 25vw;
                overflow: hidden;
                transition: all 0.3s ease-in-out;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 10px;
            }

        }
    }

    .advantage {
        // padding: 0px 100px 0 100px;

        // @include xl-desktop {
        //     padding: 0px;
        // }

        @include large-desktop {
            padding: 0px;
        }

        @include desktop {
            padding: 0px;
        }

        .sl-row {
            padding-top: 5px;

            // @include xl-desktop {
            //     padding-top: 5px;
            // }

            @include large-desktop {
                padding-top: 0px;
            }

            .sl-item {
                margin: 15px 0;
                background-color: #f2f2f2;
                border-radius: 10px;
                overflow: hidden;

                @include xl-desktop {
                    margin: 10px 0;
                    border-radius: 8px;
                }

                @include large-desktop {
                    margin: 5px 0;
                    border-radius: 5px;
                }

                .right {
                    flex: 1;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .left {
                    padding: 30px;

                    // @include xl-desktop {
                    //     padding: 40px 50px;
                    // }

                    @include large-desktop {
                        padding: 20px;
                    }

                    @include desktop {
                        padding: 15px;
                    }

                    .title {
                        font-size: 20px;
                        color: $color-primary;
                        font-weight: bold;

                        // @include xl-desktop {
                        //     font-size: 24px;
                        // }

                        @include large-desktop {
                            font-size: 18px;
                        }

                        @include desktop {
                            font-size: 16px;
                        }
                    }

                    .text {
                        height: 140px;
                        padding-top: 20px;

                        p {
                            font-size: 16px;
                            color: #666;
                            opacity: 0.8;
                            text-align: justify;
                        }

                        // @include xl-desktop {
                        //     height: 180px;
                        //     padding-top: 20px;

                        //     p {
                        //         font-size: 18px;
                        //     }
                        // }

                        @include large-desktop {
                            height: 100px;
                            padding-top: 15px;

                            p {
                                font-size: 14px;
                            }
                        }

                        @include desktop {
                            height: 80px;
                            padding-top: 10px;

                            p {
                                font-size: 12px;
                            }
                        }
                    }

                    .btn-all {
                        font-size: 16px;
                        color: $color-primary;
                        cursor: pointer;

                        // @include xl-desktop {
                        //     font-size: 16px;
                        // }

                        @include large-desktop {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 12px;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }


    }

    .recommended {
        padding: 0 0 40px 0;

        // @include xl-desktop {
        //     padding: 0 0 40px 0;
        // }

        @include large-desktop {
            padding: 0 0 30px 0;
        }

        @include desktop {
            padding: 0 0 20px 0;
        }

        .recommended-item {
            margin: 8px 0;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 15px;

            // @include xl-desktop {
            //     border-radius: 8px;
            //     padding: 15px;
            // }

            @include large-desktop {
                border-radius: 5px;
                padding: 10px;
            }

            @include desktop {
                border-radius: 5px;
                padding: 5px;
            }

            .icon {
                width: 230px;
                height: 230px;
                border-radius: 10px;

                @include xl-desktop {
                    width: 180px;
                    height: 180px;
                }

                @include large-desktop {
                    width: 150px;
                    height: 150px;
                }

                @include desktop {
                    width: 120px;
                    height: 120px;
                }
            }

            .cont {
                width: calc(100% - 180px);
                padding-left: 20px;

                // @include xl-desktop {
                //     width: calc(100% - 180px);
                //     padding-left: 20px;
                // }

                @include large-desktop {
                    width: calc(100% - 150px);
                    padding-left: 15px;
                }

                @include desktop {
                    width: calc(100% - 120px);
                    padding-left: 10px;
                }

                .title {
                    font-size: 18px;
                    color: $color-primary;
                    font-weight: bold;

                    // @include xl-desktop {
                    //     font-size: 26px;
                    // }

                    @include large-desktop {
                        font-size: 16px;
                    }

                    @include desktop {
                        font-size: 14px;
                    }
                }

                .text {
                    height: 90px;
                    padding-top: 15px;

                    p {
                        font-size: 14px;
                        color: #828282;
                    }

                    // @include xl-desktop {
                    //     height: 90px;
                    //     padding-top: 15px;

                    //     p {
                    //         font-size: 16px;
                    //     }
                    // }

                    @include large-desktop {
                        height:  60px;
                        padding-top: 10px;

                        p {
                            font-size: 12px;
                        }
                    }

                    @include desktop {
                        height: 50px;
                        padding-top: 5px;

                        p {
                            font-size: 12px;
                        }
                    }
                }

                .btn-all {
                    width: 90px;
                    height: 30px;
                    border-radius: 15px;
                    background-color: $color-primary;
                    font-size: 12px;
                    color: #fff;
                    cursor: pointer;
                    text-align: center;
                    line-height: 30px;
                    margin-top: 20px;

                    // @include xl-desktop {
                    //     width: 110px;
                    //     height: 34px;
                    //     border-radius: 19px;
                    //     font-size: 16px;
                    //     line-height: 34px;
                    //     margin-top: 20px;
                    // }

                    @include large-desktop {
                        width: 80px;
                        height: 24px;
                        border-radius: 12px;
                        font-size: 12px;
                        line-height: 24px;
                        margin-top: 15px;
                    }

                    @include desktop {
                        width: 70px;
                        height: 20px;
                        border-radius: 12px;
                        font-size: 12px;
                        line-height: 20px;
                        margin-top: 10px;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

        }
    }

    /* .point-container {
        position: relative;
        width: 100%;
        height: 100%;
    } */
    .point {
        position: absolute;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    .point-dot {
        width: 24px;
        height: 24px;
        background:$color-primary;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 24px;
    }
    .point-pulse {
        position: absolute;
        width: 26px;
        height: 26px;
        background: rgba(33, 150, 243, 0.3);
        border-radius: 50%;
        top: -2px;
        left: -2px;
        animation: pulse 1.5s infinite;
    }
    .point-label {
        position: absolute;
        color: #fff;
        font-size: 14px;
        z-index: 10;
    }
    
    .label-popup {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.4);
        padding: 15px;
        border-radius: 8px;
        width: 200px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        /* 确保弹窗不会被其他元素遮挡 */
        z-index: 100;
    }
    
    /* 其他样式保持不变 */
    .popup-title {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
    }
    
    .popup-desc {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 12px;
    }
    
    .popup-more {
        margin: 50px auto 0 auto;
        width: 100px;
        height: 30px;
        border-radius: 15px;
        background-color: $color-primary;
        font-size: 12px;
        color: #fff;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #fff;
            color: $color-primary;
        }
    }
    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }


}